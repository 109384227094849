import styled from "@emotion/styled";
import "./searchComponent.scss";
import { Button, FormHelperText } from "@mui/material";
import React from "react";
import moment from "moment";
import SelectSearch from "react-select";
import RefreshIcon from "@mui/icons-material/Refresh";

const SearchComponent = (props) => {
  const FilterButton = styled(Button)({
    color: "#fff",
    backgroundColor: "#005D91",
    borderColor: " #005D91",
    "&:hover": {
      backgroundColor: "#07527D !important",
      borderColor: "#07527D !important",
    },
  });
  return (
    <div style={{ display: "flex", float: "right" }}>
      {!props?.trainingVideoFeedback && (
        <div style={{ zIndex: "1000" }}>
          <SelectSearch
            style={{ flex: "1" }}
            isClearable={true}
            isLoading={props?.reactSelectLoading}
            cacheOptions
            defaultOptions={props?.getSearchOption}
            value={props?.inputValues}
            getOptionLabel={(e) => e._id}
            getOptionValue={(e) => e._id}
            options={props.getSearchOption}
            onInputChange={(e, { action }) =>
              props.handleInputChangeForSearchTerm(e, action)
            }
            onChange={(e) => props.handleSearchForTable(e, "search", "")}
            className="search-form-control search-input"
            hideSelectedOptions={false}
          />
          <FormHelperText style={{ color: "red", marginLeft: "11px" }}>
            {props?.searchEmail?.isEmpty ? props?.searchEmail?.errorText : ""}
          </FormHelperText>
        </div>
      )}

      <>
        <input
          type="date"
          className="ml-10 search-form-control input dateTime"
          format={"DD/MM/YYYY"}
          value={props?.changeStartMonthDate}
          onChange={(e) => {
            props?.setStartMonthDate(e?.target?.value);
          }}
          max={props?.changeEndMonthDate}
          onBlur={(e) => {
            const current = new Date(e.target.value);
            const end = new Date(props.changeEndMonthDate);
            if (!(current <= end)) {
              const finalDate =
                current > end
                  ? props.changeEndMonthDate
                  : props.changeEndMonthDate;
              props?.setStartMonthDate(
                moment(finalDate).subtract(1, "days").format("YYYY-MM-DD")
              );
            }
          }}
        />
        <input
          type="date"
          className="ml-10 search-form-control input dateTime"
          format={"DD/MM/YYYY"}
          value={props?.changeEndMonthDate}
          onChange={(e) => {
            props?.setEndMonthDate(e?.target?.value);
          }}
          onBlur={(e) => {
            const current = new Date(e?.target.value);
            const end = new Date(props?.maxDateForEndMonth);
            if (!(current <= end)) {
              const finalDate =
                current > end
                  ? props?.maxDateForEndMonth
                  : props?.maxDateForEndMonth;
              props?.setEndMonthDate(moment(finalDate).format("YYYY-MM-DD"));
            }
          }}
          max={props?.maxDateForEndMonth}
        />
      </>

      <FilterButton
        onClick={props?.handleSearchEmailBtnClick}
        style={{ height: "37px", marginLeft: "10px" }}
      >
        Search
      </FilterButton>
      <Button
        onClick={props?.handleRefreshPage}
        className="btn btn-refresh search-comp-btn"
        id="refresh-btn"
        style={{ height: "37px", marginLeft: "10px" }}
      >
        <RefreshIcon id="refresh-icon" />
      </Button>
    </div>
  );
};
export default SearchComponent;
