import React from "react";

import { Navigate } from "react-router-dom";

export default function ProtectedForAuth(props) {
  function getCookie(cName) {
    const name = cName + "=";
    const cDecoded = decodeURIComponent(document.cookie); //to be careful
    const cArr = cDecoded.split("; ");
    let res;
    cArr.forEach((val) => {
      if (val.indexOf(name) === 0) res = val.substring(name.length);
    });
    return res;
  }

  let Cmp = props.cmp;
  const token = getCookie("tokenUI");

  return <div>{token === undefined ? <Cmp /> : <Navigate to="/providers/dashboard" />}</div>;
}
