import React, { useState } from "react";
import SelectDropdown from "../../../../Component/SelectDropdown/selectDropdown";
import { Grid, Button } from "@mui/material";
import { experimentalStyled as styled } from "@mui/material/styles";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { getMonthNumber } from "../../../../utils/CommanFunctions";
export default function SelectComponant(props) {
  const [IngestOrPublish, setIngestOrPublish] = useState("AUTO");
  const tradeTypeOption = [{ title: "IMPORT" }, { title: "EXPORT" }];
  const IngestOrPublishOption = [{ title: "AUTO" }];

  const UploadBtn = styled(Button)({
    color: "#fff",
    backgroundColor: "#005D91",
    borderColor: " #005D91",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#07527D !important",
      borderColor: "#07527D !important",
    },
  });
  const handleChangeMonth = (e) => {
    const { value } = e?.target;
    const monthAndYear = value.split("-");
    const obj = {
      month: getMonthNumber(monthAndYear[1]),
      year: monthAndYear[0],
      value: value,
    };
    props?.setMonthAndYear(obj);
  };
  return (
    <div>
      <Grid container style={{ padding: "18px", background: "white" }} spacing={{ xs: 2, md: 2 }} columns={{ xs: 2, sm: 8, md: 12 }}>
        <Grid item xs={4} sm={4} md={2} xl={4}>
          <SelectDropdown
            value={props.currentTradeType}
            setValue={(val) => props.setCurrentTradeType(val.target.value)}
            option={tradeTypeOption}
            text="Trade Type"
            label="Trade Type"
            optionValue="title"
            defaultValue="value"
          />
        </Grid>
        <Grid item xs={4} sm={4} md={2} xl={4}>
          <SelectDropdown
            value={props.currentCountry}
            setValue={(val) => props.setCurrentCountry(val.target.value)}
            option={props.allCountry}
            text="Country"
            label="Country"
            optionValue="title"
            defaultValue="value"
          />
        </Grid>
        <Grid item xs={4} sm={4} md={2} xl={4}>
          <SelectDropdown
            value={IngestOrPublish}
            setValue={(val) => props.setIngestOrPublish(val.target.value)}
            option={IngestOrPublishOption}
            text="Ingest"
            label="Ingest"
            optionValue="title"
            defaultValue="value"
          />
        </Grid>

        <Grid item xs={4} sm={4} md={2} xl={4}>
          <SelectDropdown
            value={IngestOrPublish}
            setValue={(val) => props.setIngestOrPublish(val.target.value)}
            option={IngestOrPublishOption}
            text="Publish"
            label="Publish"
            optionValue="title"
            defaultValue="value"
          />
        </Grid>
        <Grid item xs={3} sm={3} md={2} xl={4}>
          <input
            type="month"
            className="ml-10 search-form-control input dateTime"
            format={"DD/MM/YYYY"}
            value={props?.monthAndYear?.value}
            onChange={handleChangeMonth}
            max={new Date()}
          />
        </Grid>
        <Grid item style={{ marginLeft: "auto" }} xs={4} sm={4} md={2} xl={4}>
          <UploadBtn startIcon={<UploadFileIcon />} onClick={props.handleClickUploadFiles}>
            Start Upload
          </UploadBtn>
        </Grid>
      </Grid>
    </div>
  );
}
