import { FormControl, InputLabel, OutlinedInput, InputAdornment, IconButton, TextField, FormHelperText, Autocomplete, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import React, { useEffect, useState } from "react";
import SelectSearch from "react-select";
import "./search.scss";
export default function Search({
  handleSearchForTable,
  searchFieldName,
  searchIcon,
  reactSelectLoading,
  handleSearchClick,
  handleCancelEmailSearch,
  handleInputChangeForSearchTerm,
  getSearchOption,
  searchEmail,
  inputValues,
  customer,
}) {
  const [searchValue, setSearchValue] = useState("");
  const [error, seterror] = useState({
    errorText: "",
    isEmpty: false,
  });
  const clearInput = () => {
    setSearchValue("");
    handleSearchForTable("");
  };

  const validateFields = () => {
    if (inputValues) {
      if (inputValues === "") {
        seterror({
          errorText: "This Value is Required",
          isEmpty: true,
        });

        return false;
      }
      //  else if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(inputValues) === false) {
      //   seterror({
      //     errorText: "Please Enter Valid Email Id",
      //     isEmpty: true,
      //   });
      //   return false;
      // }
      else {
        seterror({
          errorText: "",
          isEmpty: false,
        });
        return true;
      }
    } else {
      seterror({
        errorText: "This Value is Required",
        isEmpty: true,
      });

      return false;
    }
  };

  const searchClick = () => {
    if (validateFields() === false) return;
    handleSearchClick(inputValues);
  };

  const cancelClick = () => {
    setSearchValue("");
    handleCancelEmailSearch();
  };


  return (
    <div>
      <FormControl sx={{ m: 1, width: customer ? "400px" : "300px", zIndex: "1000" }} variant="outlined" size="small">
        {customer ? (
          <div style={{ display: "flex" }}>
            <SelectSearch
              style={{ flex: "1" }}
              isClearable={true}
              isLoading={reactSelectLoading}
              cacheOptions
              defaultOptions={getSearchOption}
              value={inputValues}
              getOptionLabel={(e) => e._id}
              getOptionValue={(e) => e._id}
              options={getSearchOption}
              onInputChange={(e, { action }) => handleInputChangeForSearchTerm(e, action)}
              onChange={(e) => handleSearchForTable(e, "search", "")}
              className="search-form-control search-async"
              hideSelectedOptions={false}
            />
            <Button onClick={searchClick} className="btn btn-secondary ml-10 search-comp-btn" id="btn-secondary" style={{ width: "30px", marginLeft: "10px" }}>
              <SearchIcon id="searchIcon"></SearchIcon>
            </Button>
          </div>
        ) : (
          <>
            <InputLabel htmlFor="outlined-adornment-password">{searchFieldName}</InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type="text"
              value={searchValue}
              size="small"
              error={error.isEmpty}
              onChange={(e) => [handleSearchForTable(e.target.value), setSearchValue(e.target.value)]}
              endAdornment={
                <InputAdornment position="end">
                  {searchIcon ? (
                    <>
                      <IconButton edge="end">{searchValue.length > 0 && <SearchIcon onClick={() => searchClick()} />}</IconButton>
                      <IconButton edge="end">{searchValue.length > 0 && <CloseIcon onClick={() => cancelClick()} />}</IconButton>
                    </>
                  ) : (
                    <IconButton edge="end">{searchValue.length > 0 && <CloseIcon onClick={() => clearInput()} />}</IconButton>
                  )}
                </InputAdornment>
              }
              label={searchFieldName}
            />
          </>
        )}

        <FormHelperText style={{ color: "red", marginLeft: "0px" }}>{error.isEmpty ? error.errorText : ""}</FormHelperText>
      </FormControl>
    </div>
  );
}
