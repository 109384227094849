const fetchCountryData = [
  { title: "Choose.." },
  { title: "Afghanistan" },
  { title: "Albania" },
  { title: "Algeria" },
  { title: "American Samoa" },
  { title: "Andorra" },
  { title: "Angola" },
  { title: "Anguilla" },
  { title: "Antigua & Barbuda" },
  { title: "Argentina" },
  { title: "Armenia" },
  { title: "Aruba" },
  { title: "Australia" },
  { title: "Austria" },
  { title: "Azerbaijan" },
  { title: "Bahamas" },
  { title: "Bahrain" },
  { title: "Bangladesh" },
  { title: "Barbados" },
  { title: "Belarus" },
  { title: "Belgium" },
  { title: "Belize" },
  { title: "Benin" },
  { title: "Bermuda" },
  { title: "Bhutan" },
  { title: "Bolivia" },
  { title: "Bonaire" },
  { title: "Bosnia & Herzegovina" },
  { title: "Botswana" },
  { title: "Brazil" },
  { title: "British Indian Ocean Ter" },
  { title: "Brunei" },
  { title: "Bulgaria" },
  { title: "Burkina Faso" },
  { title: "Burundi" },
  { title: "Cambodia" },
  { title: "Cameroon" },
  { title: "Canada" },
  { title: "Canary Islands" },
  { title: "Cape Verde" },
  { title: "Cayman Islands" },
  { title: "Central African Republic" },
  { title: "Chad" },
  { title: "Channel Islands" },
  { title: "Chile" },
  { title: "China" },
  { title: "Christmas Island" },
  { title: "Cocos Island" },
  { title: "Colombia" },
  { title: "Comoros" },
  { title: "Congo" },
  { title: "Cook Islands" },
  { title: "Costa Rica" },
  { title: "Cote DIvoire" },
  { title: "Croatia" },
  { title: "Cuba" },
  { title: "Curacao" },
  { title: "Cyprus" },
  { title: "Czech Republic" },
  { title: "Denmark" },
  { title: "Djibouti" },
  { title: "Dominica" },
  { title: "Dominican Republic" },
  { title: "East Timor" },
  { title: "Ecuador" },
  { title: "Egypt" },
  { title: "El Salvador" },
  { title: "Equatorial Guinea" },
  { title: "Eritrea" },
  { title: "Estonia" },
  { title: "Ethiopia" },
  { title: "Falkland Islands" },
  { title: "Faroe Islands" },
  { title: "Fiji" },
  { title: "Finland" },
  { title: "France" },
  { title: "French Guiana" },
  { title: "French Polynesia" },
  { title: "French Southern Ter" },
  { title: "Gabon" },
  { title: "Gambia" },
  { title: "Georgia" },
  { title: "Germany" },
  { title: "Ghana" },
  { title: "Gibraltar" },
  { title: "Great Britain" },
  { title: "Greece" },
  { title: "Greenland" },
  { title: "Grenada" },
  { title: "Guadeloupe" },
  { title: "Guam" },
  { title: "Guatemala" },
  { title: "Guinea" },
  { title: "Guyana" },
  { title: "Haiti" },
  { title: "Hawaii" },
  { title: "Honduras" },
  { title: "Hong Kong" },
  { title: "Hungary" },
  { title: "Iceland" },
  { title: "Indonesia" },
  { title: "India" },
  { title: "Iran" },
  { title: "Iraq" },
  { title: "Ireland" },
  { title: "Isle of Man" },
  { title: "Israel" },
  { title: "Italy" },
  { title: "Jamaica" },
  { title: "Japan" },
  { title: "Jordan" },
  { title: "Kazakhstan" },
  { title: "Kenya" },
  { title: "Kiribati" },
  { title: "Korea North" },
  { title: "Korea South" },
  { title: "Kuwait" },
  { title: "Kyrgyzstan" },
  { title: "Laos" },
  { title: "Latvia" },
  { title: "Lebanon" },
  { title: "Lesotho" },
  { title: "Liberia" },
  { title: "Libya" },
  { title: "Liechtenstein" },
  { title: "Lithuania" },
  { title: "Luxembourg" },
  { title: "Macau" },
  { title: "Macedonia" },
  { title: "Madagascar" },
  { title: "Malaysia" },
  { title: "Malawi" },
  { title: "Maldives" },
  { title: "Mali" },
  { title: "Malta" },
  { title: "Marshall Islands" },
  { title: "Martinique" },
  { title: "Mauritania" },
  { title: "Mauritius" },
  { title: "Mayotte" },
  { title: "Mexico" },
  { title: "Midway Islands" },
  { title: "Moldova" },
  { title: "Monaco" },
  { title: "Mongolia" },
  { title: "Montserrat" },
  { title: "Morocco" },
  { title: "Mozambique" },
  { title: "Myanmar" },
  { title: "Nambia" },
  { title: "Nauru" },
  { title: "Nepal" },
  { title: "Netherland Antilles" },
  { title: "Netherlands (Holland, Europe)" },
  { title: "Nevis" },
  { title: "New Caledonia" },
  { title: "New Zealand" },
  { title: "Nicaragua" },
  { title: "Niger" },
  { title: "Nigeria" },
  { title: "Niue" },
  { title: "Norfolk Island" },
  { title: "Norway" },
  { title: "Oman" },
  { title: "Pakistan" },
  { title: "Palau Island" },
  { title: "Palestine" },
  { title: "Panama" },
  { title: "Papua New Guinea" },
  { title: "Paraguay" },
  { title: "Peru" },
  { title: "Philippines" },
  { title: "Pitcairn Island" },
  { title: "Poland" },
  { title: "Portugal" },
  { title: "Puerto Rico" },
  { title: "Qatar" },
  { title: "Republic of Montenegro" },
  { title: "Republic of Serbia" },
  { title: "Reunion" },
  { title: "Romania" },
  { title: "Russia" },
  { title: "Rwanda" },
  { title: "St Barthelemy" },
  { title: "St Eustatius" },
  { title: "St Helena" },
  { title: "St Kitts-Nevis" },
  { title: "St Lucia" },
  { title: "St Maarten" },
  { title: "St Pierre & Miquelon" },
  { title: "St Vincent & Grenadines" },
  { title: "Saipan" },
  { title: "Samoa" },
  { title: "Samoa American" },
  { title: "San Marino" },
  { title: "Sao Tome & Principe" },
  { title: "Saudi Arabia" },
  { title: "Senegal" },
  { title: "Seychelles" },
  { title: "Sierra Leone" },
  { title: "Singapore" },
  { title: "Slovakia" },
  { title: "Slovenia" },
  { title: "Solomon Islands" },
  { title: "Somalia" },
  { title: "South Africa" },
  { title: "Spain" },
  { title: "Sri Lanka" },
  { title: "Sudan" },
  { title: "Suriname" },
  { title: "Swaziland" },
  { title: "Sweden" },
  { title: "Switzerland" },
  { title: "Syria" },
  { title: "Tahiti" },
  { title: "Taiwan" },
  { title: "Tajikistan" },
  { title: "Tanzania" },
  { title: "Thailand" },
  { title: "Togo" },
  { title: "Tokelau" },
  { title: "Tonga" },
  { title: "Trinidad & Tobago" },
  { title: "Tunisia" },
  { title: "Turkey" },
  { title: "Turkmenistan" },
  { title: "Turks & Caicos Is" },
  { title: "Tuvalu" },
  { title: "Uganda" },
  { title: "United Kingdom" },
  { title: "Ukraine" },
  { title: "United Arab Emirates" },
  { title: "United States of America" },
  { title: "Uruguay" },
  { title: "Uzbekistan" },
  { title: "Vanuatu" },
  { title: "Vatican City State" },
  { title: "Venezuela" },
  { title: "Vietnam" },
  { title: "Virgin Islands (Brit)" },
  { title: "Virgin Islands (USA)" },
  { title: "Wake Island" },
  { title: "Wallis & Futana Is" },
  { title: "Yemen" },
  { title: "Zaire" },
  { title: "Zambia" },
  { title: "Zimbabwe" },
];
export default fetchCountryData;
