import React, { useState } from "react";
import InputField from "../../../../Component/InputField/inputField";
import "./subscriptionPanel.scss";
import {
  Grid,
  FormControl,
  Select,
  MenuItem,
  Box,
  Typography,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import OutlinedInput from "@mui/material/OutlinedInput";
import { experimentalStyled as styled, useTheme } from "@mui/material/styles";
import SelectDropdown from "../../../../Component/SelectDropdown/selectDropdown";
import { memo } from "react";
import { getCustometPlanDetails } from "../../../../Services/service";
import moment from "moment";
import { useEffect } from "react";
import { planTypes, plans } from "./subscriptionPanelEnum";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, availableCountries, theme) {
  return {
    fontWeight:
      availableCountries.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

function SubscriptionPanel(props) {
  const CurrencyOptions = [
    {
      title: "INR",
    },
    {
      title: "USD",
    },
  ];
  const theme = useTheme();
  // const [customerPlanDetails, setSubscriptionDetails] = useState({});
  const [customerPlanDetails, setCustomerPlanDetails] = useState(
    props.subscriptionDetails
  );
  const [customerLimits, setCustomerLimits] = useState({});

  const [selectedPlan, setSelectedPlan] = useState(null);

  useEffect(() => {
    if (props.editUserId) {
      customerOrPlanAPIHandler(props.editUserId);
    }
  }, [props.editUserId]);

  const customerOrPlanAPIHandler = (id) => {
    getCustometPlanDetails(id)
      .then((e) => {
        if (e?.status === 500 || e?.status === 401) {
          throw e?.status;
        } else {
          if (e?.data && e?.data.length && e?.data[0]?.plan_constraints) {
            const plan_constraints = e.data[0].plan_constraints;
            let _customerLimits = {};
            const _subscriptionPlanDetails = {
              maxUser: plan_constraints?.max_users?.remaining_limit,
              searchLimit: plan_constraints?.max_query_per_day?.remaining_limit,
              saveQueryLimit: plan_constraints?.max_save_query?.remaining_limit,
              workSpaceLimit:
                plan_constraints?.max_workspace_count?.remaining_limit,
              maxWorkSpaceDeleteCount:
                plan_constraints?.max_workspace_delete_count?.remaining_limit,
              maxWorkSpaceRecordCount:
                plan_constraints?.max_workspace_record_count?.remaining_limit,
              favCompanyLimit:
                plan_constraints?.favorite_company_limit?.remaining_limit,
              favShipmentLimit:
                plan_constraints?.favorite_shipment_limit?.remaining_limit,
              summaryLimit:
                plan_constraints?.max_summary_limit?.remaining_limit,
              reqBuyerLimit:
                plan_constraints?.max_request_shipment_count?.remaining_limit,
              paymentDetails: plan_constraints?.payment?.note,
              displayLimitation: plan_constraints?.is_hidden,
              accessValidityEndDate: moment(
                plan_constraints?.access_validity_interval?.end_date
              ).format("YYYY-MM-DD"),
              accessValidityStartDate: moment(
                plan_constraints?.access_validity_interval?.start_date
              ).format("YYYY-MM-DD"),
              payTransId: plan_constraints?.payment?.transaction_id,
              purchasePoints: plan_constraints?.purchase_points,
              payCurrency: plan_constraints?.payment?.currency,
              payAmount: plan_constraints?.payment?.amount,
              dataAvalidityEndDate: moment(
                plan_constraints?.data_availability_interval?.end_date
              ).format("YYYY-MM-DD"),
              dataAvalidityStartDate: moment(
                plan_constraints?.data_availability_interval?.start_date
              ).format("YYYY-MM-DD"),
            };
            _customerLimits = {
              allMaxUser: plan_constraints?.max_users,
              allSearchLimit: plan_constraints?.max_query_per_day,
              allSaveQueryLimit: plan_constraints?.max_save_query,
              allWorkSpaceLimit: plan_constraints?.max_workspace_count,
              allMaxWorkSpaceDeleteCount:
                plan_constraints?.max_workspace_delete_count,
              allMaxWorkSpaceRecordCount:
                plan_constraints?.max_workspace_record_count,
              allFavCompanyLimit: plan_constraints?.favorite_company_limit,
              allFavShipmentLimit: plan_constraints?.favorite_shipment_limit,
              allSummaryLimit: plan_constraints?.max_summary_limit,
              allReqBuyerLimit: plan_constraints?.max_request_shipment_count,
            };

            setCustomerLimits(_customerLimits);
            setCustomerPlanDetails(_subscriptionPlanDetails);
            props.setCustomerPlanDetails(_subscriptionPlanDetails);

            if (
              plan_constraints?.countries_available &&
              plan_constraints?.countries_available.length
            ) {
              const planCountry = [...plan_constraints?.countries_available];
              if (planCountry) {
                if (
                  plan_constraints?.countries_available?.length >
                  props?.totalCustomCountry
                ) {
                  props.blCountriesISO3 &&
                    props.blCountriesISO3.length &&
                    props.blCountriesISO3.forEach((ele) => {
                      if (planCountry.includes(ele)) {
                        const index = planCountry.findIndex(
                          (con) => con === ele
                        );
                        planCountry.splice(index, 1);
                      }
                    });
                  planCountry.push("bl");
                }
                props.setSelectedCountry(planCountry);
              }
            }
          } else {
            throw new Error();
          }
        }
      })
      .catch((err) => {
        // setloading(false);
        // seterror(true);
        // seterrorMsg({
        //   title: err === 401 ? "Warning" : "Error",
        //   content: err === 500 ? "Internal Server Error" : err === 401 ? "Session TimeOut , Please Login Again" : "Something Went Wrong , Please Login Again",
        // });
      });
  };

  const handleCustomerPlanDetails = (event) => {
    const _customerPlanDetails = {
      ...customerPlanDetails,
      [event.target.name]:
        event.target.name === "displayLimitation"
          ? event.target.checked
          : event.target.value,
    };
    setCustomerPlanDetails(_customerPlanDetails);
    props.setCustomerPlanDetails(_customerPlanDetails);
  };

  const handlePlanSelect = (e) => {
    setSelectedPlan(e.target.value);
    setFieldValue(e.target.value);
  };
  const setFieldValue = (planName) => {
    if (planName === "SP-CUSTOM") {
      planName = "SP_CUSTOM";
    }
    const planValues = planTypes[planName];
    const selectedPlanCountry = props?.availableCountry.filter(
      (item) => !planValues?.selectedCountry.includes(item.country)
    );
    const selectedCountryArr = selectedPlanCountry.map((item) => {
      return item.iso3;
    });

    setCustomerPlanDetails({
      ...customerPlanDetails,
      ...planValues,
    });
    props.setCustomerPlanDetails({
      ...customerPlanDetails,
      ...planValues,
    });
    props.setSelectedCountry(
      planName === "SP_CUSTOM" ? [] : selectedCountryArr
    );
  };
  return (
    <div id="subscription-panel">
      <Grid
        container
        rowSpacing={{ xs: 2, md: 2 }}
        columnSpacing={{ md: 4 }}
        columns={{ xs: 2, sm: 8, md: 12 }}
      >
        <Grid item xs={4} sm={4} md={4} xl={4}>
          <label>Subscription Plan</label>
          {/* <InputField type="text" value="Custom Plan" disabled={true} /> */}
          <FormControl style={{ width: "100%" }} sx={{ mt: 0.8 }}>
            <Select
              style={{ height: "40px" }}
              displayEmpty
              error={selectedPlan == null ? true : false}
              helperText={selectedPlan == null ? "This Value is Required" : ""}
              value={selectedPlan}
              onChange={handlePlanSelect}
              input={<OutlinedInput />}
              renderValue={(selectedPlan) => {
                if (selectedPlan === null) {
                  return <em>Choose Plan...</em>;
                }
                return selectedPlan;
              }}
              MenuProps={MenuProps}
              inputProps={{ "aria-label": "Without label" }}
            >
              {plans?.map((ele) => (
                <MenuItem key={ele} value={ele}>
                  {ele}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={4} sm={4} md={4} xl={4}>
          <label>
            Allow Country Data <span className="required-danger">*</span>
          </label>
          <FormControl style={{ width: "100%" }} sx={{ mt: 0.8 }}>
            <Select
              style={{ height: "40px" }}
              multiple
              displayEmpty
              error={
                props.customerPlanEmptyFields.country &&
                props.selectedCountry.length === 0
                  ? true
                  : false
              }
              helperText={
                props.customerPlanEmptyFields.country &&
                props.selectedCountry.length === 0
                  ? "This Value is Required"
                  : ""
              }
              value={props.selectedCountry}
              onChange={props.handleSelectedCountry}
              input={<OutlinedInput />}
              renderValue={(selected) => {
                if (selected && selected.length === 0) {
                  return <em>Choose Countries ...</em>;
                }
                return selected?.join(", ");
              }}
              MenuProps={MenuProps}
              inputProps={{ "aria-label": "Without label" }}
            >
              <Box style={{ display: "flex" }}>
                <Typography
                  id="select-unselect-btn"
                  onClick={props.handleSelectAll}
                >
                  Select All
                </Typography>
                <Typography
                  id="select-unselect-btn"
                  onClick={props.handleDeselectAll}
                >
                  Deselect All
                </Typography>
              </Box>
              {props.availableCountry &&
                !!props.availableCountry.length &&
                props.availableCountry.map((ele) => (
                  <MenuItem
                    key={ele.country}
                    value={ele.iso3}
                    style={getStyles(
                      ele.country,
                      props.availableCountry,
                      theme
                    )}
                  >
                    {ele.country}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={4} sm={4} md={4} xl={4}>
          <label>
            Pay Trans Id<span className="required-danger">*</span>
          </label>
          <InputField
            type="text"
            value={customerPlanDetails.payTransId}
            setValue={(val) => handleCustomerPlanDetails(val)}
            placeholder="Ex: UPI190XCVJDIRY"
            name="payTransId"
            error={props.customerPlanEmptyFields.payTransId ? true : false}
            helperText="This Value is Required"
          />
        </Grid>
      </Grid>

      <Grid
        container
        rowSpacing={{ xs: 2, md: 2 }}
        columnSpacing={{ md: 4 }}
        columns={{ xs: 2, sm: 8, md: 12 }}
        className="mt-5"
      >
        <Grid item xs={4} sm={4} md={4} xl={4}>
          <label>
            Access Validity(Start Date)
            <span className="required-danger">*</span>
          </label>
          <InputField
            type="date"
            value={customerPlanDetails.accessValidityStartDate}
            setValue={(val) => handleCustomerPlanDetails(val)}
            name="accessValidityStartDate"
            error={
              props.customerPlanEmptyFields.accessValidityStartDate
                ? true
                : false
            }
            helperText="This Value is Required"
          />
        </Grid>

        <Grid item xs={4} sm={4} md={4} xl={4}>
          <label>
            Access Validity(End Date)<span className="required-danger">*</span>
          </label>
          <InputField
            type="date"
            value={customerPlanDetails.accessValidityEndDate}
            setValue={(val) => handleCustomerPlanDetails(val)}
            name="accessValidityEndDate"
            error={
              props.customerPlanEmptyFields.accessValidityEndDate ? true : false
            }
            helperText="This Value is Required"
          />
        </Grid>

        <Grid item xs={4} sm={4} md={4} xl={4}>
          <label>
            Max User <span className="required-danger">*</span>
            {`(${customerLimits?.allMaxUser?.remaining_limit} / ${customerLimits?.allMaxUser?.alloted_limit})`}
          </label>
          <InputField
            type="text"
            value={customerPlanDetails.maxUser}
            setValue={(val) => handleCustomerPlanDetails(val)}
            placeholder="Enter numeric value"
            name="maxUser"
            error={props.customerPlanEmptyFields.maxUser ? true : false}
            helperText="This Value is Required"
          />
        </Grid>
      </Grid>

      <Grid
        container
        rowSpacing={{ xs: 2, md: 2 }}
        columnSpacing={{ md: 4 }}
        columns={{ xs: 2, sm: 8, md: 12 }}
        className="mt-5"
      >
        <Grid item xs={4} sm={4} md={4} xl={4}>
          <label>
            Data Availability(Start Date){" "}
            <span className="required-danger">*</span>
          </label>
          <InputField
            type="date"
            value={customerPlanDetails.dataAvalidityStartDate}
            setValue={(val) => handleCustomerPlanDetails(val)}
            name="dataAvalidityStartDate"
            error={
              props.customerPlanEmptyFields.dataAvalidityStartDate
                ? true
                : false
            }
            helperText="This Value is Required"
          />
        </Grid>

        <Grid item xs={4} sm={4} md={4} xl={4}>
          <label>
            Data Availability(End Date){" "}
            <span className="required-danger">*</span>
          </label>
          <InputField
            type="date"
            value={customerPlanDetails.dataAvalidityEndDate}
            setValue={(val) => handleCustomerPlanDetails(val)}
            name="dataAvalidityEndDate"
            error={
              props.customerPlanEmptyFields.dataAvalidityEndDate ? true : false
            }
            helperText="This Value is Required"
          />
        </Grid>

        <Grid item xs={4} sm={4} md={4} xl={4}>
          <label>
            Pay Currency<span className="required-danger">*</span>
          </label>
          <Box sx={{ mt: 0.8 }}>
            <SelectDropdown
              width="100%"
              mt="0.5rem"
              value={customerPlanDetails.payCurrency}
              option={CurrencyOptions}
              optionValue={"title"}
              name="payCurrency"
              showLable={false}
              setValue={(event) => handleCustomerPlanDetails(event)}
              error={props.customerPlanEmptyFields.payCurrency ? true : false}
              helperText="This Value is Required"
            />
          </Box>
        </Grid>
      </Grid>

      <Grid
        container
        rowSpacing={{ xs: 2, md: 2 }}
        columnSpacing={{ md: 4 }}
        columns={{ xs: 2, sm: 8, md: 12 }}
        className="mt-5"
      >
        <Grid item xs={4} sm={4} md={4} xl={4}>
          <label>
            Pay Amount <span className="required-danger">*</span>
          </label>
          <InputField
            type="number"
            value={customerPlanDetails.payAmount}
            setValue={(val) => handleCustomerPlanDetails(val)}
            placeholder="Enter numeric value"
            name="payAmount"
            error={props.customerPlanEmptyFields.payAmount ? true : false}
            helperText="This Value is Required"
          />
        </Grid>
        <Grid item xs={4} sm={4} md={4} xl={4}>
          <label>
            Purchase Points <span className="required-danger">*</span>
          </label>
          <InputField
            type="number"
            value={customerPlanDetails.purchasePoints}
            setValue={(val) => handleCustomerPlanDetails(val)}
            placeholder="Enter numeric value"
            name="purchasePoints"
            error={props.customerPlanEmptyFields.purchasePoints ? true : false}
            helperText="This Value is Required"
          />
        </Grid>
        <Grid item xs={4} sm={4} md={4} xl={4}>
          <label>Payment Details</label>
          <InputField
            type="text"
            value={customerPlanDetails.paymentDetails}
            setValue={(val) => handleCustomerPlanDetails(val)}
            name="paymentDetails"
          />
        </Grid>
      </Grid>

      <Grid
        container
        rowSpacing={{ xs: 2, md: 2 }}
        columnSpacing={{ md: 4 }}
        columns={{ xs: 2, sm: 8, md: 12 }}
        className="mt-5"
      >
        <Grid item xs={4} sm={4} md={4} xl={4}>
          <label>
            Add Search Limit<span className="required-danger"> *</span>
            {` (${customerLimits?.allSearchLimit?.remaining_limit} / ${customerLimits?.allSearchLimit?.alloted_limit})`}
          </label>
          <InputField
            type="number"
            value={customerPlanDetails.searchLimit}
            setValue={(val) => handleCustomerPlanDetails(val)}
            placeholder="Enter numeric value"
            name="searchLimit"
            error={props.customerPlanEmptyFields.searchLimit ? true : false}
            helperText="This Value is Required"
          />
        </Grid>
        <Grid item xs={4} sm={4} md={4} xl={4}>
          <label>
            Add Save Query Limit<span className="required-danger"> *</span>
            {` (${customerLimits?.allSaveQueryLimit?.remaining_limit} / ${customerLimits?.allSaveQueryLimit?.alloted_limit})`}
          </label>
          <InputField
            type="number"
            value={customerPlanDetails.saveQueryLimit}
            setValue={(val) => handleCustomerPlanDetails(val)}
            placeholder="Enter numeric value"
            name="saveQueryLimit"
            error={props.customerPlanEmptyFields.saveQueryLimit ? true : false}
            helperText="This Value is Required"
          />
        </Grid>
        <Grid item xs={4} sm={4} md={4} xl={4}>
          <label>
            Add WorkSpace Limit<span className="required-danger"> *</span>
            {` (${customerLimits?.allWorkSpaceLimit?.remaining_limit} / ${customerLimits?.allWorkSpaceLimit?.alloted_limit})`}
          </label>
          <InputField
            type="number"
            value={customerPlanDetails.workSpaceLimit}
            setValue={(val) => handleCustomerPlanDetails(val)}
            placeholder="Enter numeric value"
            name="workSpaceLimit"
            error={props.customerPlanEmptyFields.workSpaceLimit ? true : false}
            helperText="This Value is Required"
          />
        </Grid>
      </Grid>

      <Grid
        container
        rowSpacing={{ xs: 2, md: 2 }}
        columnSpacing={{ md: 4 }}
        columns={{ xs: 2, sm: 8, md: 12 }}
        className="mt-5"
      >
        <Grid item xs={4} sm={4} md={4} xl={4}>
          <label>
            Max Workspace Delete Count
            <span className="required-danger"> *</span>
            {` (${customerLimits?.allMaxWorkSpaceDeleteCount?.remaining_limit} / ${customerLimits?.allMaxWorkSpaceDeleteCount?.alloted_limit})`}
          </label>
          <InputField
            type="number"
            value={customerPlanDetails.maxWorkSpaceDeleteCount}
            setValue={(val) => handleCustomerPlanDetails(val)}
            placeholder="Enter numeric value"
            name="maxWorkSpaceDeleteCount"
            error={
              props.customerPlanEmptyFields.maxWorkSpaceDeleteCount
                ? true
                : false
            }
            helperText="This Value is Required"
          />
        </Grid>

        <Grid item xs={4} sm={4} md={4} xl={4}>
          <label>
            Max Workspace Record Count
            <span className="required-danger"> *</span>
            {/* {`(${customerLimits.allMaxWorkSpaceRecordCount.remaining_limit} / ${customerLimits.allMaxWorkSpaceRecordCount.alloted_limit})`} */}
          </label>
          <InputField
            type="number"
            value={customerPlanDetails.maxWorkSpaceRecordCount}
            setValue={(val) => handleCustomerPlanDetails(val)}
            placeholder="Enter numeric value"
            name="maxWorkSpaceRecordCount"
            error={
              props.customerPlanEmptyFields.maxWorkSpaceRecordCount
                ? true
                : false
            }
            helperText="This Value is Required"
          />
        </Grid>

        <Grid item xs={4} sm={4} md={4} xl={4}>
          <label>
            Add Favorite Company Limit
            <span className="required-danger"> *</span>
            {` (${customerLimits?.allFavCompanyLimit?.remaining_limit} / ${customerLimits?.allFavCompanyLimit?.alloted_limit})`}
          </label>
          <InputField
            type="number"
            value={customerPlanDetails.favCompanyLimit}
            setValue={(val) => handleCustomerPlanDetails(val)}
            placeholder="Enter numeric value"
            name="favCompanyLimit"
            error={props.customerPlanEmptyFields.favCompanyLimit ? true : false}
            helperText="This Value is Required"
          />
        </Grid>
      </Grid>

      <Grid
        container
        rowSpacing={{ xs: 2, md: 2 }}
        columnSpacing={{ md: 4 }}
        columns={{ xs: 2, sm: 8, md: 12 }}
        className="mt-5"
      >
        <Grid item xs={4} sm={4} md={4} xl={4}>
          <label>
            Add Favorite Shipment Limit
            <span className="required-danger"> *</span>
            {` (${customerLimits?.allFavShipmentLimit?.remaining_limit} / ${customerLimits?.allFavShipmentLimit?.alloted_limit})`}
          </label>
          <InputField
            type="number"
            value={customerPlanDetails.favShipmentLimit}
            setValue={(val) => handleCustomerPlanDetails(val)}
            placeholder="Enter numeric value"
            name="favShipmentLimit"
            error={
              props.customerPlanEmptyFields.favShipmentLimit ? true : false
            }
            helperText="This Value is Required"
          />
        </Grid>

        <Grid item xs={4} sm={4} md={4} xl={4}>
          <label>
            Add Summary Limit<span className="required-danger"> *</span>
            {` (${customerLimits?.allSummaryLimit?.remaining_limit} / ${customerLimits?.allSummaryLimit?.alloted_limit})`}
          </label>
          <InputField
            type="number"
            value={customerPlanDetails.summaryLimit}
            setValue={(val) => handleCustomerPlanDetails(val)}
            placeholder="Enter numeric value"
            name="summaryLimit"
            error={props.customerPlanEmptyFields.summaryLimit ? true : false}
            helperText="This Value is Required"
          />
        </Grid>

        <Grid item xs={4} sm={4} md={4} xl={4}>
          <label>
            Add Request Buyer Limit<span className="required-danger"> *</span>
            {` (${customerLimits?.allReqBuyerLimit?.remaining_limit} / ${customerLimits?.allReqBuyerLimit?.alloted_limit})`}
          </label>
          <InputField
            type="number"
            value={props?.subscriptionDetails?.reqBuyerLimit}
            setValue={(val) => handleCustomerPlanDetails(val)}
            placeholder="Enter numeric value"
            name="reqBuyerLimit"
            error={props.customerPlanEmptyFields.reqBuyerLimit ? true : false}
            helperText="This Value is Required"
          />
        </Grid>
      </Grid>

      <Grid
        container
        rowSpacing={{ xs: 2, md: 2 }}
        columnSpacing={{ md: 4 }}
        columns={{ xs: 2, sm: 8, md: 12 }}
        className="mt-5"
      >
        <Grid item xs={4} sm={4} md={4} xl={4}>
          <FormControlLabel
            control={
              <Checkbox
                checked={props?.subscriptionDetails?.displayLimitation}
                name="displayLimitation"
                onChange={(val) => handleCustomerPlanDetails(val)}
              />
            }
            label="Add display limitation"
          />
        </Grid>
      </Grid>

      {props.nextPrev && (
        <Grid container className="d-flex-center" style={{ marginTop: "30px" }}>
          <Grid item>
            <button
              className="button-color"
              style={{ width: "160px" }}
              onClick={props.handlePreviousAddModal}
            >
              Previous
            </button>
          </Grid>

          <Grid item style={{ marginLeft: "auto", width: "160px" }}>
            <button
              className="button-color"
              onClick={props.handleCustomerPlanNextBtn}
            >
              Submit
            </button>
          </Grid>
        </Grid>
      )}
    </div>
  );
}

export default memo(SubscriptionPanel);
