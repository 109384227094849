import "./loginPage.scss";
import React from "react";
import EximpediaLogo from "../../Asset/images/logo-dark-og.png";
import { Link } from "react-router-dom";
import AuthFooter from "../AuthFooter/authFooter";
import { Form, Field } from "formik";

const LoginPassword = (props) => {
 
  return (
    <Form>
      <div className="background-content mb-5" id="login-password">
        <div className="container">
          <div className="rowCards">
            <div className="col-md-8 col-lg-6 col-xl-5">
              <div style={{ marginTop: "50px" }} className="card-login bg-pattern">
                <div className="card-body">
                  <div className="text-center w-75 m-auto">
                    <a href="/consumers/login" rel="noopener noreferrer">
                      <span>
                        <img className="img-center" src={EximpediaLogo} alt="" height="44" />
                      </span>
                    </a>
                    <p className="text-muted font-14 mb-4 mt-3">{props.text}</p>
                  </div>
                  <div className="form-group">
                    <div>
                      <label className="font-14">{props.subject1}</label>
                      <Field type={props.type1} id="email-id-input" name={props.name1} className="form-control-login" placeholder={props.subject1Placeholder} />
                      {props.touched1 && props.errors1 && <div className="error-message">{props.errors1}</div>}
                    </div>
                  </div>
                  <div style={{ margin: " 20px 0px" }} className="form-group">
                    <label className="font-14">{props.subject2}</label>
                    <Field type={props.type2} id="password-input" name={props.name2} className="form-control-login" placeholder={props.subject2Placeholder} />
                    {props.touched2 && props.errors2 && <div className="error-message">{props.errors2}</div>}
                  </div>
                  <div className="form-group mb-0 text-center">
                    <button type="submit" id="cta-login-account" className="button-color btn btn-primary waves-effect waves-light btn-block">
                      {props.buttonText}
                    </button>
                  </div>
                </div>
              </div>

              {/* {props.login === "true" ? (
                <div className="mt-3">
                  <div className="col-12 text-center">
                    <p className="mt-5 mb-2 ">
                      <Link to="/password/recovery" className="text-white-50 ml-1">
                        Forgot your password?
                      </Link>
                    </p>
                    <p className="text-white-50" style={{ fontSize: "14px" }}>
                      Don't have an account?
                      <Link to="/accounts/registrations" className="text-white ml-1">
                        <b>Sign Up</b>
                      </Link>
                    </p>
                  </div>
                </div>
              ) : (
                ""
              )} */}
            </div>
          </div>
        </div>
        <AuthFooter />
      </div>
    </Form>
  );
};

export default LoginPassword;