import { Grid } from "@mui/material";
import DataTable from "../../Component/DataTable/table";
import React, { useEffect, useState } from "react";
import { feedbackTableHeader } from "./VideoFeedback.enum";
import "./TrainingVideoFeedback.scss";
import SearchComponent from "../../Component/SearchComponent/SearchComponent";
import moment from "moment";
import Loading from "../../Component/Loader/loading";
import Error from "../../Component/ErrorLoader/error";
import { getVideoFeedback } from "../../Services/service";

export default function TrainingVideoFeedback() {
  const [changeStartMonthDate, setStartMonthDate] = useState(
    moment(Date.now()).subtract(1, "days").format("YYYY-MM-DD")
  );
  const [changeEndMonthDate, setEndMonthDate] = useState(
    moment(Date.now()).format("YYYY-MM-DD")
  );
  const [loadingMsg, setLoadingMsg] = useState({});
  const [errorMsg, setErrorMsg] = useState({});
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalAvailableData, setTotalAvailableData] = useState(0);
  const [VideoFeedbackList, setVideoFeedBackList] = useState([]);
  const [maxDateForEndMonth, setMaxDateForEndMonth] = useState(
    moment(Date.now()).format("YYYY-MM-DD")
  );

  useEffect(() => {
    setStartMonthDate(
      moment(Date.now()).subtract(1, "month").format("YYYY-MM-DD")
    );
    setEndMonthDate(moment(Date.now()).format("YYYY-MM-DD"));
    handleSearchBtnClick();
  }, []);

  const handleSearchBtnClick = () => {
    const dateObj = {
      dateFrom: changeStartMonthDate,
      dateTo: changeEndMonthDate,
      start: 0,
      length: rowsPerPage,
    };
    setLoading(true);
    setLoadingMsg({
      title: "Fetching User Feedback",
      content: "Please Wait...",
    });
    fetchVideoFeedBackList(dateObj);
  };
  const fetchVideoFeedBackList = (payload) => {
    getVideoFeedback(payload)
      .then((e) => {
        setLoading(false);
        if (e?.status === 500 || e?.status === 401) {
          throw e?.status;
        } else {
          if (e?.data?.data) {
            const data = e?.data?.data?.sort(
              (a, b) => b.created_at - a.created_at
            );
            setVideoFeedBackList(data);
            const startRecord = 0 * rowsPerPage;
            const newData = data.slice(startRecord, rowsPerPage + startRecord);
            setTableData(newData);
            setTotalAvailableData(e?.data?.totalCount || 0);
          } else if (e?.message) {
            setLoading(false);
            setError(true);
            setErrorMsg({
              title: "Error",
              content: e?.message,
            });
          } else {
            throw new Error();
          }
        }
      })
      .catch((err) => {
        setLoading(false);
        setError(true);
        setErrorMsg({
          title: err === 401 ? "Warning" : "Error",
          content:
            err === 500
              ? "Internal Server Error"
              : err === 401
              ? "Session TimeOut , Please Login Again"
              : "Something Went Wrong , Please Login Again",
        });
      });
  };
  const handleRefreshPage = () => {
    window.location.reload();
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
    const startRecord = 0 * event.target.value;
    const data = VideoFeedbackList.slice(
      startRecord,
      Number(event.target.value)
    );
    setTableData(data);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage - 1);
    const startRecord = (newPage - 1) * rowsPerPage;
    const data = VideoFeedbackList.slice(
      startRecord,
      startRecord + rowsPerPage
    );
    setTableData(data);
  };
  return (
    <Grid item className="feedback-data">
      {loading && (
        <Loading title={loadingMsg.title} content={loadingMsg.content} />
      )}
      {error && <Error errorMsg={errorMsg} open={error} setOpen={setError} />}

      <Grid className="search-feedback-data">
        <h3 className="text">
          Training
          <span className="badge bg-soft-blue text-blue label-worksapce-name">
            Feedback
          </span>
        </h3>
        <SearchComponent
          trainingVideoFeedback={true}
          changeStartMonthDate={changeStartMonthDate}
          setStartMonthDate={setStartMonthDate}
          changeEndMonthDate={changeEndMonthDate}
          handleSearchEmailBtnClick={handleSearchBtnClick}
          setEndMonthDate={setEndMonthDate}
          handleRefreshPage={handleRefreshPage}
          maxDateForEndMonth={maxDateForEndMonth}
        />
      </Grid>
      <Grid>
        <DataTable
          columns={feedbackTableHeader}
          rows={tableData}
          trainingVideoFeedback={true}
          rowsPerPage={rowsPerPage}
          page={page}
          totalAvailableData={totalAvailableData}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          showPagination={true}
        />
      </Grid>
    </Grid>
  );
}
