import React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import QuetionMark from "../../Asset/images/quetionMark.png";

import "./detailsInfoModal.scss";

export default function DetailsInfoModal(props) {
  return (
    <div id="error-loader">
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={props.open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.open}>
          <Box id="details-err-box">
            <Box sx={{ textAlign: "center", mt: 2 }}>
              <img src={QuetionMark} alt="QuetionMark" width="100px"/>
            </Box>
            <Typography
              id="transition-modal-title"
              variant="h6"
              component="h2"
              sx={{
                fontSize: "28px",
                fontWeight: 600,
                textAlign: "center",
                marginTop: "10px",
                opacity: 0.9,
                color: "#595959",
              }}
            >
              {props.errorMsg.title ? props.errorMsg.title : "error"}
            </Typography>
            <Typography
              id="transition-modal-description"
              sx={{
                marginTop: "10px",
                fontSize: "1.125em",
                fontweight: 300,
                wordwrap: "break-word",
                textAlign: "center",
                opacity: 0.6,
              }}
            >
              {props.errorMsg.content && props.errorMsg.content}
            </Typography>
            <Box sx={{ textAlign: "center", mt: 3 }} id="err-button">
              <Box sx={{ textAlign: "center", mt: 4 }} onClick={() => props.setOpen(false)}>
                {/* <a href="" className="OK-button" rel="noopener noreferrer">
                  OK
                </a> */}
                <span className="OK-button">OK</span>
              </Box>
            </Box>
            {/* <Box sx={{ mt: 3 }}>
              <Box sx={{ mt: 5, textAlign: "center" }}>
                <a href="" id="anchor" rel="noopener noreferrer">
                  Refresh Page & Try Again
                </a>
              </Box>
            </Box> */}
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
