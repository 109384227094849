import React, { useEffect, useState } from "react";
import "./dashboard.scss";
import { Container } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import PublicIcon from "@mui/icons-material/Public";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import StorageIcon from "@mui/icons-material/Storage";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import SpeakerNotesIcon from "@mui/icons-material/SpeakerNotes";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import BookmarksIcon from "@mui/icons-material/Bookmarks";
import LayersIcon from "@mui/icons-material/Layers";
import { getDashboredData } from "../../Services/service";
import DashboardComponent from "../../Component/DashboardComponant/dashboard";
import Loading from "../../Component/Loader/loading";
import Error from "../../Component/ErrorLoader/error";
export default function Dashboard() {
  const [loading, setloading] = useState(false);
  const [loadingMsg, setLoadingMsg] = useState({});
  const [error, seterror] = useState(false);
  const [errorMsg, seterrorMsg] = useState({});
  const [arrForDashbored, setArrForDashbored] = useState([]);
  const [getDashboredValues, setDashboredValue] = useState([]);
  useEffect(() => {
    setloading(true);
    setLoadingMsg({
      title: "Retrieving Dashboard",
      content: "Preparing Stats",
    });
    setArrForDashbored([
      {
        spanStyle: "textStyles",
        className: "avatar-lg rounded-circle bg-soft-primary border-primary border",
        image: <PersonIcon className="imgStylesPrimary" />,
        text: "Total Customers",
      },
      {
        spanStyle: "textStyles",
        className: "avatar-lg rounded-circle bg-soft-success border-success border",
        image: <PublicIcon className="imgStylesSuccess" />,
        text: "Uploaded Countries",
      },
      {
        spanStyle: "textStyles",
        className: "avatar-lg rounded-circle bg-soft-info border-info border",
        image: <CalendarMonthIcon className="imgStylesInfo" />,
        text: "Uploaded Data Range",
      },
      {
        spanStyle: "textStyles",
        className: "avatar-lg rounded-circle bg-soft-warning border-warning border",
        image: <StorageIcon className="imgStylesWarning" />,
        text: "Total Workspaces",
      },
      {
        spanStyle: "textStyles",
        className: "avatar-lg rounded-circle bg-soft-info border-info border",
        image: <CreditCardIcon className="imgStylesInfo" />,
        text: "Customer Records",
      },
      {
        spanStyle: "textStyles",
        className: "avatar-lg rounded-circle bg-soft-success border-success border",
        image: <SpeakerNotesIcon className="imgStylesSuccess" />,
        text: "Customer Credits",
      },
      {
        spanStyle: "textStyles",
        className: "avatar-lg rounded-circle bg-soft-warning border-warning border",
        image: <LayersIcon className="imgStylesWarning" />,
        text: "Total Customer Users",
      },
      {
        spanStyle: "textStyles",
        className: "avatar-lg rounded-circle bg-soft-primary border-primary border",
        image: <EventBusyIcon className="imgStylesPrimary" />,
        text: "Total Subscription",
      },
    ]);
    DashboardDetails();
  }, []);

  const DashboardDetails = () => {
    getDashboredData()
      .then((ele) => {
        if (ele?.status === 500 || ele?.status === 401) {
          throw ele?.status;
        } else {
          if (ele?.data) {
            setloading(false);
            const Data = ele?.data;
            let titlesForDashBoard = [];

            titlesForDashBoard = [
              {
                title: Data?.totalCustomers,
              },
              {
                title: Data?.uploadedCountries,
              },
              {
                title: "2018 - 2020",
              },
              {
                title: Data?.workspaceCount,
              },
              {
                title: Data?.totalRecords,
              },
              {
                title: 1500000,
              },
              {
                title: 988888,
              },
              {
                title: 9000,
              },
            ];

            setDashboredValue(titlesForDashBoard);
          } else {
            throw new Error();
          }
        }
      })
      .catch((err) => {
        setloading(false);
        seterror(true);
        seterrorMsg({
          title: err === 401 ? "Warning" : "Error",
          content: err === 500 ? "Internal Server Error" : err === 401 ? "Session TimeOut , Please Login Again" : "Something Went Wrong , Please Login Again",
        });
      });
  };
  return (
    <div id="dashboard">
      <Container maxWidth="xl">
        {loading && <Loading title={loadingMsg.title} content={loadingMsg.content} />}
        {error && <Error errorMsg={errorMsg} open={error} setOpen={seterror} />}
        <DashboardComponent arrForDashbored={arrForDashbored} getDashboredValues={getDashboredValues} />
      </Container>
    </div>
  );
}
