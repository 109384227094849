import React, { useState } from "react";
import "./upload.scss";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Loading from "../../../../Component/Loader/loading";

export default function Upload(props) {
  const [loading, setloading] = useState(false);
  const [loadingMsg, setloadingMsg] = useState({});
  const [progressValue, setProgressValue] = useState(0);
  const handleFileUpload = () => {
    const fileupload = document.getElementById("uploadFile");
    fileupload.click();
  };

  const getFileSize = (file) => {
    var _size = file;
    var fSExt = new Array("Bytes", "KB", "MB", "GB"),
      i = 0;
    while (_size > 900) {
      _size /= 1024;
      i++;
    }
    var exactSize = Math.round(_size * 100) / 100 + " " + fSExt[i];
    return exactSize;
  };

  const handleFileChange = (e) => {
    const _allSelectedFiles = [...props.allSelectedFiles];
    const _processStasticsRows = [...props.processStasticsRows];
    const _progressCounter = [...props.progressCounter];
    Object.values(e.target.files).forEach((ele) => {
      const fileSize = getFileSize(ele?.size);
      ele.exactSize = fileSize;
      props.setSelectedFile(ele);

      const isAlreadyUpload = _allSelectedFiles.filter((file) => file.name === ele?.name);

      if (isAlreadyUpload && isAlreadyUpload.length === 0) {
        _allSelectedFiles.push(ele);
        _processStasticsRows.push({
          name: ele?.name,
          tradeType: props.currentTradeType,
          country: props.currentCountry,
          trade_records: "-",
          validationTitle: "",
          validationContent: "",
          status: "",
          progress: "",
          Actions: "",
        });
        _progressCounter.push({
          file: ele?.name,
        });
      } else {
        setloading(true);
        setloadingMsg({
          title: "Duplicate File Auto-Removed",
          content: "File Discarded",
        });
        setTimeout(() => {
          setloading(false);
        }, 2000);
      }
    });
    props.setAllSelectedFiles(_allSelectedFiles);
    props.setProgressCounter(_progressCounter);
    props.setProcessStasticsRows(_processStasticsRows);
    // const formData = new FormData();
    // formData.append("myFile", e.target.files[0]);
    // console.log(formData);
  };

  const handleRemoveFile = (event, index) => {
    event.stopPropagation();
    const _allSelectedFiles = [...props.allSelectedFiles];
    const _processStasticsRows = [...props.processStasticsRows];
    _allSelectedFiles.splice(index, 1);
    _processStasticsRows.splice(index, 1);
    props.setAllSelectedFiles(_allSelectedFiles);
    props.setProcessStasticsRows(_processStasticsRows);
  };
  return (
    <div id="upload">
      {loading && <Loading loader={"hide"} title={loadingMsg.title} content={loadingMsg.content} />}
      <div className="card">
        <div class="card-body">
          <div class="card-widgets">
            <a id="expcolFileDropZone" data-toggle="collapse" href="#dropFileCard" role="button" aria-expanded="false" aria-controls="dropFileCard" rel="noopener noreferrer">
              <i class="mdi mdi-minus"></i>
            </a>
          </div>
          <h4 class="upload-title">Select Files To Upload</h4>

          <div id="dropFileCard">
            <div class="dropzone" id="fileDropZone" onClick={handleFileUpload} onChange={handleFileChange}>
              <input type="file" id="uploadFile" style={{ display: "none" }} multiple />

              {props.allSelectedFiles && props.allSelectedFiles.length > 0 ? (
                props.allSelectedFiles.map((e, index) => {
                  return (
                    <div className="dz-preview">
                      <div className="dz-content">
                        <div className="dz-details">
                          <div className="dz-size">{e.exactSize}</div>
                          <div className="dz-filename" style={{ justifyContent: e.name.length < 10 && "center" }}>
                            {e.name.length > 10 ? e.name.slice(0, 10) + ".." : e.name}
                          </div>
                        </div>
                      </div>
                      <div className="dz-remove" onClick={(e) => handleRemoveFile(e, index)}>
                        Remove File
                      </div>
                    </div>
                  );
                })
              ) : (
                <div class="dz-message needsclick" style={{ textAlign: "center", padding: "40px" }}>
                  <CloudUploadIcon fontSize="large" style={{ fontSize: "60px" }} />
                  <h1 className="upload-title" style={{ margin: "0px", marginBottom: "15px" }}>
                    Drop files here or click to select files.
                  </h1>
                  <span class="text-muted" style={{ marginTop: "10px" }}>
                    (Selected files are <strong>subjected</strong> to strict parse rules.)
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
