import { Container, Grid, Button, TextField, FormControl, Select, MenuItem, OutlinedInput } from "@mui/material";
import { getAllAccountsList, getAllUsersList, addNotifications } from "../../Services/service";
import "./notification.scss";
import React, { useState } from "react";
import InputField from "../../Component/InputField/inputField";
import { experimentalStyled as styled, useTheme } from "@mui/material/styles";
import { Box } from "@mui/system";
import { useEffect } from "react";
import Error from "../../Component/ErrorLoader/error";
import ApproveSuccessModal from "../../Component/ApprovSuccessModal/approvSuccessModal";

const SuccessBtn = styled(Button)({
  color: "#fff",
  backgroundColor: "#1abc9c",
  borderColor: "#1abc9c",
  marginLeft: "10px",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "#159a80 !important",
    borderColor: "#148f77 !important",
  },
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, availableCountries, theme) {
  return {
    fontWeight: availableCountries.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
  };
}

export default function Notifications() {
  const theme = useTheme();
  const [loading, setloading] = useState(false);
  const [loadingMsg, setLoadingMsg] = useState({});
  const [error, seterror] = useState(false);
  const [errorMsg, seterrorMsg] = useState({});
  const [successModal, setSuccessModal] = useState(false);
  const [allUserList, setAllUserList] = useState([]);
  const [allAccountList, setAllAccountList] = useState([]);
  const [selectedUserOrAccount, setSelectedUserOrAccount] = useState([]);
  const [notificationDetails, setNotificationDetails] = useState({
    notiType: "general",
    heading: "",
    description: "",
  });
  const [emptyField, setEmptyField] = useState({
    heading: false,
    description: false,
  });

  useEffect(() => {
    getAllAccountsList()
      .then((account) => {
        if (account?.status === 500 || account?.status === 401) {
          throw account?.status;
        } else {
          if (account?.data) {
            const _allAcounts = [];
            account?.data.forEach((e) => {
              _allAcounts.push({
                title: e?.access?.email_id,
                value: e?._id,
              });
            });
            setAllAccountList(_allAcounts);
          } else {
            throw new Error();
          }
        }
      })
      .catch((err) => {
        setloading(false);
        seterror(true);
        seterrorMsg({
          title: err === 401 ? "Warning" : "Error",
          content: err === 500 ? "Internal Server Error" : err === 401 ? "Session TimeOut , Please Login Again" : "Something Went Wrong , Please Login Again",
        });
      });

    getAllUsersList()
      .then((user) => {
        if (user?.status === 500 || user?.status === 401) {
          throw user?.status;
        } else {
          if (user?.data) {
            const _allUsers = [];
            user?.data.forEach((e) => {
              _allUsers.push({
                title: e?.email_id,
                value: e?._id,
              });
            });
            setAllUserList(_allUsers);
          } else {
            throw new Error();
          }
        }
      })
      .catch((err) => {
        setloading(false);
        seterror(true);
        seterrorMsg({
          title: err === 401 ? "Warning" : "Error",
          content: err === 500 ? "Internal Server Error" : err === 401 ? "Session TimeOut , Please Login Again" : "Something Went Wrong , Please Login Again",
        });
      });
  }, []);

  const handleNotificationDetails = (event) => {
    setSelectedUserOrAccount([])
    setNotificationDetails({
      ...notificationDetails,
      [event.target.name]: event.target.value,
    });
  };

  const validateNotiFields = () => {
    let _emptyField = { ...emptyField };
    if (notificationDetails?.heading === "") {
      _emptyField = {
        ..._emptyField,
        heading: true,
      };
      setEmptyField(_emptyField);
      return false;
    }
    if (notificationDetails?.description === "") {
      _emptyField = {
        ..._emptyField,
        description: true,
      };
      setEmptyField(_emptyField);
      return false;
    }
  };
  const handleSubmitClick = () => {
    if (validateNotiFields() === false) {
      return;
    }

    const notificationsPayload = {
      notificationType: notificationDetails?.notiType?.toLowerCase(),
      notificationDetails: {
        heading: notificationDetails.heading,
        description: notificationDetails.description,
        link: "",
      },
    };
    if (notificationDetails?.notiType?.toLowerCase() === "account") {
      notificationDetails.account_id = selectedUserOrAccount;
    } else if (notificationDetails?.notiType?.toLowerCase === "user") {
      notificationDetails.user_id = selectedUserOrAccount;
    }
    addNotifications(notificationsPayload).then((e) => {
      if (e?.status === 500 || e?.status === 401) {
        throw e?.status;
      } else {
        setSuccessModal(true);
        setTimeout(() => {
          setSuccessModal(false);
        }, 3000);
      }
    });
  };

  const handleSelectAccountOrUser = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedUserOrAccount(typeof value === "string" ? value.split(",") : value);
  };

  return (
    <Container maxWidth="xxl" id="notification">
      {error && <Error open={error} setOpen={seterror} errorMsg={errorMsg} />}
      {successModal && <ApproveSuccessModal Open={successModal} title="Successfully Update Customer Details!" footerTitle="Updated Successfull" />}
      <div className="main-flex">
        <div className="row-dashbored">
          <h3 className="text">
            Manage
            <span className="badge bg-soft-blue text-blue label-worksapce-name">Notifications</span>
          </h3>
        </div>

        <Box className="mlAuto">
          <Button
            className={notificationDetails.notiType === "general" ? "trade-selectedButton" : "trade-tabButton"}
            name="notiType"
            value="general"
            onClick={(e) => handleNotificationDetails(e)}
          >
            General
          </Button>
          <Button
            className={notificationDetails.notiType === "Account" ? "trade-selectedButton" : "trade-tabButton"}
            name="notiType"
            value="Account"
            onClick={(e) => handleNotificationDetails(e)}
          >
            Account
          </Button>
          <Button
            className={notificationDetails.notiType === "User" ? "trade-selectedButton" : "trade-tabButton"}
            name="notiType"
            value="User"
            onClick={(e) => handleNotificationDetails(e)}
          >
            User
          </Button>
        </Box>
      </div>

      <Grid container>
        <Grid item style={{ margin: "auto", width: "900px" }}>
          <label>Heading</label>
          <InputField
            type="text"
            placeholder="Type Something"
            value={notificationDetails.heading}
            setValue={(event) => handleNotificationDetails(event)}
            name="heading"
            width="100%"
            error={emptyField.heading ? true : false}
            helperText="This Value is Required"
          />
        </Grid>
      </Grid>

      {(notificationDetails.notiType === "Account" || notificationDetails.notiType === "User") && (
        <Grid container>
          <Grid item style={{ margin: "auto", width: "900px" }}>
            <label style={{ padding: "10px 0px" }}>{notificationDetails.notiType}</label>
            <FormControl style={{ width: "100%", background: "white" }} sx={{ mt: 0.8 }}>
              <Select
                style={{ height: "40px" }}
                multiple
                displayEmpty
                // error={props.customerPlanEmptyFields.country && props.selectedCountry.length === 0 ? true : false}
                // helperText={props.customerPlanEmptyFields.country && props.selectedCountry.length === 0 ? "This Value is Required" : ""}
                value={selectedUserOrAccount}
                onChange={handleSelectAccountOrUser}
                input={<OutlinedInput />}
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return <em>Choose {notificationDetails.notiType} ...</em>;
                  }
                  return selected.join(", ");
                }}
                MenuProps={MenuProps}
                inputProps={{ "aria-label": "Without label" }}
              >
                {(notificationDetails.notiType === "Account" ? allAccountList : allUserList).map((ele) => (
                  <MenuItem
                    key={ele.title}
                    value={ele.title}
                    style={getStyles(ele.title, notificationDetails.notiType === "Account" ? allAccountList : allUserList, theme)}
                  >
                    {ele.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      )}

      <Grid container>
        <Grid item style={{ display: "flex", flexDirection: "column", margin: "auto" }}>
          <label className="ml-10" style={{ padding: "10px 0px" }}>
            Description
          </label>

          <TextField
            id="outlined-multiline-static"
            placeholder="Type Something"
            multiline
            rows={4}
            name="description"
            value={notificationDetails.description}
            onChange={(val) => handleNotificationDetails(val)}
            style={{ width: "900px", background: "white" }}
            error={emptyField.heading && notificationDetails.description === "" ? true : false}
            helperText={emptyField.heading && notificationDetails.description === "" ? "This Value is Required" : ""}
          />
        </Grid>

        <Grid container style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
          <Grid item>
            <SuccessBtn onClick={handleSubmitClick}>Submit</SuccessBtn>
          </Grid>
          {/* <Grid item>
            <DanderBtn>Cancel</DanderBtn>
          </Grid> */}
        </Grid>
      </Grid>
    </Container>
  );
}
