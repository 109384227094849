import { styled } from "@mui/material/styles";
import { Grid, Paper, Box } from "@mui/material";
import "./dashboard.scss";
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  textAlign: "center",
  margin: "15px 0px",
  color: theme.palette.text.secondary,
  boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
  padding: "14px",
}));

const DashboardComponent = (props) => {
  return (
    <Box id="dashBoardComponent">
      <div className="row-dashbored">
        <h4 className="text">
          Dashboard
          <span className="badge bg-soft-blue text-blue label-worksapce-name">Current Highlights</span>
        </h4>
      </div>
      <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 2, sm: 8, md: 12 }}>
        {props.arrForDashbored.length > 0 &&
          props.arrForDashbored.map((e, index) => (
            <Grid key={index} item xs={2} sm={3} md={3}>
              <Item>
                <div className="row-dashbored rounded-circle">
                  <div className={e.className}>{e.image}</div>
                  <div className="text-right ml-auto">
                    <h3>
                      <span className={e.spanStyle}>{props?.getDashboredValues[index]?.title}</span>
                    </h3>
                    <p className="text-muted  text-truncate">{e.text}</p>
                  </div>
                </div>
              </Item>
            </Grid>
          ))}
      </Grid>
    </Box>
  );
};

export default DashboardComponent;
