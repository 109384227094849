import React, { useState } from "react";
import { Grid, FormControl, Select, MenuItem, Box } from "@mui/material";
import InputField from "../../../../Component/InputField/inputField";
import fetchCountryData from "../../../../fetchCountryData";
import SelectDropdown from "../../../../Component/SelectDropdown/selectDropdown";
import { validateFields } from "../../../../Helpers/validation";
export default function AddCustomerModal(props) {
  const heardOption = [{ title: "Choose..." }, { title: "Press" }, { title: "Internet" }, { title: "Word of mouth" }, { title: "Other" }];
  const [customerCompanyDetails, setCustomerCompanyDetails] = useState(props.customerCompanyDetails);
  const [customerCompanyEmptyFields, setCustomerCompanyEmptyFields] = useState({});

  const handleCustomerCompanyDetails = (event) => {
    const _customerCompanyDetails = {
      ...customerCompanyDetails,
      [event.target.name]: event.target.value,
    };
    setCustomerCompanyDetails(_customerCompanyDetails);
    props.setCustomerCompanyDetails(_customerCompanyDetails);
  };

  const handleCompanyNextBtn = () => {
    const companyDetailsFields = {
      name: customerCompanyDetails.name,
      phone_no: customerCompanyDetails.phone_no,
      // country: customerCompanyDetails.country,
      pin_code: customerCompanyDetails.pin_code,
      address: customerCompanyDetails.address,
      email_id: customerCompanyDetails.email_id,
      // referral_medium: customerCompanyDetails.referral_medium,
    };

    const emptyFields = validateFields(companyDetailsFields);
    setCustomerCompanyEmptyFields(emptyFields);

    if (Object.keys(emptyFields).length === 0) {
      props.setIsOpenPlanModalAddCustomer(true);
      props.setIsOpenAddCustomer(false);
    }
  };

  return (
    <div id="addCustomerModal">
      <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 2, sm: 8, md: 12 }} style={{ paddingBottom: "15px" }}>
        <Grid item xs={4} sm={4} md={4} xl={4}>
          <label>
            Company Name <span className="required-danger"> *</span>
          </label>
          <InputField
            type="text"
            value={customerCompanyDetails.name}
            setValue={(event) => handleCustomerCompanyDetails(event)}
            name="name"
            error={customerCompanyEmptyFields.name ? true : false}
            helperText="This Value is Required"
          />
        </Grid>
        <Grid item xs={4} sm={4} md={4} xl={4}>
          <label>Company Email</label>
          <InputField type="email" value={customerCompanyDetails.email} setValue={(event) => handleCustomerCompanyDetails(event)} name="email" />
        </Grid>
        <Grid item xs={4} sm={4} md={4} xl={4}>
          <label>Website Url</label>
          <InputField type="text" value={customerCompanyDetails.website_url} setValue={(event) => handleCustomerCompanyDetails(event)} name="website_url" />
        </Grid>
      </Grid>

      <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 2, sm: 8, md: 12 }} style={{ paddingBottom: "15px" }}>
        <Grid item xs={4} sm={4} md={4} xl={4}>
          <label>
            Phone No <span className="required-danger"> *</span>
          </label>
          <InputField
            type="number"
            value={customerCompanyDetails.phone_no}
            setValue={(event) => handleCustomerCompanyDetails(event)}
            name="phone_no"
            error={customerCompanyEmptyFields.phone_no ? true : false}
            helperText="This Value is Required"
          />
        </Grid>
        <Grid item xs={4} sm={4} md={4} xl={4}>
          <label>Tax Identification Number (TIN)</label>
          <InputField
            type="text"
            value={customerCompanyDetails.tax_identification_no}
            setValue={(event) => handleCustomerCompanyDetails(event)}
            name="tax_identification_no"
          />
        </Grid>
        <Grid item xs={4} sm={4} md={4} xl={4}>
          <label>
            Country <span className="required-danger"> *</span>
          </label>
          <Box style={{marginTop : "7px"}}>
            <SelectDropdown
              width="100%"
              value={customerCompanyDetails.country}
              option={fetchCountryData}
              optionValue={"title"}
              name="country"
              showLable={false}
              setValue={(event) => handleCustomerCompanyDetails(event)}
              error={customerCompanyEmptyFields.country ? true : false}
              helperText="This Value is Required"
            />
          </Box>
        </Grid>
      </Grid>

      <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 2, sm: 8, md: 12 }} style={{ paddingBottom: "15px" }}>
        <Grid item xs={4} sm={4} md={4} xl={4}>
          <label>
            Pin Code <span className="required-danger"> *</span>
          </label>
          <InputField
            type="number"
            value={customerCompanyDetails.pin_code}
            setValue={(event) => handleCustomerCompanyDetails(event)}
            name="pin_code"
            error={customerCompanyEmptyFields.pin_code ? true : false}
            helperText="This Value is Required"
          />
        </Grid>
        <Grid item xs={4} sm={4} md={4} xl={4}>
          <label>
            Address <span className="required-danger"> *</span>
          </label>
          <InputField
            type="text"
            value={customerCompanyDetails.address}
            setValue={(event) => handleCustomerCompanyDetails(event)}
            name="address"
            error={customerCompanyEmptyFields.address ? true : false}
            helperText="This Value is Required"
          />
        </Grid>
        <Grid item xs={4} sm={4} md={4} xl={4}>
          <label>
            Email Id <span className="required-danger"> *</span>
          </label>
          <InputField
            type="text"
            value={customerCompanyDetails.email_id}
            setValue={(event) => handleCustomerCompanyDetails(event)}
            name="email_id"
            error={customerCompanyEmptyFields.email_id ? true : false}
            helperText="This Value is Required"
          />
        </Grid>
      </Grid>

      <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 2, sm: 8, md: 12 }}>
        <Grid item xs={4} sm={4} md={4} xl={4}>
          <label>
            Heard about Eximpedia via <span className="required-danger"> *</span>
          </label>
          <SelectDropdown
            width="100%"
            value={customerCompanyDetails.referral_medium}
            option={heardOption}
            optionValue={"title"}
            name="referral_medium"
            showLable={false}
            setValue={(event) => handleCustomerCompanyDetails(event)}
            error={customerCompanyEmptyFields.referral_medium ? true : false}
            helperText="This Value is Required"
          />
        </Grid>
      </Grid>

      <Grid container className="d-flex-center" style={{ marginTop: "30px" }}>
        <Grid style={{ margin: "auto", width: "160px" }}>
          <button className="button-color" onClick={handleCompanyNextBtn}>
            Next
          </button>
        </Grid>
      </Grid>
    </div>
  );
}
