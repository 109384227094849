import ErrorPage from "../Component/ErrorPage/errorPage";
import Login from "../Containers/Login/login";
import LogOut from "../Containers/Logout/logout";
import Dashboard from "../Containers/Dashboard/dashboard";
import Build from "../Containers/Trade/Build/build";
import Ledger from "../Containers/Trade/Ledger/ledger";
import Customers from "../Containers/Customers/customers";
import ActivityTracker from "../Containers/ActivityTracker/activityTracker";
import ShipmentsRequest from "../Containers/ShipmentsRequest/shipmentsRequest";
import Notifications from "../Containers/Notifications/notification";
import AccountProfile from "../Containers/Account/account";
import TrainingVideoFeedback from "../Containers/TrainingVideoFeedback/TrainingVideoFeedback";
export const pageRoutes = [
  {
    path: "/",
    exact: true,
    element: Dashboard,
  },
  {
    path: "/providers/logout",
    exact: true,
    element: LogOut,
  },
  {
    path: "/providers/dashboard",
    exact: true,
    element: Dashboard,
  },
  {
    path: "/providers/trade/builder",
    exact: true,
    element: Build,
  },
  {
    path: "/providers/trade/ledger",
    exact: true,
    element: Ledger,
  },
  {
    path: "/providers/customers",
    exact: true,
    element: Customers,
  },
  {
    path: "/providers/activity-tracker",
    exact: true,
    element: ActivityTracker,
  },
  {
    path: "/providers/shipments-request",
    exact: true,
    element: ShipmentsRequest,
  },
  {
    path: "/providers/notifications",
    exact: true,
    element: Notifications,
  },
  {
    path: "/providers/feedback",
    exact: true,
    element: TrainingVideoFeedback,
  },
  {
    path: "/providers/accounts/profile",
    exact: true,
    element: AccountProfile,
  },
  {
    path: "*",
    exact: true,
    element: ErrorPage,
  },
];

export const authRoutes = [
  {
    path: "/providers/login",
    exact: true,
    element: Login,
  },
];
