import "./login.scss";
import React, { useState } from "react";
import { getLoginDetails } from "../../Services/service";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import Loading from "../../Component/Loader/loading";
import Error from "../../Component/ErrorLoader/error";
import LoginPage from "../../Component/LoginPage/loginPage";
import { debounce } from "@mui/material";

const DisplayingErrorMessagesSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("This value is required."),
  password: Yup.string().required("This value is required."),
});
const Login = (props) => {
  const navigate = useNavigate();
  const [loading, setloading] = useState(false);
  const [error, seterror] = useState(false);
  const [errorMsg, seterrorMsg] = useState({});
  function login(values) {
    setloading(true);
    let loginDetails = {};
    loginDetails.email_id = values.email;
    loginDetails.password = values.password;
    loginDetails.scope = "PROVIDER";

    getLoginDetails(loginDetails)
      .then((accountLoginBundlePack) => {
        if (!accountLoginBundlePack.data) {
          setTimeout(() => {
            setloading(false);

            seterror(true);
            seterrorMsg({
              title: "Account Anomaly",
              content: "There has been some error while logging in for this account! Please contact Support",
              open: true,
            });
          }, 1000);
        } else if (accountLoginBundlePack?.data?.type === "MATCHED") {
          seterror(false);
          document.cookie = `accountId=${accountLoginBundlePack.data.account_id}; path=/;secure`;
          document.cookie = `user_id=${accountLoginBundlePack.data.user_id}; path=/;secure`;
          document.cookie = `firstName=${accountLoginBundlePack.data.firstName}; path=/;secure`;
          document.cookie = `tokenUI=${accountLoginBundlePack.data.token}; path=/;secure`;
          document.cookie = `lastName=${accountLoginBundlePack.data.lastName}; path=/;secure`;
          document.cookie = `email_id=${accountLoginBundlePack.data.email_id}; path=/;secure`;
          setTimeout(() => {
            navigate("/providers/dashboard");
            setloading(false);
          }, 1000);
        } else {
          setloading(false);
          seterror(true);
          seterrorMsg({
            title: accountLoginBundlePack.data.msg,
            content: accountLoginBundlePack.data.desc,
            open: true,
          });
        }
      })
      .catch((e) => {
        setloading(false);
        seterror(true);
        seterrorMsg({
          title: "Error",
          content: "Something Went Wrong , Please Login Again",
        });
      });
  }

  return (
    <div id="login-page">
      {error && <Error errorMsg={errorMsg} open={error} setOpen={seterror} />}
      {loading && <Loading title={"Access Guard"} content={"Verifying Account Login"} />}
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        validationSchema={DisplayingErrorMessagesSchema}
        onSubmit={(values) => {
          login(values);
        }}
      >
        {({ errors, touched }) => (
          <LoginPage
            login="true"
            text=" Enter your email address and password to access
          admin panel"
            subject1="Email Id"
            type1="email"
            name1="email"
            subject1Placeholder="Enter a valid e-mail"
            touched1={touched.email}
            errors1={errors.email}
            subject2="Password"
            type2="password"
            name2="password"
            subject2Placeholder="Password"
            touched2={touched.password}
            errors2={errors.password}
            buttonText="Log In"
          />
        )}
      </Formik>
    </div>
  );
};
export default Login;
