import * as React from "react";
import { Box, Button, Typography, Modal } from "@mui/material";
import "./modal.scss";
import { experimentalStyled as styled } from "@mui/material/styles";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import PrintIcon from "@mui/icons-material/Print";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  save: {
    fontFamily: "Rubik",
    marginRight: "10px",
    color: "white",
  },
});
const FilterButton = styled(Button)({
  backgroundColor: "#005d91 !important",
  borderColor: "transparent !important",
  color: "white",
});

export default function ModalComponent(props) {
  const classes = useStyles();

  return (
    <div>
      <Modal id="Modal" open={props.open} onClose={() => props.setOpen(false)}>
        <Box style={{ width: props.width, height: props.height }} className="modal-styles">
          <div className="d-flex main">
            <Typography className="headerText" id="modal-modal-title" variant="h6" component="h2">
              {props.header}
            </Typography>
            <div className="m_10">
              <span
                className="close"
                onClick={() => {
                  props.setOpen(false);
                }}
              >
                ×
              </span>
            </div>
          </div>

          <div>
            <div className="modalBody">{props.modalBody}</div>
            <div className="hr"></div>
            <div className="footer-modal">
              <div className="footer-modal-content">
                {props.saveButtonShown ? (
                  <Button
                    style={{ backgroundColor: props.saveButtonColor, marginRight: "10px", color: "white" }}
                    className={classes.save}
                    onClick={() => props.savedClicked(true)}
                  >
                    {props.saveText}
                  </Button>
                ) : (
                  ""
                )}
                <Button
                  onClick={() => {
                    props.setOpen(false);
                  }}
                  className="closeButton"
                  variant="contained"
                >
                  Close
                </Button>
              </div>
              {props.IsNextPrevBtnShown && (
                <div className="next-prev">
                  {props.prevOrNext === 0 ? (
                    <Button variant="contained" disabled startIcon={<ArrowBackIosNewIcon size="small" />}>
                      Previous
                    </Button>
                  ) : (
                    <FilterButton
                      onClick={() => props.previousDataTableRecords()}
                      variant="contained"
                      size="medium"
                      startIcon={<ArrowBackIosNewIcon size="small" />}
                    >
                      Previous
                    </FilterButton>
                  )}

                  {props.prevOrNext + 1 >= props.rowsPerPage ? (
                    <Button variant="contained" style={{ marginLeft: "10px" }} disabled endIcon={<ArrowForwardIosIcon className="iconWidth" size="small" />}>
                      Next
                    </Button>
                  ) : (
                    <FilterButton
                      style={{ marginLeft: "10px" }}
                      onClick={() => props.nextDataTableRecords()}
                      variant="contained"
                      size="medium"
                      endIcon={<ArrowForwardIosIcon className="iconWidth" size="small" />}
                    >
                      Next
                    </FilterButton>
                  )}

                  <FilterButton
                    onClick={() => props.handlePrintTableRecords()}
                    variant="contained"
                    size="medium"
                    startIcon={<PrintIcon size="small" />}
                    className="print"
                  >
                    Print
                  </FilterButton>

                  <FilterButton
                    onClick={() => props.handleSummaryDataForTrade()}
                    variant="contained"
                    size="medium"
                    startIcon={<RemoveRedEyeIcon size="small" />}
                    className="summary"
                  >
                    View Summary
                  </FilterButton>
                </div>
              )}
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
