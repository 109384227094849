import React from "react";
import { TextField } from "@mui/material";
// import "./inputField.scss";
export default function InputField(props) {
  return (
    // <div>
    <TextField
      className="ml-10 search-form-control input-field"
      onChange={(e) => props.setValue(e)}
      label={props.label}
      name={props.name}
      type={props.type}
      format={"DD/MM/YYYY"}
      value={props.value}
      sx={{ width: props.width ? props.width : "100%", mt: 0.8 }}
      InputLabelProps={{
        shrink: true,
      }}
      disabled={props.disabled}
      placeholder={props.placeholder}
      // style={{height : props.height}}
      size="small"
      error={props.error && props.value === ""}
      helperText={props.error && props.value === "" ? props.helperText : ""}
    />
    // </div>
  );
}
