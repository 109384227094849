export const tradeOption = {
    IMPORT: "Import",
    EXPORT: "Export",
};
export const getMonthNumber = (month) => {
    const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];
    return months[month - 1];
};