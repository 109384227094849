import "./App.scss";
import React from "react";
import { authRoutes, pageRoutes} from "./Routes/Routes";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import ProtectedForPage from "./Component/ProtectedForPage/ProtectedForPage";
import ProtectedForAuth from "./Component/ProtectedForAuth/ProtectForAuth";
function App() {
  return (
    <div className="app-container">
      <Router>
        <Routes>
          {pageRoutes.map((route, index) => {
            return (
              route.element && <Route key={index} path={route.path} name={route.name} element={<ProtectedForPage route={route.path} cmp={route.element} />} />
            );
          })}

          {authRoutes.map((route, idx) => {
            return <Route key={idx} path={route.path} element={<ProtectedForAuth cmp={route.element} />}></Route>;
          })}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
