import * as React from "react";
import "./collapseTable.scss";
import { Button, Box, Collapse, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Pagination } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

import { experimentalStyled as styled } from "@mui/material/styles";
import TablePagionationDropdown from "../TablePaginationDropdown/TablePaginationDropdown";

const AnalyseBtn = styled(Button)({
  color: "#fff",
  backgroundColor: "#005D91",
  borderColor: " #005D91",

  "&:hover": {
    backgroundColor: "#07527D !important",
    borderColor: "#07527D !important",
  },
});

function Row(props) {
  const [open, setOpen] = React.useState(false);

  const handleOpen = (row) => {
    if (props.activityTracker) {
      props.handleOpenCollapseTable(row, !open);
      setOpen(!open);
    }
  };

  return (
    <React.Fragment>
      <TableRow>
        {props.columns.map((e) => {
          if (e.key === "add") {
            return (
              <>
                <TableCell className="collapsible-rows" style={{ width: "10px" }}>
                  <IconButton aria-label="expand row" size="small" onClick={() => handleOpen(props.row)}>
                    {open ? <RemoveIcon /> : <AddIcon />}
                  </IconButton>
                </TableCell>
              </>
            );
          } else if (e.key === "No") {
            return (
              <>
                <TableCell className="collapsible-rows" style={{ width: "10px", textAlign: "center" }}>
                  {props?.index + 1}
                </TableCell>
              </>
            );
          } else if (e.key === "role") {
            return <TableCell className="collapsible-rows">{props.row?.userData[0][e.key]}</TableCell>;
          } else if (e.key === "Name") {
            const Name = props.row?.userData[0]?.first_name + " " + props.row?.userData[0]?.last_name;
            return <TableCell className="collapsible-rows">{Name}</TableCell>;
          } else if (e.key === "analyze") {
            return (
              <TableCell className="collapsible-rows">
                <AnalyseBtn onClick={() => props.handleAnalyseRecord("parent", props.row)}>Analyze</AnalyseBtn>
              </TableCell>
            );
          } else {
            return <TableCell className="collapsible-rows">{props.row[e.key]}</TableCell>;
          }
        })}
      </TableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: open ? 12 : 0, paddingTop: open ? 12 : 0 }} colSpan="100%">
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1, width: props.collapseTableSize, overflow: "auto", boxShadow: "0 4px 16px 0 rgb(0 0 0 / 20%)" }}>
              <Table size="small" aria-label="purchases" stickyHeader>
                <TableHead>
                  <TableRow>
                    {props.userListChildTableHeader.map((e) => {
                      return <TableCell className="collapsible-header">{e.title}</TableCell>;
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.activityTracker &&
                    props.childData[props?.row?.email_id] &&
                    !!props.childData[props?.row?.email_id] &&
                    props.childData[props?.row?.email_id].map((ele, index) => {
                      return (
                        <TableRow>
                          {props.userListChildTableHeader.map((column) => {
                            if (column.key === "analyze") {
                              return (
                                <TableCell className="collapsible-rows">
                                  <AnalyseBtn onClick={() => props.handleAnalyseRecord("child", ele)}>Analyze</AnalyseBtn>
                                </TableCell>
                              );
                            } else if (column.key === "No") {
                              return (
                                <>
                                  <TableCell className="collapsible-rows" style={{ width: "10px", textAlign: "center" }}>
                                    {index + 1}
                                  </TableCell>
                                </>
                              );
                            } else if (column.key === "Name") {
                              const Name = props.row?.userData[0]?.first_name + " " + props.row?.userData[0]?.last_name;
                              return <TableCell className="collapsible-rows">{Name}</TableCell>;
                            } else if (column.key === "add") {
                              return (
                                <TableCell className="collapsible-rows">
                                  <AnalyseBtn onClick={() => props.handleAnalyseRecord(ele)}>Analyze</AnalyseBtn>
                                </TableCell>
                              );
                            } else {
                              return <TableCell className="collapsible-rows">{ele[column.key]}</TableCell>;
                            }
                          })}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function CollapsibleTable(props) {
  return (
    <>
      {props.showPagination === true && <TablePagionationDropdown handleChange={props.handleChangeRowsPerPage} value={props.rowsPerPage} />}
      <TableContainer
        component={Paper}
        id="collapsible-table"
        style={{ height: !props.removeData ? (props.height ? props.height : "550px") : "", overflow: "overlay" }}
      >
        <Table aria-label="collapsible table" stickyHeader size="small">
          <TableHead>
            <TableRow>
              {props.columns.map((column) => {
                return (
                  <TableCell className="collapsible-header" style={{ position: "sticky", zIndex: "100" }}>
                    {column.title}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.rows.map((row, index) => {
              return (
                <Row
                  removeData={props.removeData}
                  columns={props.columns}
                  index={index}
                  row={row}
                  userListChildTableHeader={props.userListChildTableHeader}
                  childData={props.childData}
                  activityTracker={props.activityTracker}
                  handleOpenCollapseTable={props.handleOpenCollapseTable}
                  handleAnalyseRecord={props.handleAnalyseRecord}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {props.showPagination === true && !props.removeData ? (
        <Pagination count={Math.ceil(props.totalAvailableData / props.rowsPerPage)} page={props.page + 1} onChange={props.handleChangePage} />
      ) : (
        ""
      )}
    </>
  );
}
