import React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import CloseIcon from "../../Asset/images/close.png";
import WarningIcon from "../../Asset/images/warning.png";
import InfoIcon from "../../Asset/images/info.png";
import "./error.scss";
import { useNavigate } from "react-router-dom";

export default function Error(props) {
  // const [open, setOpen] = React.useState(true);

  const navigate = useNavigate();

  const redirectPage = () => {
    navigate("/providers/logout");
    props.setOpen(false);
  };
  return (
    <div id="error-loader">
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={props.open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.open}>
          <Box id="error-box">
            <Box sx={{ textAlign: "center", mt: 2 }}>
              {props.errorMsg.title === "Warning" ? (
                <img src={WarningIcon} alt="Warning" width="100px" />
              ) : props.errorMsg.title === "Info" ? (
                <img src={InfoIcon} alt="Info" width="90px" />
              ) : (
                <img src={CloseIcon} alt="CloseIcon" />
              )}
            </Box>
            <Typography
              id="transition-modal-title"
              variant="h6"
              component="h2"
              sx={{
                fontSize: "28px",
                fontWeight: 600,
                textAlign: "center",
                marginTop: "10px",
                opacity: 0.9,
                color: "#595959",
              }}
            >
              {props.errorMsg.title ? props.errorMsg.title : "error"}
            </Typography>
            <Typography
              id="transition-modal-description"
              sx={{
                marginTop: "10px",
                fontSize: "1.125em",
                fontweight: 300,
                wordwrap: "break-word",
                textAlign: "center",
                opacity: 0.6,
              }}
            >
              {props.errorMsg.content && props.errorMsg.content}
            </Typography>
            <Box sx={{ textAlign: "center", mt: 3 }} id="err-button">
              <Box sx={{ textAlign: "center", mt: 4 }}>
                <span
                  onClick={() =>
                    props.errorMsg?.content === "Something Went Wrong , Please Login Again" ||
                    props.errorMsg?.content === "Session TimeOut , Please Login Again" ||
                    props.errorMsg?.content === "User Details Updated Successfully , Please Login Again"
                      ? redirectPage()
                      : props.setOpen(false)
                  }
                  className="OK-button"
                >
                  OK
                </span>
              </Box>
            </Box>
            <Box sx={{ mt: 3 }}>
              {props.errorMsg?.content === "Something Went Wrong , Please Login Again" ||
              props.errorMsg?.content === "Session TimeOut , Please Login Again" ||
              props.errorMsg?.content === "User Details Updated Successfully , Please Login Again" ? (
                ""
              ) : (
                <Box id="refreshWidth" sx={{ mt: 5, textAlign: "center" }}>
                  <a href="" id="anchor" rel="noopener noreferrer">
                    Refresh Page & Try Again
                  </a>
                </Box>
              )}
            </Box>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
