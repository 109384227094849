import { BlobServiceClient } from "@azure/storage-blob";
import { getBlobToken } from "../Services/service";
import { tradeOption } from "./CommanFunctions";
import axios from "axios";

const uploadFile = async (
  allSelectedFiles,
  fileEntry,
  fileImportQueue,
  updateFileDataStageAPIHandler,
  updateUploadSyncProcess,
  updateUploadProgressCounter,
  ProcessStasticsRowsParams,
  monthAndYear
) => {
  const fileId = fileEntry?._id;
  const originalFileName = fileEntry?.file;
  const fileExtension = originalFileName.split(".").pop();
  const fileNameWithoutExtension = originalFileName.replace(
    "." + fileExtension,
    ""
  );
  const modifiedFileName = `${fileNameWithoutExtension}#${fileId}.${fileExtension}`;

  let blobToken;
  const uploadedFile = allSelectedFiles.find(
    (file) => file.name === fileEntry?.file
  );
  await getBlobToken()
    .then((e) => {
      if (e?.status === 500 || e?.status === 401) {
        throw e?.status;
      } else {
        if (e?.data?.blobUploadSaasToken) {
          blobToken = e?.data?.blobUploadSaasToken;
        }
      }
    })
    .catch((err) => {
      let errorCode =
        err === 500
          ? "Internal Server Error"
          : err === 401
          ? "Session TimeOut , Please Login Again"
          : "Something Went Wrong , Please Login Again";
      if (err === 401) {
        const userConfirmed = window.confirm(errorCode);
        if (userConfirmed || !userConfirmed) {
          window.location.href("/providers/logout");
        }
      } else {
        const userConfirm = window.confirm(errorCode);
        if (userConfirm || !userConfirm) {
          window.location.reload();
          return;
        }
      }
    });

  if (blobToken) {
    const containerName = process.env.REACT_APP_AZURE_CONTAINER_NAME;
    const blobServerName = process.env.REACT_APP_AZURE_BLOB_SERVER;
    const sasToken = `https://${blobServerName}.blob.core.windows.net/?${blobToken}`;
    const blobServiceClient = new BlobServiceClient(sasToken);
    const containerClient = blobServiceClient.getContainerClient(containerName);

    let mapCountryToAdxTableName = {
      SRILANKA: "SriLanka",
      USA: "USA",
      SOUTH_SUDAN: "SouthSudan",
      IVORY_COAST: "IvoryCoast",
      VIETNAM_2022: "Vietnam",
      BL_BRAZIL: "BLBrazil",
      BL: "BL",
    };

    if (fileEntry?.country.toUpperCase() in mapCountryToAdxTableName) {
      fileEntry.country =
        mapCountryToAdxTableName[fileEntry.country.toUpperCase()];
    }

    if (uploadedFile) {
      const { month, year } = monthAndYear;
      const tradeType = tradeOption[fileEntry.trade_type.toString()];
      const blobName = `${fileEntry?.country.toString()}/${tradeType}/${year}/${month}/${modifiedFileName}`;
      const blockBlobClient = containerClient.getBlockBlobClient(blobName);
      try {
        blockBlobClient
          .uploadData(uploadedFile, {
            onProgress: (ev) => {
              const totalBytes = uploadedFile.size;
              // Calculate the percentage and pass it to the progress callback
              const percentage = (ev.loadedBytes / totalBytes) * 100;
              updateUploadProgressCounter(
                ProcessStasticsRowsParams,
                fileEntry,
                percentage
              );
            },
          })
          .then(async (res) => {
            if (res._response.status === 200 || res._response.status === 201) {
              updateUploadProgressCounter(
                ProcessStasticsRowsParams,
                fileEntry,
                100
              );
              let updateUploadDataStage = null;

              for (let i in fileImportQueue) {
                if (
                  fileImportQueue[i].stage === "UPLOAD" &&
                  fileImportQueue[i].status === "P"
                ) {
                  fileImportQueue[i].status = "S";
                  fileImportQueue[i].errors = [];
                  updateUploadDataStage = fileImportQueue[i];
                  break;
                }
              }
              updateUploadSyncProcess(fileEntry, ProcessStasticsRowsParams);
              if (updateUploadDataStage) {
                try {
                  await triggerDataIngestionFunction(fileEntry, monthAndYear);
                } catch {
                  const userConfirm = window.alert("File Upload Failed...");
                  if (userConfirm || !userConfirm) {
                    window.location.reload();
                  }
                }

                updateFileDataStageAPIHandler(
                  updateUploadDataStage,
                  ProcessStasticsRowsParams
                );
              }
            } else {
              const userConfirm = window.alert("File Upload Failed...");
              if (userConfirm || !userConfirm) {
                window.location.reload();
              }
            }
          })
          .catch((error) => {
            const userConfirm = window.alert("File Upload Failed...");
            if (userConfirm || !userConfirm) {
              window.location.reload();
            }
          });
      } catch (error) {
        const userConfirm = window.confirm("File Upload Failed...");
        if (userConfirm || !userConfirm) {
          window.location.reload();
        }
      }
    }
  }
};

async function triggerDataIngestionFunction(fileEntry, monthAndYear) {
  try {
    const fileId = fileEntry?._id;
    const originalFileName = fileEntry?.file;
    const fileExtension = originalFileName.split(".").pop();
    const fileNameWithoutExtension = originalFileName.replace(
      "." + fileExtension,
      ""
    );
    const modifiedFileName = `${fileNameWithoutExtension}#${fileId}.${fileExtension}`;

    const { month, year } = monthAndYear;
    const tradeType = tradeOption[fileEntry.trade_type.toString()];
    const containerName = process.env.REACT_APP_AZURE_CONTAINER_NAME;
    const blobName = `${containerName}/${fileEntry?.country.toString()}/${tradeType}/${year}/${month}/${modifiedFileName}`;

    try {
      let functionEndpoint =
        "https://func-eximpedia-ingestion-ci-prod.azurewebsites.net/api/HttpPipelineTrigger";

      const response = await axios.post(functionEndpoint, {
        blobName: blobName,
      });

      if (response.status == 200) {
        console.log(response.data);
      } else {
        throw "File upload failed...";
      }
    } catch (error) {
      throw error;
    }
  } catch (error) {
    throw error;
  }
}

export default {
  uploadFile,
  triggerDataIngestionFunction,
};
