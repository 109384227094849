// import React from "react";
// import { InputLabel, MenuItem, FormControl, Select } from "@mui/material";
// export default function SelectDropdown(props) {
//   const title = props.optionValue;

//   return (
//     <div>
//       {props.showLable && <label style={{ position: "relative", top: "1rem", marginLeft: "40px", marginRight: "5px" }}>{props.label}</label>}
//       <FormControl
//         sx={{ minWidth: 120, background: "white", width: props.width, height: props.height , mt:props.mt ? props.mt : "" }}
//         size="small"
//         error={props.error && props.value === ""}
//         helperText={props.error && props.value === "" ? props.helperText : ""}
//       >
//         {/* <InputLabel id="demo-select-small">{props.text}</InputLabel> */}
//         <Select
//           labelId="demo-select-smal"
//           id="demo-select-small"
//           value={props.value}
//           // label={props.label}
//           onChange={(e) => props.setValue(e)}
//           style={{ maxHeight: "100px !important", overflowY: "auto !important" }}
//           name={props.name ? props.name : ""}
//         >
//           {props.option.length > 0 &&
//             props.option.map((e, index) => {
//               return (
//                 <MenuItem value={e[title]} key={index} defaultValue={props.option[0][title]}>
//                   {e[title].split("_").join(" ")}
//                 </MenuItem>
//               );
//             })}
//         </Select>
//       </FormControl>
//     </div>
//   );
// }

import React from "react";
import "./selectDropdown.scss";
export default function SelectDropdown(props) {
  const title = props.optionValue;

  return (
    <div class="select">
      <select onChange={(e) => props.setValue(e)} value={props.value} style={{ height: props.height, width: props.width }} name={props.name ? props.name : ""}>
        {props.intialOption && <option hidden>{props.intialOptionLable}</option>}
        {props.option.length > 0 &&
          props.option.map((e, index) => {
            return (
              <option value={props.defaultValue ? e[props.defaultValue] : e[title]} key={index} defaultValue={props.option[0][title]}>
                {e[title] && e[title].split("_").join(" ")}
              </option>
            );
          })}
      </select>
    </div>
  );
}
