import AuthPageComponant from "../AuthPageComponant/authPageComponant";

const ErrorPage = (props) => {
  return (
    <div>
      <AuthPageComponant name="ErrorPage" token={props.token} />
    </div>
  );
};

export default ErrorPage;
