import React, { useState, useEffect } from "react";
import { fetchLedgerFilesStats } from "../../../Services/service";
import { Box, Container, Button } from "@mui/material";
import DashboardComponent from "../../../Component/DashboardComponant/dashboard";
import DataTable from "../../../Component/DataTable/table";
import FolderIcon from "@mui/icons-material/Folder";
import LanguageIcon from "@mui/icons-material/Language";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import Spinner from "../../../Component/Loader/spinner";
import Loading from "../../../Component/Loader/loading";
import Error from "../../../Component/ErrorLoader/error";
import { useNavigate } from "react-router";
export default function Ledger() {
  const navigate = useNavigate();
  const [loading, setloading] = useState(false);
  const [spinner, setspinner] = useState(false);
  const [loadingMsg, setLoadingMsg] = useState({});
  const [errorMsg, seterrorMsg] = useState({});
  const [error, seterror] = useState(false);
  const [getTradeType, setTradeType] = useState("all");
  const [ledgerFilesStats, setLedgerFilesStats] = useState([]);
  const [initialLedgerFiles, setInitialLedgerFiles] = useState([]);
  const [ledgerFiles, setLedgerFiles] = useState([]);
  const [arrForSummary, setArrForSummary] = useState([]);
  const [summaryLedgerValue, setSummaryLedgerValue] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [ledgerFilesTableHeader, setLedgerFilesTableHeader] = useState([
    {
      key: "file",
      title: "Name",
    },
    {
      key: "ingested_at",
      title: "Ingested Date",
    },
    {
      key: "trade",
      title: "Trade",
    },
    {
      key: "country",
      title: "country",
    },
    {
      key: "year",
      title: "Year",
    },
    {
      key: "records",
      title: "Records",
    },
    // {
    //   key: "TotalPointsCost",
    //   title: "Data-Bucket",
    // },
    {
      key: "data_status",
      title: "Data Status",
    },
    {
      key: "error_type",
      title: "Error Type",
    },
    {
      key: "error_message",
      title: "Error Message",
    },
  ]);
  useEffect(() => {
    setspinner(true);
    getLedgerFiles(getTradeType);
  }, [getTradeType]);
  const getLedgerFiles = (getTradeType) => {
    fetchLedgerFilesStats(getTradeType)
      .then((e) => {
        setspinner(false);
        if (e?.status === 500 || e?.status === 401) {
          throw e?.status;
        } else {
          if (e?.data) {
            if (e?.data?.length) {
              setLedgerFilesStats(e.data[0]);
              setLedgerFiles(e.data[0].ledger_files.slice(0, 10));
              setInitialLedgerFiles(e.data[0].ledger_files);
              const summary = e.data[0].summary[0];
              setSummaryLedgerValue([
                {
                  title: summary?.totalFiles,
                },
                {
                  title: summary?.totalCountries,
                },
                {
                  title: summary?.totalRecords,
                },
                {
                  title: summary?.totalYears,
                },
              ]);
            }
          } else {
            throw new Error();
          }
        }
      })
      .catch((err) => {
        setloading(false);
        seterror(true);
        seterrorMsg({
          title: err === 401 ? "Warning" : "Error",
          content:
            err === 500
              ? "Internal Server Error"
              : err === 401
              ? "Session TimeOut , Please Login Again"
              : "Something Went Wrong , Please Login Again",
        });
      });

    setArrForSummary([
      {
        spanStyle: "textStyles",
        className:
          "avatar-lg rounded-circle bg-soft-primary border-primary border",
        image: <FolderIcon className="imgStylesPrimary" />,
        text: "Total Files",
      },
      {
        spanStyle: "textStyles",
        className:
          "avatar-lg rounded-circle bg-soft-success border-success border",
        image: <LanguageIcon className="imgStylesSuccess" />,
        text: "Total Countries",
      },
      {
        spanStyle: "textStyles",
        className: "avatar-lg rounded-circle bg-soft-info border-info border",
        image: <PeopleAltIcon className="imgStylesInfo" />,
        text: "Total Records",
      },
      {
        spanStyle: "textStyles",
        className:
          "avatar-lg rounded-circle bg-soft-warning border-warning border",
        image: <AccessTimeIcon className="imgStylesWarning" />,
        text: "Total Years",
      },
    ]);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
    // let offset = page * event.target.value;
    if (event.target.value < ledgerFilesStats?.ledger_files?.length) {
      setLedgerFiles(
        ledgerFilesStats.ledger_files.slice(0, event.target.value)
      );
    }
  };

  const handleChangePage = (event, newPage) => {
    let offset = (newPage - 1) * rowsPerPage;
    setPage(newPage - 1);
    if (event.target.value < ledgerFilesStats?.ledger_files?.length) {
      setLedgerFiles(
        ledgerFilesStats.ledger_files.slice(offset, offset + rowsPerPage)
      );
    }
  };

  const handleChangeTradeType = (e) => {
    setTradeType(e.target.name);
  };

  const handleSearchForTable = (value) => {
    const filteredRows =
      initialLedgerFiles &&
      !!initialLedgerFiles?.length &&
      initialLedgerFiles?.filter((row) => {
        return row["file"]?.toLowerCase().includes(value?.toLowerCase());
      });
    setLedgerFiles(filteredRows);
    setLedgerFilesStats({
      ...ledgerFilesStats,
      ledger_files: filteredRows,
    });
  };

  return (
    <Container maxWidth="xxl" id="ledger">
      {loading && (
        <Loading title={loadingMsg.title} content={loadingMsg.content} />
      )}
      {spinner && <Spinner />}
      {error && <Error errorMsg={errorMsg} open={error} setOpen={seterror} />}
      <Box style={{ float: "right" }}>
        <Button
          name="all"
          className={
            getTradeType === "all" ? "trade-selectedButton" : "trade-tabButton"
          }
          onClick={(e) => handleChangeTradeType(e)}
        >
          All
        </Button>
        <Button
          name="import"
          className={
            getTradeType === "import"
              ? "trade-selectedButton"
              : "trade-tabButton"
          }
          onClick={(e) => handleChangeTradeType(e)}
        >
          Import
        </Button>
        <Button
          name="export"
          className={
            getTradeType === "export"
              ? "trade-selectedButton"
              : "trade-tabButton"
          }
          onClick={(e) => handleChangeTradeType(e)}
        >
          Export
        </Button>
      </Box>
      <DashboardComponent
        arrForDashbored={arrForSummary}
        getDashboredValues={summaryLedgerValue}
      />
      <DataTable
        columns={ledgerFilesTableHeader}
        rows={ledgerFiles}
        rowsPerPage={rowsPerPage}
        page={page}
        showPagination={true}
        totalAvailableData={
          ledgerFilesStats.ledger_files !== undefined &&
          ledgerFilesStats.ledger_files.length
        }
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleChangePage={handleChangePage}
        handleSearchForTable={handleSearchForTable}
        searchFieldName="Search File"
        searching={true}
        searchIcon={false}
      />
    </Container>
  );
}
