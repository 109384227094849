import { Grid, TextField } from "@mui/material";
import React, { useState, useEffect } from "react";
import Error from "../../../Component/ErrorLoader/error";
import ApproveSuccessModal from "../../../Component/ApprovSuccessModal/approvSuccessModal";
import ModalComponent from "../../../Component/Modal/modal";
import { validateFields } from "../../../Helpers/validation";
import { updateConsigneeRequest } from "../../../Services/service";

export default function UpdateRequest(props) {
  const intialUpdateReqData = { buyer_name: "", buyer_address: "", buyer_email: "", buyer_phone_number: "", buyer_concerned_person: "" };
  const [error, seterror] = useState(false);
  const [errorMsg, seterrorMsg] = useState({});
  const [updateReqData, setUpdateReqData] = useState(intialUpdateReqData);
  const [emptyField, setEmptyField] = useState({
    buyer_name: false,
    buyer_address: false,
  });
  const [successPayload, setSuccessPayload] = useState({});

  const handleUpdateReqDataChange = (e) => {
    setUpdateReqData({
      ...updateReqData,
      [e.target.name]: e.target.value,
    });
  };

  const validateUpdateRequireField = () => {
    const updateReqValidateFields = {
      buyer_name: updateReqData.buyer_name,
      buyer_address: updateReqData.buyer_address,
    };

    return validateFields(updateReqValidateFields);
  };

  useEffect(() => {
    setEmptyField({});
    setUpdateReqData(intialUpdateReqData);
  }, [props.isUpadteReqModal]);

  const handleUpdateReqClick = () => {
    const _emptyFields = validateUpdateRequireField();
    setEmptyField(_emptyFields);
    if (Object.keys(_emptyFields).length === 0) {
      const _updateReqData = { ...updateReqData };
      _updateReqData.shipment_number = props?.selectedUpdatedData?.shipment_number;
      _updateReqData.userId = props?.selectedUpdatedData?.userId;

      updateConsigneeRequest(_updateReqData)
        .then((e) => {
          if (e?.status === 500 || e?.status === 401) {
            throw e?.status;
          } else {
            if (e?.data === "Request Updated Successfully.") {
              props.setIsUpadteReqModal(false);
              setSuccessPayload({
                open: true,
                title: "Successfully Update Request!",
                footerTitle: "Updated Successfull",
              });

              setTimeout(() => {
                setSuccessPayload({
                  ...successPayload,
                  open: false,
                });
                props.handleSubmitReq();
              }, 3000);
            }
          }
        })
        .catch((err) => {
          seterror(true);
          seterrorMsg({
            title: err === 401 ? "Warning" : "Error",
            content: err === 500 ? "Internal Server Error" : err === 401 ? "Session TimeOut , Please Login Again" : "Something Went Wrong , Please Login Again",
          });
        });
    }
  };
  return (
    <div>
      {successPayload?.open && <ApproveSuccessModal Open={successPayload?.open} title={successPayload?.title} footerTitle={successPayload?.footerTitle} />}
      {error && <Error errorMsg={errorMsg} open={error} setOpen={seterror} />}
      <ModalComponent
        open={props.isUpadteReqModal}
        setOpen={props.setIsUpadteReqModal}
        width="500px"
        header="Analyze User Details"
        modalBody={
          <Grid container>
            <Grid item md={12}>
              <label>
                Buyer Name <span className="required-danger">*</span>
              </label>
              <TextField
                sx={{ width: "100%", my: 1 }}
                id="outlined-multiline-flexible"
                multiline
                value={updateReqData.buyer_name}
                name="buyer_name"
                onChange={handleUpdateReqDataChange}
                size="small"
                error={emptyField.buyer_name && updateReqData.buyer_name === "" ? true : false}
                helperText={emptyField.buyer_name && updateReqData.buyer_name === "" ? "This Value is Required" : ""}
              />
            </Grid>
            <Grid item md={12}>
              <label>
                Buyer Address <span className="required-danger">*</span>
              </label>
              <TextField
                sx={{ width: "100%", my: 1 }}
                id="outlined-multiline-flexible"
                multiline
                value={updateReqData.buyer_address}
                name="buyer_address"
                onChange={handleUpdateReqDataChange}
                size="small"
                error={emptyField.buyer_address && updateReqData.buyer_address === "" ? true : false}
                helperText={emptyField.buyer_address && updateReqData.buyer_address === "" ? "This Value is Required" : ""}
              />
            </Grid>
            <Grid item md={12}>
              <label>Buyer Email</label>
              <TextField
                sx={{ width: "100%", my: 1 }}
                id="outlined-multiline-flexible"
                multiline
                value={updateReqData.buyer_email}
                name="buyer_email"
                onChange={handleUpdateReqDataChange}
                size="small"
              />
            </Grid>
            <Grid item md={12}>
              <label>Buyer Phone Number</label>
              <TextField
                sx={{ width: "100%", my: 1 }}
                id="outlined-multiline-flexible"
                multiline
                value={updateReqData.buyer_phone_number}
                name="buyer_phone_number"
                onChange={handleUpdateReqDataChange}
                size="small"
              />
            </Grid>
            <Grid item md={12}>
              <label>Buyer Concerned Person</label>
              <TextField
                sx={{ width: "100%", my: 1 }}
                id="outlined-multiline-flexible"
                multiline
                value={updateReqData.buyer_concerned_person}
                name="buyer_concerned_person"
                onChange={handleUpdateReqDataChange}
                size="small"
              />
            </Grid>
          </Grid>
        }
        saveText="Submit"
        saveButtonShown={true}
        saveButtonColor="#005d91"
        savedClicked={handleUpdateReqClick}
      />
    </div>
  );
}
