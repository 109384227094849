import moment from "moment";

export const plans = ["MINI", "BOOST", "BUSINESS", "CORPORATE", "SP-CUSTOM"];

export const planTypes = {
  MINI: {
    planType: "MINI",
    payAmount: 50000,
    selectedCountry: [
      "BANGLADESH",
      "VIETNAM",
      "KENYA",
      "MEXICO",
      "GHANA",
      "ZIMBABWE",
      "UKRAINE",
      "BURUNDI",
      "TURKEY",
    ],
    accessValidityStartDate: moment(new Date()).format("YYYY-MM-DD"),
    accessValidityEndDate: moment(new Date())
      .add(1, "year")
      .format("YYYY-MM-DD"),
    dataAvalidityStartDate: moment(new Date())
      .subtract(18, "months")
      .format("YYYY-MM-DD"),
    dataAvalidityEndDate: moment(new Date()).format("YYYY-MM-DD"),
    purchasePoints: 50000,
    workSpaceLimit: 20,
    maxWorkSpaceRecordCount: 20000,
    maxUser: 5,
    searchLimit: 20,
    favShipmentLimit: 5,
    favCompanyLimit: 2,
    summaryLimit: 10,
    reqBuyerLimit: 0,
    saveQueryLimit: 5,
    maxWorkSpaceDeleteCount: 5,
  },
  BOOST: {
    planType: "BOOST",
    payAmount: 75000,
    selectedCountry: [
      "BANGLADESH",
      "VIETNAM",
      "KENYA",
      "MEXICO",
      "GHANA",
      "ZIMBABWE",
      "UKRAINE",
      "BURUNDI",
      "TURKEY",
    ],
    accessValidityStartDate: moment(new Date()).format("YYYY-MM-DD"),
    accessValidityEndDate: moment(new Date())
      .add(1, "year")
      .format("YYYY-MM-DD"),
    dataAvalidityStartDate: moment(new Date())
      .subtract(36, "months")
      .format("YYYY-MM-DD"),
    dataAvalidityEndDate: moment(new Date()).format("YYYY-MM-DD"),
    purchasePoints: 150000,
    workSpaceLimit: 60,
    maxWorkSpaceRecordCount: 40000,
    maxUser: 5,
    searchLimit: 40,
    favShipmentLimit: 10,
    favCompanyLimit: 20,
    summaryLimit: 20,
    reqBuyerLimit: 10,
    saveQueryLimit: 10,
    maxWorkSpaceDeleteCount: 10,
  },
  BUSINESS: {
    planType: "BUSINESS",
    payAmount: 90000,
    selectedCountry: ["UKRAINE", "TURKEY"],
    accessValidityStartDate: moment(new Date()).format("YYYY-MM-DD"),
    accessValidityEndDate: moment(new Date())
      .add(1, "year")
      .format("YYYY-MM-DD"),
    dataAvalidityStartDate: moment(new Date())
      .subtract(48, "months")
      .format("YYYY-MM-DD"),
    dataAvalidityEndDate: moment(new Date()).format("YYYY-MM-DD"),
    purchasePoints: 200000,
    workSpaceLimit: 80,
    maxWorkSpaceRecordCount: 40000,
    maxUser: 10,
    searchLimit: 60,
    favShipmentLimit: 20,
    favCompanyLimit: 10,
    summaryLimit: 40,
    reqBuyerLimit: 20,
    saveQueryLimit: 20,
    maxWorkSpaceDeleteCount: 20,
  },
  CORPORATE: {
    planType: "CORPORATE",
    payAmount: 175000,
    selectedCountry: [],
    accessValidityStartDate: moment(new Date()).format("YYYY-MM-DD"),
    accessValidityEndDate: moment(new Date())
      .add(1, "year")
      .format("YYYY-MM-DD"),
    // dataAvalidityStartDate: moment(new Date())
    //   .subtract(36, "months")
    //   .format("YYYY-MM-DD"),
    dataAvalidityStartDate: "",
    dataAvalidityEndDate: moment(new Date()).format("YYYY-MM-DD"),
    purchasePoints: 400000,
    workSpaceLimit: 200,
    maxWorkSpaceRecordCount: 40000,
    maxUser: 10,
    searchLimit: 100,
    favShipmentLimit: 40,
    favCompanyLimit: 20,
    summaryLimit: 80,
    reqBuyerLimit: 40,
    saveQueryLimit: 40,
    maxWorkSpaceDeleteCount: 40,
  },
  SP_CUSTOM: {
    planType: "SP-CUSTOM",
    payAmount: "",
    selectedCountry: [""],
    accessValidityStartDate: "",
    accessValidityEndDate: "",
    dataAvalidityStartDate: "",
    dataAvalidityEndDate: "",
    purchasePoints: "",
    workSpaceLimit: "",
    maxWorkSpaceRecordCount: "",
    maxUser: "",
    searchLimit: "",
    favShipmentLimit: "",
    favCompanyLimit: "",
    summaryLimit: "",
    reqBuyerLimit: "",
    saveQueryLimit: "",
    maxWorkSpaceDeleteCount: "",
  },
};
