import axios from "axios";
const urlHost = process.env.REACT_APP_BASE_LIVE;

function getCookie(cName) {
  const name = cName + "=";
  const cDecoded = decodeURIComponent(document.cookie); //to be careful
  const cArr = cDecoded.split("; ");
  let res;
  cArr.forEach((val) => {
    if (val.indexOf(name) === 0) res = val.substring(name.length);
  });
  return res;
}

export const axiosPost = async (url, data) => {
  let response = null;
  try {
    const mainUrl = urlHost + url;
    response = await axios.post(mainUrl, data, {
      headers: {
        Cookies: getCookie("tokenUI"),
        React: true,
      },
    });
  } catch (err) {
    throw err.response; 
  }
  return response;
};

export const axiosPostWithContentType = async (url, data) => {
  let response = null;
  try {
    const mainUrl = urlHost + url;
    response = await axios.post(
      mainUrl,
      {
        headers: {
          Cookies: getCookie("tokenUI"),
          React: true,
        },
      },
      data
    );
  } catch (err) {
    throw err.response; 
  }
  return response;
};

export const axiosPut = async (url, data) => {
  let response = null;
  try {
    const mainUrl = urlHost + url;
    response = await axios.put(
      mainUrl,
      data,

      {
        headers: {
          Cookies: getCookie("tokenUI"),
          React: true,
        },
      }
    );
  } catch (err) {
    throw err.response; 
  }
  return response;
};
export const axiosGet = async (url) => {
  let response = null;
  try {
    const mainUrl = urlHost + url;

    response = await axios.get(mainUrl, {
      headers: {
        Cookies: getCookie("tokenUI"),
        React: true,
      },
    });
  } catch (err) {
    throw err.response; 
  }
  return response;
};
export const axiosDelete = async (url) => {
  let response = null;
  try {
    const mainUrl = urlHost + url;
    response = await axios.delete(
      mainUrl,

      {
        headers: {
          Cookies: getCookie("tokenUI"),
          React: true,
        },
      }
    );
  } catch (err) {
    throw err.response; 
  }
  return response;
};
