import React from "react";
import "./authFooter.scss";
const AuthFooter = (props) => {
  return (
    <div id="auth-footer">
      <div style={{ position: props.name !== "reg" ? "absolute" : "" }} className="footer footer-alt">
        <span>
          2020-2021 @ Developed by
          <a href="https://www.seair.co.in/" rel="noopener noreferrer"> Eximpedia</a>
        </span>
      </div>
    </div>
  );
};

export default AuthFooter;
