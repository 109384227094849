import React from "react";
import moment from "moment";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import BusinessIcon from "@mui/icons-material/Business";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import { experimentalStyled as styled } from "@mui/material/styles";

import EditIcon from "@mui/icons-material/Edit";
import { Button, Grid, Container } from "@mui/material";

const SuccesButton = styled(Button)({
  color: "white",
  backgroundColor: "#1abc9c !important",
  borderColor: "#1abc9c !important",
  height: "33px",
  "&:hover": {
    backgroundColor: "#148f77 !important",
    borderColor: "#159a80 !important",
    color: "white",
  },
});

export default function AccountDetails(props) {
  const sanitizeDate = (value) => {
    return new Date(value).toDateString();
  };

  return (
    <Container maxWidth="xxl">
      <div id="account-comp">
        <div style={{ display: "flex" }}>
          <div className="row-dashbored">
            <h4 className="text">
              Manage
              <span className="badge bg-soft-blue text-blue label-worksapce-name">Account</span>
            </h4>
          </div>
          <div style={{ marginTop: "auto", marginBottom: "auto", marginLeft: "auto" }}>
            <SuccesButton startIcon={<EditIcon />} onClick={props.handleEditUserDetails}>
              Edit User Profile
            </SuccesButton>
            <span style={{ marginLeft: "10px" }}>
              <SuccesButton startIcon={<EditIcon />} onClick={props.handleEditCompanyDetails}>
                Edit Company Profile
              </SuccesButton>
            </span>
          </div>
        </div>
        {/* <div id="account-details">

        </div> */}
        <Grid container>
          <Grid item lg={4} md={4} sm={6} xs={12}>
            <div className="account-card">
              <div className="account-card-header">
                <AccountCircleIcon className="set-card-icons" />
                USER PROFILE
              </div>
              <div className="account-card-body">
                {props.userDetails &&
                  !!props.userDetails.length &&
                  props.userDetails.map((user) => {
                    return (
                      <>
                        <div className="account-card-title">
                          {user.first_name} {user.last_name}
                        </div>
                        <span className="text-muted">@{user.role}</span>

                        <div className="account-card-body-content">
                          <div className="mb-2">
                            <span className="account-card-body-content-key"> Email :</span>
                            <span className="account-card-body-content-value">{user?.email_id}</span>
                            <span style={{ color: "#005d91", fontSize: "11px", fontWeight: 900, marginRight: "3px" }}>
                              {user.is_email_verified === 1 ? <span className="verify-email"> Verified</span> : <span className="pending-email"> Pending</span>}
                            </span>
                          </div>
                          <div className="mb-2">
                            <span className="account-card-body-content-key">Mobile :</span>
                            <span className="account-card-body-content-value">{user?.mobile_no}</span>
                          </div>
                          <div className="mb-2">
                            <span className="account-card-body-content-key">Joined :</span>
                            <span className="account-card-body-content-value">{moment(user?.created_ts).format("lll")}</span>
                          </div>
                          <div>
                            <span className="account-card-body-content-key ">Status : </span>
                            {user?.is_active ? (
                              <span className="account-card-body-content-value bg-soft-green set-interval">Activated</span>
                            ) : (
                              <span className="account-card-body-content-value bg-soft-red set-interval">Deactivated</span>
                            )}
                          </div>
                        </div>
                      </>
                    );
                  })}
              </div>
            </div>
          </Grid>
          <Grid item lg={4} md={4} sm={6} xs={12}>
            <div className="account-card">
              <div className="account-card-header">
                <BusinessIcon className="set-card-icons" />
                COMPANY PROFILE
              </div>
              <div className="account-card-body">
                {props.accountDetails &&
                  !!props.accountDetails.length &&
                  props.accountDetails.map((accountDetails) => {
                    return (
                      <>
                        <div className="account-card-title">{accountDetails?.company.name}</div>
                        <span className="text-muted">@{accountDetails?.company?.country}</span>

                        <div className="account-card-body-content">
                          <div className="mb-2">
                            <span className="account-card-body-content-key">Address :</span>
                            <span className="account-card-body-content-value">{accountDetails?.company?.address}</span>
                          </div>
                          <div className="mb-2">
                            <span className="account-card-body-content-key">Pincode :</span>
                            <span className="account-card-body-content-value">{accountDetails?.company?.pin_code}</span>
                          </div>
                          <div className="mb-2">
                            <span className="account-card-body-content-key">Email :</span>
                            <span className="account-card-body-content-value">{accountDetails?.company?.email_id}</span>
                          </div>
                          <div className="mb-2">
                            <span className="account-card-body-content-key">Website :</span>
                            <span className="account-card-body-content-value">{accountDetails?.company?.website_url}</span>
                          </div>
                          <div className="mb-2">
                            <span className="account-card-body-content-key">Phone No :</span>
                            <span className="account-card-body-content-value">{accountDetails?.company?.phone_no}</span>
                          </div>
                          <div>
                            <span className="account-card-body-content-key">Tax Identification No :</span>
                            <span className="account-card-body-content-value">{accountDetails?.company?.tax_identification_no}</span>
                          </div>
                        </div>
                      </>
                    );
                  })}
              </div>
            </div>
          </Grid>
          <Grid item lg={4} md={4} sm={6} xs={12}>
            <div className="account-card">
              <div className="account-card-header">
                <CardGiftcardIcon className="set-card-icons" />
                CURRENT PLAN BENEFITS
              </div>
              <div className="account-card-body">
                {props.accountDetails &&
                  !!props.accountDetails.length &&
                  props.accountDetails.map((accountDetails) => {
                    return (
                      <>
                        <div className="account-card-title">{accountDetails?.plan_constraints?.subscriptionType}</div>
                        <span className="text-muted">@{accountDetails?.plan_constraints?.data_availability_interval?.start_date}</span>

                        <div className="account-card-body-content">
                          <div className="mb-2">
                            <span className="account-card-body-content-key">Max User :</span>
                            <span className="account-card-body-content-value">{accountDetails?.plan_constraints?.max_users}</span>
                          </div>
                          <div className="mb-2">
                            <span className="account-card-body-content-key">Purchase Points :</span>
                            <span className="account-card-body-content-value">{accountDetails?.plan_constraints?.purchase_points}</span>
                          </div>
                          <span className="account-card-body-content-key">Countries Available :</span>
                          <div className="mb-2 p-10">
                            <div>
                              {/* {accountDetails?.plan_constraints?.countries_available?.map((country) => {
                              return <span className="account-card-body-content-value available-countries">{country} |</span>
                            })} */}
                              {props.userAvailableCountry &&
                                !!props.userAvailableCountry.length &&
                                props.userAvailableCountry.map((country) => {
                                  return <span className="available-countries">{`  ${country}  |`}</span>;
                                })}
                            </div>
                          </div>
                          <div className="mb-2">
                            <span className="account-card-body-content-key">Data Range :</span>
                            <span className="account-card-body-content-value bg-soft-green set-interval">
                              {sanitizeDate(accountDetails?.plan_constraints?.data_availability_interval?.start_date) +
                                "-" +
                                sanitizeDate(accountDetails?.plan_constraints?.data_availability_interval?.end_date)}
                            </span>
                          </div>
                          <div className="mb-2">
                            <span className="account-card-body-content-key">Access Validity :</span>
                            <span className="account-card-body-content-value bg-soft-red set-interval">
                              {sanitizeDate(accountDetails?.plan_constraints?.access_validity_interval?.start_date) +
                                "-" +
                                sanitizeDate(accountDetails?.plan_constraints?.access_validity_interval?.end_date)}
                            </span>
                          </div>
                        </div>
                      </>
                    );
                  })}
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
}
