import * as React from "react";
import { Typography, Box, Modal, Backdrop, Fade } from "@mui/material";

import "./loading.scss";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "white",
  boxShadow: 24,
  px: 8,
  py: 3,
  outline: "none",
  borderRadius: "6px",
};
export default function Loading(props) {
  const open = true;
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style} id="succesLoader-box">
            <Typography
              id="transition-modal-title"
              variant="h6"
              component="h2"
              sx={{
                fontSize: "24px",
                textAlign: "center",
                fontFamily: "Rubik",
                color: "#595959",
                fontWeight: 600,
              }}
            >
              {props.title}
              {/* Configuring Environment For Exploration */}
            </Typography>
            <Typography
              id="transition-modal-description"
              sx={{
                mt: 1,
                fontSize: "16px",
                fontweight: 300,
                wordwrap: "break-word",
                textAlign: "center",
                opacity: 0.6,
                fontFamily: "Rubik",
              }}
            >
              {props.content}
            </Typography>
            {props.loader === "hide" ? (
              ""
            ) : (
              <Box sx={{ textAlign: "center", mt: 3 }}>
                <span className="loading-spinner"></span>
              </Box>
            )}
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
