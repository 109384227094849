import {
  axiosGetWithoutAuth,
  axiosPostWithoutAuth,
  axiosPutWithoutAuth,
  axiosDeleteWithoutAuth,
  axiosGetWithoutAuthWithoutHost,
} from "../Helpers/axiosWithoutAuth";
import {
  axiosGet,
  axiosPost,
  axiosPostWithContentType,
  axiosPut,
  axiosDelete,
} from "../Helpers/axiosWithAuth";

export const getLoginDetails = async (data) => {
  let response = null;
  try {
    const output = await axiosPutWithoutAuth("/auths/login", data);
    response = output.data;
  } catch (err) {
    response = err;
  }
  return response;
};

export const logoutDetails = async (data) => {
  let response = null;
  try {
    const output = await axiosPut(`/auths/users/${data.user_id}/logout`, data);
    response = output.data;
  } catch (err) {
    response = err;
  }
  return response;
};

export const getDashboredData = async () => {
  let response = null;
  try {
    const output = await axiosGet("/dashboard/providers");
    response = output.data;
  } catch (err) {
    response = err;
  }
  return response;
};

export const getTaxonomyGlobal = async () => {
  let response = null;
  try {
    const output = await axiosGet(`/taxonomies?taxonomyScope=GLOBAL`);
    response = output.data;
  } catch (err) {
    response = err;
  }
  return response;
};

export const getTaxonomy = async () => {
  let response = null;
  try {
    const output = await axiosGet(`/taxonomies`);
    response = output.data;
  } catch (err) {
    response = err;
  }
  return response;
};

export const verifyUploadedFiles = async (filesParams) => {
  let response = null;
  try {
    const output = await axiosGet(`/ledger/files/verify?files=${filesParams}`);
    response = output.data;
  } catch (err) {
    response = err;
  }
  return response;
};

export const addLedgerFiles = async (data) => {
  let response = null;
  try {
    const output = await axiosPost("/ledger/files", data);
    response = output.data;
  } catch (err) {
    response = err;
  }
  return response;
};

export const updateLedgerFile = async (fileId, data) => {
  let response = null;
  try {
    const output = await axiosPut(`/ledger/files/${fileId}/stage`, data);
    response = output.data;
  } catch (err) {
    response = err;
  }
  return response;
};

export const fetchLedgerFilesStats = async (tradeType) => {
  const currentTrade = tradeType === "all" ? "" : tradeType?.toUpperCase();
  let response = null;
  try {
    const output = await axiosGet(
      `/ledger/files/stats?tradeType=${currentTrade}`
    );
    response = output.data;
  } catch (err) {
    response = err;
  }
  return response;
};

export const fetchSubscriptionCustomers = async (data) => {
  let response = null;
  try {
    const output = await axiosPost("/accounts/fetchCustomers", data);
    response = output.data;
  } catch (err) {
    response = err;
  }
  return response;
};

export const fetchWebsiteCustomers = async (data) => {
  let response = null;
  try {
    const output = await axiosPost("/accounts/fetchWebsiteCustomers", data);
    response = output.data;
  } catch (err) {
    response = err;
  }
  return response;
};

export const getCustomerByEmail = async (emailId) => {
  let response = null;
  try {
    const output = await axiosGet(`/accounts/fetchCustomer/email/${emailId}`);
    response = output.data;
  } catch (err) {
    response = err;
  }
  return response;
};

export const getCustometPlanDetails = async (accountId) => {
  let response = null;
  try {
    const output = await axiosGet(`/accounts/customer/plan/${accountId}`);
    response = output.data;
  } catch (err) {
    response = err;
  }
  return response;
};

export const customerRegistrations = async (data) => {
  let response = null;
  try {
    const output = await axiosPost("/accounts/registrations", data);
    response = output.data;
  } catch (err) {
    response = err;
  }
  return response;
};

export const addCustomerSubscription = async (data) => {
  let response = null;
  try {
    const output = await axiosPost("/subscriptions", data);
    response = output.data;
  } catch (err) {
    response = err;
  }
  return response;
};

export const updateCustomerConstraints = async (data) => {
  let response = null;
  try {
    const output = await axiosPut("/accounts/customer/updateConstraints", data);
    response = output.data;
  } catch (err) {
    response = err;
  }
  return response;
};

export const deleteCustomerRecord = async (accountId) => {
  let response = null;
  try {
    const output = await axiosDelete(`/accounts/${accountId}`);
    response = output.data;
  } catch (err) {
    response = err;
  }
  return response;
};

export const getCustomerAccountInfo = async (accountId) => {
  let response = null;
  try {
    const output = await axiosGet(`/accounts/customer/info/${accountId}`);
    response = output.data;
  } catch (err) {
    response = err;
  }
  return response;
};

export const getUserActivityList = async (data) => {
  let response = null;
  try {
    const output = await axiosPost("/activity/account/list", data);
    response = output.data;
  } catch (err) {
    response = err;
  }
  return response;
};

export const getAccountActivityList = async (data) => {
  let response = null;
  try {
    const output = await axiosGet(
      `/activity/user/list/${data.accountId}/${data.dateFrom}/${data.dateTo}`
    );
    response = output.data;
  } catch (err) {
    response = err;
  }
  return response;
};

// subuser activity
export const getAnalyzeUserActivity = async (data) => {
  let response = null;
  try {
    const output = await axiosGet(
      `/activity/user/${data.id}/${data.startDate}/${data.endDate}`
    );
    response = output.data;
  } catch (err) {
    response = err;
  }
  return response;
};

export const getAnalyzeAccountActivity = async (data) => {
  let response = null;
  try {
    const output = await axiosGet(
      `/activity/account/${data.id}/${data.startDate}/${data.endDate}`
    );
    response = output.data;
  } catch (err) {
    response = err;
  }
  return response;
};
export const getUserEmailActivityList = async (emailId) => {
  let response = null;
  try {
    const output = await axiosGet(`/activity/user/email/${emailId}`);
    response = output.data;
  } catch (err) {
    response = err;
  }
  return response;
};

export const getConsigneePendingRequestList = async (data) => {
  let response = null;
  try {
    const output = await axiosPost(`/consignee/request/list`, data);
    response = output.data;
  } catch (err) {
    response = err;
  }
  return response;
};

export const getConsigneeProcessedRequestList = async (data) => {
  let response = null;
  try {
    const output = await axiosPost(`/consignee/request/processed/list`, data);
    response = output.data;
  } catch (err) {
    response = err;
  }
  return response;
};

export const updateConsigneeRequest = async (data) => {
  let response = null;
  try {
    const output = await axiosPost("/consignee/request/update", data);
    response = output.data;
  } catch (err) {
    response = err;
  }
  return response;
};

export const deleteConsigneeRequest = async (data) => {
  let response = null;
  try {
    const output = await axiosPost("/consignee/delete/shipment/request", data);
    response = output.data;
  } catch (err) {
    response = err;
  }
  return response;
};

export const getAllAccountsList = async (accountId) => {
  let response = null;
  try {
    const output = await axiosGet(`/accounts`);
    response = output.data;
  } catch (err) {
    response = err;
  }
  return response;
};

export const getAllUsersList = async (accountId) => {
  let response = null;
  try {
    const output = await axiosGet(`/users`);
    response = output.data;
  } catch (err) {
    response = err;
  }
  return response;
};

export const addNotifications = async (data) => {
  let response = null;
  try {
    const output = await axiosPost("/notification", data);
    response = output.data;
  } catch (err) {
    response = err;
  }
  return response;
};

export const getAccountDetails = async (accountId) => {
  try {
    let response = await axiosGet(`/accounts/${accountId}`);
    return response.data;
  } catch (err) {
    return err;
  }
};

export const getUsersDetails = async (userId) => {
  try {
    let response = await axiosGet(`/users/${userId}`);
    return response.data;
  } catch (err) {
    return err;
  }
};

export const updateUserDetails = async (userId, data) => {
  try {
    let response = await axiosPut(`/users/${userId}`, data);
    return response.data;
  } catch (err) {
    return err;
  }
};

export const updateAccountDetails = async (accountId, data) => {
  try {
    let response = await axiosPut(`/accounts/${accountId}`, data);
    return response.data;
  } catch (err) {
    return err;
  }
};

export const fetchCustomerEmailSuggestion = async (userEmail) => {
  try {
    let response = await axiosGet(
      `/accounts/fetchCustomerEmailSuggestion/email/${userEmail}`
    );
    return response;
  } catch (err) {
    return err;
  }
};

export const fetchActivityEmailSuggestion = async (userEmail) => {
  try {
    let response = await axiosGet(
      `/activity/fetchUserEmailSuggestion/email/${userEmail}`
    );
    return response;
  } catch (err) {
    return err;
  }
};

export const getActivityUserEmail = async (data) => {
  let response = null;
  try {
    const output = await axiosPost(`/activity/user/email/find`, data);
    response = output;
  } catch (err) {
    response = err;
  }
  return response;
};

export const getBlobToken = async () => {
  try {
    const blobToken = await axiosGet(`/auths/blob/token`);
    return blobToken;
  } catch (err) {
    return err;
  }
};
export const getVideoFeedback = async (dateObj) => {
  try {
    const blobToken = await axiosPost(`/video/feedback/all`, dateObj);
    return blobToken;
  } catch (err) {
    return err;
  }
};

export const sendShipmentData = async (data) => {
  let response = null;
  try {
    const output = await axiosPost(
      `/backend/consignee/request/update/all`,
      data
    );
    response = output;
  } catch (err) {
    response = err;
  }
  return response;
};
