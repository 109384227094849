import React, { useEffect, useState } from "react";
import _ from "lodash";
import moment from "moment";
import {
  fetchActivityEmailSuggestion,
  getAccountActivityList,
  getAccountDetails,
  getActivityUserEmail,
  getAnalyzeAccountActivity,
  getAnalyzeUserActivity,
  getUserActivityList,
} from "../../Services/service";
import CollapsibleTable from "../../Component/CollapseTable/collapseTable";
import ModalComponent from "../../Component/Modal/modal";
import DataTable from "../../Component/DataTable/table";
import { experimentalStyled as styled } from "@mui/material/styles";
import { Button, Container, Box, Grid } from "@mui/material";
import Cookies from "js-cookie";
import Loading from "../../Component/Loader/loading";
import Error from "../../Component/ErrorLoader/error";
import Spinner from "../../Component/Loader/spinner";
import SearchComponent from "../../Component/SearchComponent/SearchComponent";

const FilterButton = styled(Button)({
  color: "#fff",
  backgroundColor: "#005D91",
  borderColor: " #005D91",
  // textTransform:"none",
  "&:hover": {
    backgroundColor: "#07527D !important",
    borderColor: "#07527D !important",
  },
});

export default function ActivityTracker() {
  const [removeData, setRemoveData] = useState(false);
  const [inputValues, setInputValues] = useState("");
  const [reactSelectLoading, setReactSelectLoading] = useState(false);
  const [getSearchOption, setSearchOptionForMain] = useState([]);
  const [maxDateForEndMonth, setMaxDateForEndMonth] = useState(
    moment(Date.now()).format("YYYY-MM-DD")
  );
  const [changeStartMonthDate, setStartMonthDate] = useState(
    moment(Date.now()).subtract(1, "days").format("YYYY-MM-DD")
  );
  const [changeEndMonthDate, setEndMonthDate] = useState(
    moment(Date.now()).format("YYYY-MM-DD")
  );
  const [loading, setloading] = useState(false);
  const [spinner, setspinner] = useState(false);
  const [loadingMsg, setloadingMsg] = useState({});
  const [error, seterror] = useState(false);
  const [errorMsg, seterrorMsg] = useState({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [userActivityList, setUserActivityList] = useState([]);
  const [totalUserListCount, setTotalUserListCount] = useState(0);
  const [childData, setChildData] = useState({});
  const [analzeModalData, setAnalzeModalData] = useState([]);
  const [paramsForDownload, setParamsForDownload] = useState({
    paramsKey: "",
    paramsValue: "",
  });
  const [IsanalyzeModal, setIsAnalyzeModal] = useState(false);
  const [searchEmail, setSearchEmail] = useState({
    emailId: "",
    isEmpty: false,
    errorText: "",
  });
  const analyzeModalTableHeader = [
    { key: "country", title: "country" },
    { key: "tradeType", title: "TradeType" },
    { key: "role", title: "Role" },
    { key: "activity", title: "Activity" },
    { key: "queryCreatedAt", title: "QueryCreatedAt" },
    { key: "queryResponseTime", title: "QueryResponseTime" },
    { key: "query", title: "Query" },
    { key: "workspaceCreationQuery", title: "Workspace Creation Query" },
    { key: "points", title: "points" },
  ];
  const userListTableHeader = [
    { key: "add", title: "" },
    { key: "No", title: "S.No" },

    { key: "Name", title: "Name" },
    { key: "email_id", title: "Email" },
    { key: "role", title: "Role" },
    { key: "activity_count", title: "Activity Count" },
    { key: "analyze", title: "Analyze" },
  ];

  const userListChildTableHeader = [
    { key: "No", title: "S.No" },

    { key: "Name", title: "Name" },
    { key: "email_id", title: "Email" },
    { key: "role", title: "Role" },
    { key: "activity_count", title: "Activity Count" },
    { key: "analyze", title: "Analyze" },
  ];

  const handleInputChangeForSearchTerm = _.debounce((value) => {
    if (value !== "") {
      setReactSelectLoading(true);
      loadOptions(value, "search", "");
      setInputValues(value);
    }
  }, 500);

  const loadOptions = async (inputValue) => {
    const dataSearchterm = [];
    if (inputValue !== "") {
      fetchActivityEmailSuggestion(inputValue)
        .then((res) => {
          setReactSelectLoading(false);
          if (res?.data) {
            if (res?.data?.data?.length && res?.data?.data?.length > 0) {
              res?.data?.data?.forEach((value) => {
                dataSearchterm.unshift({
                  _id: value,
                });
              });
              setSearchOptionForMain(dataSearchterm);
            }
          }
        })
        .catch(() => {});
    }
  };
  const handleSearchForTable = (value) => {
    if (value === null) {
      setInputValues(null);
      setSearchEmail({
        ...searchEmail,
        errorText: "This Value is Required",
        isEmpty: true,
      });
    } else {
      setInputValues(value);
      setSearchEmail({
        ...searchEmail,
        errorText: "",
        isEmpty: false,
      });
    }
  };

  useEffect(() => {
    const userActivityPayload = {
      limit: rowsPerPage,
      offset: page,
      dateTo: changeEndMonthDate,
      dateFrom: changeStartMonthDate,
    };
    getUserActivityListAPIHandler(userActivityPayload);
  }, []);

  const getUserActivityListAPIHandler = (userActivityPayload) => {
    setloading(true);
    setloadingMsg({
      title: "Fetching User Activites",
      content: "Please Wait...",
    });
    getUserActivityList(userActivityPayload)
      .then((e) => {
        setloading(false);
        if (e?.status === 500 || e?.status === 401) {
          throw e?.status;
        } else {
          if (e?.data) {
            setUserActivityList(e?.data);
            setTotalUserListCount(e?.totalAccountCount);
          } else {
            throw new Error();
          }
        }
      })
      .catch((err) => {
        setloading(false);
        seterror(true);
        seterrorMsg({
          title: err === 401 ? "Warning" : "Error",
          content:
            err === 500
              ? "Internal Server Error"
              : err === 401
              ? "Session TimeOut , Please Login Again"
              : "Something Went Wrong , Please Login Again",
        });
      });
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage - 1);
    const userActivityPayload = {
      limit: rowsPerPage,
      offset: (newPage - 1) * rowsPerPage,
      dateTo: changeEndMonthDate,
      dateFrom: changeStartMonthDate,
    };
    getUserActivityListAPIHandler(userActivityPayload);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
    const userActivityPayload = {
      limit: event.target.value,
      offset: 0,
      dateTo: changeEndMonthDate,
      dateFrom: changeStartMonthDate,
    };
    getUserActivityListAPIHandler(userActivityPayload);
  };

  const handleOpenCollapseTable = (rowData, open) => {
    if (open) {
      setspinner(true);

      setloadingMsg({
        title: "Fetching Child User",
        content: "Please Wait...",
      });

      const payload = {
        accountId: rowData?.userData[0]?.account_id,
        dateTo: changeEndMonthDate,
        dateFrom: changeStartMonthDate,
      };

      getAccountActivityList(payload)
        .then((e) => {
          setspinner(false);

          if (e?.status === 500 || e?.status === 401) {
            throw e?.status;
          } else {
            if (e?.data) {
              let obj = { ...childData };
              obj = { ...obj, [rowData?.email_id]: e.data };
              setChildData(obj);
            } else {
              throw new Error();
            }
          }
        })
        .catch((err) => {
          setspinner(false);
          seterror(true);
          seterrorMsg({
            title: err === 401 ? "Warning" : "Error",
            content:
              err === 500
                ? "Internal Server Error"
                : err === 401
                ? "Session TimeOut , Please Login Again"
                : "Something Went Wrong , Please Login Again",
          });
        });
    } else {
      let obj = { ...childData };
      delete obj[rowData?.email_id];
      setChildData(obj);
    }
  };

  const handleAnalyseRecord = (caller, rowData) => {
    setIsAnalyzeModal(true);
    setspinner(true);
    let userId = "";
    if (caller === "parent") {
      userId = rowData?.userData[0]?.account_id;
    } else {
      userId = rowData?.user_id;
    }

    const obj = {
      id: userId,
      startDate: changeStartMonthDate,
      endDate: changeEndMonthDate,
    };
    setParamsForDownload({
      paramsKey: "userId",
      paramsValue: userId,
    });

    if (caller === "parent") {
      getAnalyzeAccountActivity(obj)
        .then((e) => {
          setspinner(false);
          if (e?.status === 500 || e?.status === 401) {
            throw e?.status;
          } else {
            if (e?.data) {
              e?.data &&
                e?.data.length &&
                e?.data?.forEach((ele) => {
                  if (ele.hasOwnProperty("workspaceCreationQuery")) {
                    ele.workspaceCreationQuery = ele?.workspaceCreationQuery
                      ?.toString()
                      ?.toUpperCase();
                  }
                  if (ele.hasOwnProperty("queryCreatedAt")) {
                    ele.queryCreatedAt = moment(ele?.queryCreatedAt).format(
                      "lll"
                    );
                  }
                });
              setAnalzeModalData(e?.data);
            } else {
              throw new Error();
            }
          }
        })
        .catch((err) => {
          setloading(false);
          seterror(true);
          seterrorMsg({
            title: err === 401 ? "Warning" : "Error",
            content:
              err === 500
                ? "Internal Server Error"
                : err === 401
                ? "Session TimeOut , Please Login Again"
                : "Something Went Wrong , Please Login Again",
          });
        });
    } else {
      getAnalyzeUserActivity(obj)
        .then((e) => {
          setspinner(false);
          if (e?.status === 500 || e?.status === 401) {
            throw e?.status;
          } else {
            if (e?.data) {
              e?.data &&
                e?.data.length &&
                e?.data?.forEach((ele) => {
                  if (ele.hasOwnProperty("workspaceCreationQuery")) {
                    ele.workspaceCreationQuery = ele?.workspaceCreationQuery
                      ?.toString()
                      ?.toUpperCase();
                  }
                  if (ele.hasOwnProperty("queryCreatedAt")) {
                    ele.queryCreatedAt = moment(ele?.queryCreatedAt).format(
                      "lll"
                    );
                  }
                });
              setAnalzeModalData(e?.data);
            } else {
              throw new Error();
            }
          }
        })
        .catch((err) => {
          setloading(false);
          seterror(true);
          seterrorMsg({
            title: err === 401 ? "Warning" : "Error",
            content:
              err === 500
                ? "Internal Server Error"
                : err === 401
                ? "Session TimeOut , Please Login Again"
                : "Something Went Wrong , Please Login Again",
          });
        });
    }
  };

  const handleDownloadUserActivity = () => {
    let paramsObj = {
      [paramsForDownload.paramsKey]: paramsForDownload.paramsValue,
      dateFrom: changeStartMonthDate,
      dataTo: changeEndMonthDate,
    };
    let xhttp = new XMLHttpRequest();
    xhttp.onreadystatechange = function () {
      var a;
      if (xhttp.readyState === 4 && xhttp.status === 200) {
        // Trick for making downloadable link
        a = document.createElement("a");
        a.href = window.URL.createObjectURL(xhttp.response);
        // Give filename you wish to download
        a.download = "Activity.xlsx";
        a.style.display = "none";
        document.body.appendChild(a);
        a.click();
      } else if (xhttp.status === 500 || xhttp.status === 400) {
        setloading(false);
        seterror(true);
        seterrorMsg({
          title: xhttp.status === 401 ? "Warning" : "Error",
          content:
            xhttp.status === 500
              ? "Internal Server Error"
              : xhttp.status === 401
              ? "Session TimeOut , Please Login Again"
              : "Something Went Wrong , Please Login Again",
        });
      }
    };
    // Post data to URL which handles post request
    xhttp.open("POST", "https://web.eximpedia.app:8000/activity/user/download");

    // xhttp.withCredentials = true;
    xhttp.setRequestHeader("Content-Type", "application/json");
    xhttp.setRequestHeader("React", true);
    xhttp.setRequestHeader("Cookies", Cookies.get("tokenUI"));
    // You should set responseType as blob for binary responses
    xhttp.responseType = "blob";
    xhttp.send(JSON.stringify(paramsObj));
  };

  const handleEmailSearch = (event) => {
    let _temp = { ...searchEmail };
    _temp = {
      ...searchEmail,
      [event.target.name]: event.target.value,
    };
    setSearchEmail(_temp);
  };

  const validateFields = () => {
    if (inputValues === undefined || inputValues === "") {
      setSearchEmail({
        ...searchEmail,
        errorText: "This Value is Required",
        isEmpty: true,
      });

      return false;
    } else {
      setSearchEmail({
        ...searchEmail,
        errorText: "",
        isEmpty: false,
      });
      return true;
    }
  };

  const handleRefreshPage = () => {
    setRowsPerPage(10);
    setPage(0);
    setInputValues("");
    setSearchOptionForMain([]);
    setSearchEmail({
      ...searchEmail,
      errorText: "",
      isEmpty: false,
    });
    setStartMonthDate(
      moment(Date.now()).subtract(1, "days").format("YYYY-MM-DD")
    );
    setEndMonthDate(moment(Date.now()).format("YYYY-MM-DD"));
    setRemoveData(false);
    const userActivityPayload = {
      limit: 10,
      offset: 0,
      dateTo: changeEndMonthDate,
      dateFrom: changeStartMonthDate,
    };
    getUserActivityListAPIHandler(userActivityPayload);
  };
  const handleSearchEmailBtnClick = () => {
    const emailObj = {
      email_id: inputValues?._id,
      dateFrom: changeStartMonthDate,
      dateTo: changeEndMonthDate,
    };
    if (validateFields() === false) {
      return;
    }
    setspinner(true);
    getActivityUserEmail(emailObj)
      .then((e) => {
        setspinner(false);
        if (e?.status === 500 || e?.status === 401) {
          throw e?.status;
        } else {
          if (e?.data) {
            setRemoveData(true);
            setUserActivityList([e?.data]);
          } else if (e?.message) {
            setloading(false);
            seterror(true);
            seterrorMsg({
              title: "Error",
              content: e?.message,
            });
          } else {
            throw new Error();
          }
        }
      })
      .catch((err) => {
        setloading(false);
        seterror(true);
        seterrorMsg({
          title: err === 401 ? "Warning" : "Error",
          content:
            err === 500
              ? "Internal Server Error"
              : err === 401
              ? "Session TimeOut , Please Login Again"
              : "Something Went Wrong , Please Login Again",
        });
      });
  };

  return (
    <Container maxWidth="xxl">
      {loading && (
        <Loading title={loadingMsg.title} content={loadingMsg.content} />
      )}
      {spinner && <Spinner />}
      {error && <Error errorMsg={errorMsg} open={error} setOpen={seterror} />}
      <Grid container className="row-dashbored">
        <Grid item lg={2} md={2} sm={3} xs={3}>
          <h3 className="text">
            Activity
            <span className="badge bg-soft-blue text-blue label-worksapce-name">
              Tracker
            </span>
          </h3>
        </Grid>

        <Grid item lg={10} md={10} sm={6} xs={12} style={{ marginTop: "20px" }}>
          <SearchComponent
            handleRefreshPage={handleRefreshPage}
            reactSelectLoading={reactSelectLoading}
            getSearchOption={getSearchOption}
            inputValues={inputValues}
            handleInputChangeForSearchTerm={handleInputChangeForSearchTerm}
            handleEmailSearch={handleEmailSearch}
            handleSearchForTable={handleSearchForTable}
            searchEmail={searchEmail}
            handleSearchEmailBtnClick={handleSearchEmailBtnClick}
            changeEndMonthDate={changeEndMonthDate}
            changeStartMonthDate={changeStartMonthDate}
            setEndMonthDate={setEndMonthDate}
            setStartMonthDate={setStartMonthDate}
            maxDateForEndMonth={maxDateForEndMonth}
          />
        </Grid>
      </Grid>
      <CollapsibleTable
        removeData={removeData}
        rows={userActivityList}
        columns={userListTableHeader}
        userListChildTableHeader={userListChildTableHeader}
        activityTracker={true}
        childData={childData}
        handleOpenCollapseTable={handleOpenCollapseTable}
        handleAnalyseRecord={handleAnalyseRecord}
        totalAvailableData={totalUserListCount}
        rowsPerPage={rowsPerPage}
        page={page}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        showPagination={true}
      />

      <ModalComponent
        open={IsanalyzeModal}
        setOpen={setIsAnalyzeModal}
        width="58vw"
        header="Analyze User Details"
        modalBody={
          <Box>
            {!!analzeModalData.length && (
              <FilterButton
                variant="contained"
                style={{ margin: "10px", float: "right" }}
                onClick={handleDownloadUserActivity}
              >
                Download
              </FilterButton>
            )}
            <DataTable
              columns={analyzeModalTableHeader}
              rows={analzeModalData}
              isActivityTracker={true}
              whiteSpaceNoWrap={true}
            />
          </Box>
        }
        saveButtonShown={false}
      />
    </Container>
  );
}
