import { Fade, Modal, Backdrop } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import "./loading.scss";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  boxShadow: 24,
  outline: "none",
  borderRadius: "6px",
};
export default function Spinner() {
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={true}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      style={{ zIndex: 10000 }}
    >
      <Fade in={true}>
        <Box sx={style} id="succesLoader-box">
          <Box sx={{ textAlign: "center", mt: 3 }}>
            <span className="loader"></span>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
}
