import React from "react";
import { Modal, Box, Typography } from "@mui/material";
export default function ApproveSuccessModal(props) {
  return (
    <div>
      <Modal open={props.Open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            background: "white",
            boxShadow: 24,
            p: 4,
            borderRadius: "5px",
          }}
        >
          <Typography variant="h6" component="h2" style={{ height: "135px" }}>
            <Typography>
              <span style={{ display: "flex", justifyContent: "center", color: "#505458", fontSize: "1.2rem", paddingTop: "12px" }}>
                {/* {selectedValueRadioButton === "existing" ? "Successfully Modified Workspace!" : "Successfully Created Workspace!"} */}
                {props.title}
              </span>
            </Typography>
            <div className="logout-checkmark">
              <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                <circle className="path circle" fill="none" stroke="#4bd396" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1" />
                <polyline
                  className="path check"
                  fill="none"
                  stroke="#4bd396"
                  stroke-width="6"
                  stroke-linecap="round"
                  stroke-miterlimit="10"
                  points="100.2,40.2 51.5,88.8 29.8,67.5 "
                />
              </svg>
            </div>
          </Typography>
          <Typography sx={{ mt: 2, fontSize: "2rem", color: "#505458" }}>
            <span style={{ display: "flex", justifyContent: "center", padding: "23px 11px" }}>{props.footerTitle}</span>
          </Typography>
        </Box>
      </Modal>
    </div>
  );
}
