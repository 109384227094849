import React from "react";
import { Navigate } from "react-router-dom";
import Cookies from "js-cookie";
import Navbar from "../Navbar/navbar";
export default function ProtectedForPage(props) {
  function getCookie(cName) {
    const name = cName + "=";
    const cDecoded = decodeURIComponent(document.cookie); //to be careful
    const cArr = cDecoded.split("; ");
    let res;
    cArr.forEach((val) => {
      if (val.indexOf(name) === 0) res = val.substring(name.length);
    });
    return res;
  }

  const CLIENT_USER_ID = Cookies.get("user_id");
  const CLIENT_ACCOUNT_ID = Cookies.get("accountId");
  const CLIENT_FIRSTNAME = Cookies.get("firstName");
  const CLIENT_LASTNAME = Cookies.get("lastName");
  const CLIENT_EMAIL_ID = Cookies.get("email_id");
  const Cmp = props.cmp;

  
  const token = getCookie("tokenUI");
  // const token = Cookie.token;
  return (
    <div>
      {token !== undefined ? (
        <>
          {props.route === "*" ? (
            <Cmp
              token={token}
              CLIENT_USER_ID={CLIENT_USER_ID}
              CLIENT_ACCOUNT_ID={CLIENT_ACCOUNT_ID}
              CLIENT_FIRSTNAME={CLIENT_FIRSTNAME}
              CLIENT_LASTNAME={CLIENT_LASTNAME}
              CLIENT_EMAIL_ID={CLIENT_EMAIL_ID}
            />
          ) : props.route === "/providers/logout" ? (
            <Cmp
              token={token}
              CLIENT_USER_ID={CLIENT_USER_ID}
              CLIENT_ACCOUNT_ID={CLIENT_ACCOUNT_ID}
              CLIENT_FIRSTNAME={CLIENT_FIRSTNAME}
              CLIENT_LASTNAME={CLIENT_LASTNAME}
              CLIENT_EMAIL_ID={CLIENT_EMAIL_ID}
            />
          ) : (
            <Navbar>
              <Cmp
                token={token}
                CLIENT_USER_ID={CLIENT_USER_ID}
                CLIENT_ACCOUNT_ID={CLIENT_ACCOUNT_ID}
                CLIENT_FIRSTNAME={CLIENT_FIRSTNAME}
                CLIENT_LASTNAME={CLIENT_LASTNAME}
                CLIENT_EMAIL_ID={CLIENT_EMAIL_ID}
              />
            </Navbar>
          )}
        </>
      ) : (
        <Navigate to="/providers/login"></Navigate>
      )}
    </div>
  );
}
