import React, { useEffect, useState } from "react";
import Loading from "../../Component/Loader/loading";
import { getAccountDetails, getUsersDetails, updateUserDetails, updateAccountDetails, getTaxonomy } from "../../Services/service";
import ApprovedSuccess from "../../Component/ApprovSuccessModal/approvSuccessModal";
import ModalComponent from "../../Component/Modal/modal";
import "./account.scss";
import AccountDetails from "./Componant/accountDetails";
import EditUserDetails from "./Componant/editUserDetails";
import EditCompanyDetails from "./Componant/editCompanyDetails";

import Error from "../../Component/ErrorLoader/error";
export default function AccountProfile(props) {
  const [loading, setloading] = useState(false);
  const [loadingMsg, setloadingMsg] = useState({});
  const [error, seterror] = useState(false);
  const [errorMsg, seterrorMsg] = useState({});
  const [taxomonyDetails, setTaxomonyDetails] = useState([]);
  const [accountDetails, setAccountDetails] = useState([]);
  const [userAvailableCountry, seUserAvailableCountry] = useState([]);
  const [userDetails, setUserDetails] = useState([]);
  const [editUserDetails, setEditUserDetails] = useState({});
  const [editCompanyDetails, setEditCompanyDetails] = useState({});
  const [isUserEditModal, setIsUserEditModal] = useState(false);
  const [isCompanyEditModal, setIsCompanyEditModal] = useState(false);
  const [isEditUserNameEmpty, setIsEditUserNameEmpty] = useState({
    first_name: false,
    last_name: false,
  });
  const [isEditCompanyNameEmpty, setIsEditCompanyNameEmpty] = useState({
    name: false,
    address: false,
    pin_code: false,
    country: false,
  });
  const [approvSuccessModal, setApprovSuccessModal] = useState(false);
  const [approvSuccessModalData, setApprovSuccessModalData] = useState({});

  useEffect(() => {
    setloading(true);
    setloadingMsg({
      title: "Retrieving Profile",
      content: "Preparing Information",
    });
    getTaxonomyDetails();
    fetchAccountDetails(props.CLIENT_ACCOUNT_ID);
    fetchUserDetails(props.CLIENT_USER_ID);
  }, []);

  useEffect(() => {
    const _userAvailableCountry = [...userAvailableCountry];
    if (taxomonyDetails && taxomonyDetails.length && accountDetails && accountDetails.length) {
      accountDetails[0]?.plan_constraints?.countries_available &&
        accountDetails[0].plan_constraints?.countries_available?.length &&
        accountDetails[0].plan_constraints?.countries_available?.forEach((e) => {
          taxomonyDetails.forEach((taxonomy) => {
            if (taxonomy?.code_iso_3 === e) {
              if (!_userAvailableCountry.includes(taxonomy?.country?.toUpperCase()?.split("_")?.join(" "))) {
                _userAvailableCountry.push(taxonomy?.country?.toUpperCase()?.split("_")?.join(" "));
              }
            }
          });
        });
      seUserAvailableCountry(_userAvailableCountry);
    }
  }, [taxomonyDetails, accountDetails]);

  const getTaxonomyDetails = () => {
    getTaxonomy()
      .then((e) => {
        if (e?.status === 500 || e?.status === 401) {
          throw e?.status;
        } else {
          if (e?.data) {
            setTaxomonyDetails(e.data);
          } else {
            throw new Error();
          }
        }
        setloading(false);
      })
      .catch((err) => {
        setloading(false);
        seterror(true);
        seterrorMsg({
          title: "Error",
          content: err === 500 ? "Internal Server Error" : "Something Went Wrong , Please Login Again",
        });
      });
  };
  const fetchAccountDetails = (accountId) => {
    getAccountDetails(accountId)
      .then((successPayload) => {
        if (successPayload?.status === 500 || successPayload?.status === 401) {
          throw successPayload?.status;
        } else {
          // setloading(false);
          let _accountDetails = [];
          if (successPayload?.data) {
            _accountDetails.push(successPayload?.data);
            setAccountDetails(_accountDetails);
            setEditCompanyDetails(successPayload?.data?.company);
          } else {
            throw new Error();
          }
        }
      })
      .catch((err) => {
        setloading(false);
        seterror(true);
        seterrorMsg({
          title: err === 401 ? "Warning" : "Error",
          content: err === 500 ? "Internal Server Error" : err === 401 ? "Session TimeOut , Please Login Again" : "Something Went Wrong , Please Login Again",
        });
      });
  };

  const fetchUserDetails = (userId) => {
    getUsersDetails(userId)
      .then((user) => {
        if (user?.status === 500 || user?.status === 401) {
          throw user?.status;
        } else {
          if (user?.data) {
            let _userDetails = [];
            _userDetails.push(user?.data);
            setUserDetails(_userDetails);
            setEditUserDetails({
              first_name: user?.data?.first_name,
              last_name: user?.data?.last_name,
              mobile_no: user?.data?.mobile_no,
            });
          } else {
            throw new Error();
          }
        }
      })
      .catch((err) => {
        setloading(false);
        seterror(true);
        seterrorMsg({
          title: err === 401 ? "Warning" : "Error",
          content: err === 500 ? "Internal Server Error" : err === 401 ? "Session TimeOut , Please Login Again" : "Something Went Wrong , Please Login Again",
        });
      });
  };
  const handleChangeEditUserDetails = (e) => {
    setEditUserDetails({
      ...editUserDetails,
      [e.target.name]: e.target.value,
    });
    if (isEditUserNameEmpty) {
      if (e.target.value.length > 0) {
        setIsEditUserNameEmpty({
          ...isEditUserNameEmpty,
          [e.target.name]: false,
        });
      }
    }
  };
  const handleEditUserDetails = () => {
    setIsUserEditModal(true);
  };

  const handleEditCompanyDetails = () => {
    setIsCompanyEditModal(true);
  };

  const validationForEditUserDetails = () => {
    if (editUserDetails.first_name === "" || editUserDetails.first_name === undefined) {
      setIsEditUserNameEmpty({
        ...isEditUserNameEmpty,
        first_name: true,
      });
      return false;
    }

    if (editUserDetails.last_name === "" || editUserDetails.last_name === undefined) {
      setIsEditUserNameEmpty({
        ...isEditUserNameEmpty,
        last_name: true,
      });
      return false;
    }

    return true;
  };

  const savedEditUserDetails = () => {
    if (validationForEditUserDetails() === false) {
      return;
    }
    setIsUserEditModal(false);
    setloading(true);
    setloadingMsg({
      title: "Updating User Details",
      content: "Mapping Account Specifications",
    });

    updateUserDetails(props.CLIENT_USER_ID, editUserDetails)
      .then((e) => {
        if (e?.status === 500 || e?.status === 401) {
          throw e?.status;
        } else {
          fetchUserDetails(props.CLIENT_USER_ID);
          setloading(false);
          setApprovSuccessModal(true);
          setApprovSuccessModalData({
            title: "Successfully Update User Details!",
            footerTitle: "Updated Successfull",
          });
          setTimeout(() => {
            setApprovSuccessModal(false);
            seterror(true);
            seterrorMsg({
              title: "Info",
              content: "User Details Updated Successfully , Please Login Again",
            });
          }, 2000);
        }
      })
      .catch((err) => {
        setloading(false);
        seterror(true);
        seterrorMsg({
          title: err === 401 ? "Warning" : "Error",
          content: err === 500 ? "Internal Server Error" : err === 401 ? "Session TimeOut , Please Login Again" : "Something Went Wrong , Please Login Again",
        });
      });
  };

  const handleChangeEditCompanyDetails = (e) => {
    setEditCompanyDetails({
      ...editCompanyDetails,
      [e.target.name]: e.target.value,
    });
    if (isEditCompanyNameEmpty) {
      if (e.target.value.length > 0) {
        setIsEditCompanyNameEmpty({
          ...isEditCompanyNameEmpty,
          [e.target.name]: false,
        });
      }
    }
  };

  const validationForEditCompanyDetails = () => {
    if (editCompanyDetails.name === "" || editCompanyDetails.name === undefined) {
      setIsEditCompanyNameEmpty({
        ...isEditCompanyNameEmpty,
        name: true,
      });
      return false;
    }

    if (editCompanyDetails.address === "" || editCompanyDetails.address === undefined) {
      setIsEditCompanyNameEmpty({
        ...isEditCompanyNameEmpty,
        address: true,
      });
      return false;
    }

    if (editCompanyDetails.pin_code === "" || editCompanyDetails.pin_code === undefined) {
      setIsEditCompanyNameEmpty({
        ...isEditCompanyNameEmpty,
        pin_code: true,
      });
      return false;
    }
    return true;
  };
  const savedEditCompanyDetails = () => {
    if (validationForEditCompanyDetails() === false) {
      return;
    }
    setIsCompanyEditModal(false);
    setloading(true);
    setloadingMsg({
      title: "Updating Company Details",
      content: "Mapping Account Specifications",
    });

    const companyDetails = {
      company: editCompanyDetails,
    };

    updateAccountDetails(props.CLIENT_ACCOUNT_ID, companyDetails)
      .then((e) => {
        if (e?.status === 500 || e?.status === 401) {
          throw e?.status;
        } else {
          fetchAccountDetails(props.CLIENT_ACCOUNT_ID);
          setloading(false);
          setApprovSuccessModal(true);
          setApprovSuccessModalData({
            title: "Successfully Update Company Details!",
            footerTitle: "Updated Successfull",
          });
          setTimeout(() => {
            setApprovSuccessModal(false);
          }, 2000);
        }
      })
      .catch((err) => {
        setloading(false);
        seterror(true);
        seterrorMsg({
          title: err === 401 ? "Warning" : "Error",
          content: err === 500 ? "Internal Server Error" : err === 401 ? "Session TimeOut , Please Login Again" : "Something Went Wrong , Please Login Again",
        });
      });
  };

  return (
    <div>
      {loading && <Loading title={loadingMsg.title} content={loadingMsg.content} />}
      {error && <Error errorMsg={errorMsg} open={error} setOpen={seterror} />}
      <AccountDetails
        userDetails={userDetails}
        accountDetails={accountDetails}
        userAvailableCountry={userAvailableCountry}
        handleEditUserDetails={handleEditUserDetails}
        handleEditCompanyDetails={handleEditCompanyDetails}
      />
      <ModalComponent
        open={isUserEditModal}
        setOpen={setIsUserEditModal}
        header="Edit User Details"
        modalBody={
          <EditUserDetails
            editUserDetails={editUserDetails}
            handleChangeEditUserDetails={handleChangeEditUserDetails}
            isEditUserNameEmpty={isEditUserNameEmpty}
          />
        }
        saveText="Submit"
        saveButtonShown={true}
        saveButtonColor="#1abc9c"
        savedClicked={savedEditUserDetails}
      />
      <ModalComponent
        open={isCompanyEditModal}
        setOpen={setIsCompanyEditModal}
        header="Edit Company Details"
        modalBody={
          <EditCompanyDetails
            editCompanyDetails={editCompanyDetails}
            handleChangeEditCompanyDetails={handleChangeEditCompanyDetails}
            isEditCompanyNameEmpty={isEditCompanyNameEmpty}
          />
        }
        width="870px"
        saveText="Submit"
        saveButtonShown={true}
        saveButtonColor="#1abc9c"
        savedClicked={savedEditCompanyDetails}
      />
      <ApprovedSuccess Open={approvSuccessModal} title={approvSuccessModalData.title} footerTitle={approvSuccessModalData.footerTitle} />
    </div>
  );
}
