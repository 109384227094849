import { Container, Grid, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import moment from "moment";
import "./customers.scss";
import ModalComponent from "../../Component/Modal/modal";
import Loading from "../../Component/Loader/loading";
import Spinner from "../../Component/Loader/spinner";
import { Button } from "@mui/material";
import { experimentalStyled as styled } from "@mui/material/styles";
import DataTable from "../../Component/DataTable/table";
import {
  fetchSubscriptionCustomers,
  fetchWebsiteCustomers,
  getCustometPlanDetails,
  getTaxonomyGlobal,
  customerRegistrations,
  addCustomerSubscription,
  updateCustomerConstraints,
  deleteCustomerRecord,
  getCustomerAccountInfo,
  getCustomerByEmail,
  fetchCustomerEmailSuggestion,
} from "../../Services/service";
import AddCustomerModal from "./Componant/AddCustomer/addCustomerModal";
import SubscriptionPanel from "./Componant/SubscriptionPanel/subscriptionPanel";
import { validateFields } from "../../Helpers/validation";
import Error from "../../Component/ErrorLoader/error";
import ApproveSuccessModal from "../../Component/ApprovSuccessModal/approvSuccessModal";
import CustomerInfo from "./Componant/CustomerInfo/customerInfo";
import _ from "lodash";
import { planTypes } from "./Componant/SubscriptionPanel/subscriptionPanelEnum";
const AddUserButton = styled(Button)({
  color: "white",
  backgroundColor: "#1abc9c !important",
  borderColor: "#1abc9c !important",
  height: "33px",
  "&:hover": {
    backgroundColor: "#148f77 !important",
    borderColor: "#159a80 !important",
    color: "white",
  },
});

export default function Customers(props) {
  const [inputValues, setInputValues] = useState();
  const [reactSelectLoading, setReactSelectLoading] = useState(false);
  const [getSearchOption, setSearchOptionForMain] = useState([]);

  const customerCompanyFields = {
    address: "",
    country: "",
    email: "",
    email_id: "",
    name: "",
    phone_no: "",
    pin_code: "",
    website_url: "",
    referral_medium: "",
    state: "-",
    fax_no: "-",
    city: "-",
  };

  const customerPlanFields = {
    paymentDetails: "",
    searchLimit: "",
    saveQueryLimit: "",
    workSpaceLimit: "",
    maxWorkSpaceDeleteCount: "",
    maxWorkSpaceRecordCount: "",
    favCompanyLimit: "",
    favShipmentLimit: "",
    summaryLimit: "",
    reqBuyerLimit: "",
    displayLimitation: false,
    accessValidityEndDate: "",
    accessValidityStartDate: "",
    maxUser: "",
    payTransId: "",
    purchasePoints: "",
    payCurrency: "INR",
    payAmount: "",
    dataAvalidityEndDate: "",
    dataAvalidityStartDate: "",
    planType: "",
  };

  const [switchValue, setSwitchValue] = useState(false);
  const [loading, setloading] = useState(false);
  const [spinner, setspinner] = useState(false);
  const [loadingMsg, setLoadingMsg] = useState({});
  const [error, seterror] = useState(false);
  const [errorMsg, seterrorMsg] = useState({});
  const [searchEmail, setSearchEmail] = useState({
    emailId: "",
    isEmpty: false,
    errorText: "",
    email: "",
  });
  const [successModal, setSuccessModal] = useState(false);
  const [successMsg, setSuccessMsg] = useState(false);
  const [availableCountry, setAvailableCountry] = useState([]);

  const [totalCustomCountry, setTotalCustomCountry] = useState(0);
  const [selectedCountry, setSelectedCountry] = useState([]);

  const [blCountriesISO3, setblCountriesISO3] = useState([]);
  const [isOpenAddCustomer, setIsOpenAddCustomer] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [getOffsetPage, setOffsetPage] = useState(0);
  const [customerTableRows, setCustomerTableRows] = useState([]);
  const [totalAvailableRecords, setTotalAvailableRecords] = useState(0);
  const [IsOpencustomerPanelModal, setIsOpencustomerPanelModal] =
    useState(false);
  const [currentAccountDetails, setCurrentAccountDetails] = useState({});
  const [isEditPlan, setIsEditPlan] = useState(false);
  const [editUserId, setEditUserId] = useState();
  const [isDeleteCustomerModal, setIsDeleteCustomerModal] = useState(false);
  const [isCustomerInfoModal, setIsCustomerInfoModal] = useState(false);
  const [customerInfoDetails, setCustomerInfoDetails] = useState({});
  const [customerCompanyDetails, setCustomerCompanyDetails] = useState(
    customerCompanyFields
  );
  const [customerCompanyEmptyFields, setCustomerCompanyEmptyFields] = useState(
    {}
  );
  const [customerPlanDetails, setCustomerPlanDetails] =
    useState(customerPlanFields);
  const [customerPlanEmptyFields, setCustomerPlanEmptyFields] = useState({});
  const [IsOpenPlanModalAddCustomer, setIsOpenPlanModalAddCustomer] =
    useState(false);

  const customerTableHeader = [
    {
      key: "name",
      title: "Company",
    },
    {
      key: "country",
      title: "Country",
    },
    {
      key: "email_id",
      title: "Email",
    },
    {
      key: "tax_identification_no",
      title: "Tax No.",
    },
    {
      key: "phone_no",
      title: "Mobile No",
    },
    {
      key: "created_ts",
      title: "Added On",
    },
    {
      key: "is_active",
      title: "Status",
    },
    {
      key: "Action",
      title: "Action",
    },
  ];

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    fetchTaxonomyAPIhandler();
  }, []);

  useEffect(() => {
    getAccountDetails(0, 10);
    setPage(0);
    setRowsPerPage(10);
  }, [switchValue]);

  const handleCustomersChange = (event) => {
    setSwitchValue(event.target.checked);
  };

  const fetchTaxonomyAPIhandler = () => {
    setloading(true);
    setLoadingMsg({
      title: "Retrieving Customers",
      content: "Please Wait..",
    });
    const availableCountries = [];
    const codeIso3 = [];
    const blIso3 = [];
    getTaxonomyGlobal()
      .then((e) => {
        if (e?.status === 500 || e?.status === 401) {
          throw e?.status;
        } else {
          if (e?.data) {
            setloading(false);
            e?.data
              .filter((e) => e.bl_flag === false)
              .forEach((taxonomy) => {
                if (!codeIso3.includes(taxonomy.code_iso_3)) {
                  availableCountries.push({
                    country: taxonomy.country.toUpperCase(),
                    iso3: taxonomy.code_iso_3,
                  });
                  codeIso3.push(taxonomy.code_iso_3);
                }
              });
            setTotalCustomCountry(availableCountries?.length);
            e?.data
              .filter((e) => e.bl_flag === true)
              .forEach((taxonomy) => {
                if (!blIso3.includes(taxonomy?.code_iso_3)) {
                  blIso3.push(taxonomy?.code_iso_3);
                }
              });
            availableCountries.push({ country: "BL COUNTRIES", iso3: "bl" });
            setblCountriesISO3(blIso3);
            setAvailableCountry(availableCountries);
          } else {
            throw new Error();
          }
        }
      })
      .catch((err) => {
        setloading(false);
        seterror(true);
        seterrorMsg({
          title: err === 401 ? "Warning" : "Error",
          content:
            err === 500
              ? "Internal Server Error"
              : err === 401
              ? "Session TimeOut , Please Login Again"
              : "Something Went Wrong , Please Login Again",
        });
      });
  };

  const getWebsiteCustomer = (Page, rowsPerPage) => {
    let payload = {
      limit: rowsPerPage,
      // offset: page,
      // page
      offset: Page && rowsPerPage ? (Page - 1) * rowsPerPage : Page,
    };
    fetchWebsiteCustomers(payload)
      .then((e) => {
        setspinner(false);
        if (e?.status === 500 || e?.status === 401) {
          throw e?.status;
        } else {
          if (e?.data) {
            if (typeof e?.data === "object") {
              setCustomerTableRows(e?.data);
              setTotalAvailableRecords(e?.totalAccountCount);
            }
          } else {
            throw new Error();
          }
        }
      })
      .catch((err) => {
        setspinner(false);
        setloading(false);
        seterror(true);
        seterrorMsg({
          title: err === 401 ? "Warning" : "Error",
          content:
            err === 500
              ? "Internal Server Error"
              : err === 401
              ? "Session TimeOut , Please Login Again"
              : "Something Went Wrong , Please Login Again",
        });
      });
  };

  const getSubscribeCustomer = (Page, rowsPerPage) => {
    let payload = {
      limit: rowsPerPage,
      // page
      offset: Page && rowsPerPage ? Page * rowsPerPage : Page,
    };
    fetchSubscriptionCustomers(payload)
      .then((e) => {
        setspinner(false);
        if (e?.status === 500 || e?.status === 401) {
          throw e?.status;
        } else {
          if (e?.data) {
            setCustomerTableRows(e?.data);
            setTotalAvailableRecords(e?.totalAccountCount);
          } else {
            throw new Error();
          }
        }
      })
      .catch((err) => {
        setloading(false);
        seterror(true);
        seterrorMsg({
          title: err === 401 ? "Warning" : "Error",
          content:
            err === 500
              ? "Internal Server Error"
              : err === 401
              ? "Session TimeOut , Please Login Again"
              : "Something Went Wrong , Please Login Again",
        });
      });
  };

  const getAccountDetails = (page, rowsPerPage) => {
    if (switchValue) {
      getWebsiteCustomer(page, rowsPerPage);
    } else {
      getSubscribeCustomer(page, rowsPerPage);
    }
  };

  const customerDetailsPayload = () => {
    let _selectedCountry = selectedCountry;
    if (_selectedCountry.includes("bl")) {
      const index = _selectedCountry.findIndex((e) => e === "bl");
      _selectedCountry.splice(index, 1);
      blCountriesISO3 &&
        blCountriesISO3.length &&
        blCountriesISO3.forEach((e) => {
          _selectedCountry.push(e);
        });
    }

    const customerRegistrationsPayload = {
      plan: {
        // subscriptionType: "SP-CUSTOM",
        subscriptionType: customerPlanDetails?.planType,
        access_validity_interval: {
          start_date: customerPlanDetails?.accessValidityStartDate,
          end_date: customerPlanDetails?.accessValidityEndDate,
        },
        data_availability_interval: {
          start_date: customerPlanDetails?.dataAvalidityStartDate,
          end_date: customerPlanDetails?.dataAvalidityEndDate,
        },
        countries_available: _selectedCountry,
        favorite_company_limit: customerPlanDetails?.favCompanyLimit,
        favorite_shipment_limit: customerPlanDetails?.favShipmentLimit,
        max_query_per_day: customerPlanDetails?.searchLimit,
        max_save_query: customerPlanDetails?.saveQueryLimit,
        max_users: customerPlanDetails?.maxUser,
        max_workspace_count: customerPlanDetails?.workSpaceLimit,
        max_workspace_delete_count:
          customerPlanDetails?.maxWorkSpaceDeleteCount,
        max_workspace_record_count:
          customerPlanDetails?.maxWorkSpaceRecordCount,
        max_summary_limit: customerPlanDetails?.summaryLimit,
        max_request_shipment_count: customerPlanDetails?.reqBuyerLimit,
        is_hidden: customerPlanDetails?.displayLimitation,
        purchase_points: customerPlanDetails?.purchasePoints,
        payment: {
          transaction_id: customerPlanDetails?.payTransId,
          amount: customerPlanDetails?.payAmount,
          currency: customerPlanDetails?.payCurrency,
          note: customerPlanDetails?.paymentDetails,
        },
      },
      referral_medium: customerCompanyDetails?.referral_medium?.toUpperCase(),
      user: { email_id: customerCompanyDetails?.email_id },
    };

    return customerRegistrationsPayload;
  };

  const customerSubscriptionValidateField = () => {
    const planValidateFields = {
      country: selectedCountry,
      payTransId: customerPlanDetails.payTransId,
      accessValidityStartDate: customerPlanDetails.accessValidityStartDate,
      accessValidityEndDate: customerPlanDetails.accessValidityEndDate,
      maxUser: customerPlanDetails.maxUser,
      dataAvalidityStartDate: customerPlanDetails.dataAvalidityStartDate,
      dataAvalidityEndDate: customerPlanDetails.dataAvalidityEndDate,
      payCurrency: customerPlanDetails.payCurrency,
      payAmount: customerPlanDetails.payAmount,
      purchasePoints: customerPlanDetails.purchasePoints,
      searchLimit: customerPlanDetails?.searchLimit,
      saveQueryLimit: customerPlanDetails?.saveQueryLimit,
      workSpaceLimit: customerPlanDetails?.workSpaceLimit,
      maxWorkSpaceDeleteCount: customerPlanDetails?.maxWorkSpaceDeleteCount,
      maxWorkSpaceRecordCount: customerPlanDetails?.maxWorkSpaceRecordCount,
      favCompanyLimit: customerPlanDetails?.favCompanyLimit,
      favShipmentLimit: customerPlanDetails?.favShipmentLimit,
      summaryLimit: customerPlanDetails?.summaryLimit,
      reqBuyerLimit: customerPlanDetails?.reqBuyerLimit,
    };

    return validateFields(planValidateFields);
  };

  const handleAddorPlanBtn = (type, rData) => {
    setIsOpencustomerPanelModal(true);
    setCurrentAccountDetails(rData);
    if (type === "EDIT") {
      setIsEditPlan(true);
      // customerOrPlanAPIHandler(rData._id);
      setEditUserId(rData._id);
    } else {
      setIsEditPlan(false);
      const _customerPlanDetails = { ...customerPlanDetails };
      Object.keys(_customerPlanDetails).forEach((e) => {
        if (e !== "payCurrency") {
          _customerPlanDetails[e] = "";
        }
      });
      setCustomerPlanDetails(_customerPlanDetails);
      setCustomerPlanEmptyFields({});
      setSelectedCountry([]);
    }
  };

  const handleSelectedCountry = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedCountry(typeof value === "string" ? value.split(",") : value);
  };

  const handleSelectAll = () => {
    const _selectedCountry = [];
    availableCountry &&
      availableCountry.length &&
      availableCountry.forEach((country) => {
        _selectedCountry.push(country.iso3);
      });

    setSelectedCountry(_selectedCountry);
  };

  const handleDeselectAll = () => {
    setSelectedCountry([]);
  };
  const handleChangeRowsPerPage = (event) => {
    setspinner(true);
    setRowsPerPage(event.target.value);
    getAccountDetails(page, event.target.value);
  };

  const handleChangePage = (event, newPage) => {
    let offset = newPage * rowsPerPage;
    setOffsetPage(offset);
    // setPage(newPage);
    setPage(newPage - 1);
    getAccountDetails(newPage - 1, rowsPerPage);
  };

  const handlePreviousAddModal = () => {
    setIsOpenPlanModalAddCustomer(false);
    setIsOpenAddCustomer(true);
  };

  const customerRegistrationDetailsAPIHandler = () => {
    setloading(true);
    setLoadingMsg({
      title: "Registering Account",
      content: "Verifying Account Specifications",
    });
    let _customerCompanyDetails = { ...customerCompanyDetails };
    delete _customerCompanyDetails.email_id;
    delete _customerCompanyDetails.referral_medium;
    const customerRegistrationsPayload = customerDetailsPayload();
    customerRegistrationsPayload.company = _customerCompanyDetails;
    customerRegistrations(customerRegistrationsPayload)
      .then((e) => {
        if (e?.status === 500 || e?.status === 401) {
          throw e?.status;
        } else {
          if (e?.data) {
            if (e?.data?.activation_email_id) {
              setloading(false);
              setIsOpenPlanModalAddCustomer(false);
              getAccountDetails(0, 10);
              setPage(0);
              setRowsPerPage(10);
            } else if (e?.data?.data?.msg) {
              setloading(false);
              setIsOpenPlanModalAddCustomer(false);
              seterror(true);
              seterrorMsg({
                title: e?.data?.data?.type,
                content: e?.data?.data?.desc,
              });
            }
          } else {
            throw new Error();
          }
        }
      })
      .catch((err) => {
        setloading(false);
        seterror(true);
        seterrorMsg({
          title: err === 401 ? "Warning" : "Error",
          content:
            err === 500
              ? "Internal Server Error"
              : err === 401
              ? "Session TimeOut , Please Login Again"
              : "Something Went Wrong , Please Login Again",
        });
      });
  };

  const handleCustomerPlanNextBtn = () => {
    const emptyFields = customerSubscriptionValidateField();
    setCustomerPlanEmptyFields(emptyFields);

    if (Object.keys(emptyFields).length === 0) {
      customerRegistrationDetailsAPIHandler();
    }
  };

  const handleAddorEditPlanBtn = () => {
    const emptyFields = customerSubscriptionValidateField();
    setCustomerPlanEmptyFields(emptyFields);

    if (Object.keys(emptyFields).length === 0) {
      setloading(true);
      setLoadingMsg({
        title: "Adding Account Subscription",
        content: "Verifying Account Specifications",
      });
      const customerRegistrationsPayload = customerDetailsPayload();
      delete customerRegistrationsPayload.referral_medium;
      delete customerRegistrationsPayload.user;
      if (isEditPlan) {
        customerRegistrationsPayload.accountId = currentAccountDetails._id;

        updateCustomerConstraints(customerRegistrationsPayload)
          .then((e) => {
            if (e?.status === 500 || e?.status === 401) {
              throw e?.status;
            } else {
              if (e?.message) {
                setloading(false);
                if (e.message === "Constarints updated.") {
                  setIsOpencustomerPanelModal(false);
                  setSuccessModal(true);
                  setSuccessMsg({
                    title: "Successfully Update Customer Details!",
                    footerTitle: "Updated Successfull",
                  });
                  setTimeout(() => {
                    setSuccessModal(false);
                  }, 2000);
                }
              } else {
                throw new Error();
              }
            }
          })
          .catch((err) => {
            setloading(false);
            seterror(true);
            seterrorMsg({
              title: err === 401 ? "Warning" : "Error",
              content:
                err === 500
                  ? "Internal Server Error"
                  : err === 401
                  ? "Session TimeOut , Please Login Again"
                  : "Something Went Wrong , Please Login Again",
            });
          });
      } else {
        setIsOpencustomerPanelModal(false);
        customerRegistrationsPayload.account_id = currentAccountDetails?._id;
        customerRegistrationsPayload.account_email_id =
          currentAccountDetails?.access.email_id;
        customerRegistrationsPayload.user_id = props?.CLIENT_USER_ID;
        addCustomerSubscription(customerRegistrationsPayload)
          .then((e) => {
            if (e?.status === 500 || e?.status === 401) {
              throw e?.status;
            } else {
              if (e) {
                setloading(false);
                if (e.message) {
                  seterror(true);
                  seterrorMsg({
                    title: "Error",
                    content: e.message,
                  });
                } else {
                  setSuccessModal(true);
                  setSuccessMsg({
                    title: "Successfully Add Details!",
                    footerTitle: "Added Successfull",
                  });
                  setTimeout(() => {
                    setSuccessModal(false);
                  }, 2000);
                }
              } else {
                throw new Error();
              }
            }
          })
          .catch((err) => {
            setloading(false);
            seterror(true);
            seterrorMsg({
              title: err === 401 ? "Warning" : "Error",
              content:
                err === 500
                  ? "Internal Server Error"
                  : err === 401
                  ? "Session TimeOut , Please Login Again"
                  : "Something Went Wrong , Please Login Again",
            });
          });
      }
    }
  };

  const handleDeleteCustomerBtn = (row) => {
    setIsDeleteCustomerModal(true);
    setCurrentAccountDetails(row);
  };

  const deleteCustomerAPIHandler = () => {
    deleteCustomerRecord(currentAccountDetails._id).then((e) => {
      // setIsOpencustomerPanelModal(false);
      if (e.data.msg === "Deleted Successfully!") {
        setIsDeleteCustomerModal(false);
        setSuccessModal(true);
        setSuccessMsg({
          title: "Successfully Delete Customer!",
          footerTitle: "Deleted Successfull",
        });
        setTimeout(() => {
          setSuccessModal(false);
          setPage(0);
          setRowsPerPage(10);
          getAccountDetails(0, 10);
        }, 2000);
      } else if (e.message) {
        seterror(true);
        seterrorMsg({
          title: "Error",
          content: e.message,
        });
      }
    });
  };

  const handleCustomerInfoBtn = (row) => {
    setCurrentAccountDetails(row);
    setIsCustomerInfoModal(true);

    getCustomerAccountInfo(row._id)
      .then((e) => {
        if (e?.status === 500 || e?.status === 401) {
          throw e?.status;
        } else {
          if (e?.data && e?.data.length) {
            setCustomerInfoDetails(e?.data[0]);
          } else {
            throw new Error();
          }
        }
      })
      .catch((err) => {
        setloading(false);
        seterror(true);
        seterrorMsg({
          title: err === 401 ? "Warning" : "Error",
          content:
            err === 500
              ? "Internal Server Error"
              : err === 401
              ? "Session TimeOut , Please Login Again"
              : "Something Went Wrong , Please Login Again",
        });
      });
  };

  const handleAddCustomerBtnClick = () => {
    setIsOpenAddCustomer(true);
    setCustomerCompanyDetails(customerCompanyFields);
    setCustomerPlanDetails(customerPlanFields);
    setSelectedCountry([]);
  };

  const handleOnCloseEditSubscriptionPanelModal = () => {
    setIsOpencustomerPanelModal(false);
    setEditUserId(undefined);
  };

  const handleOnCloseAddSubscriptionPanelModal = () => {
    setIsOpenPlanModalAddCustomer(false);
    setEditUserId(undefined);
  };

  const handleSearchEmailBtnClick = (emailId) => {
    setspinner(true);
    getCustomerByEmail(emailId?._id)
      .then((e) => {
        setspinner(false);
        if (e?.status === 500 || e?.status === 401) {
          throw e?.status;
        } else {
          if (e?.data) {
            setCustomerTableRows(e.data);
            setTotalAvailableRecords(e.data?.length);
          } else if (e?.msg) {
            setloading(false);
            seterror(true);
            seterrorMsg({
              title: "Info",
              content: e?.msg,
            });
          } else {
            throw new Error();
          }
        }
      })
      .catch((err) => {
        setloading(false);
        seterror(true);
        seterrorMsg({
          title: err === 401 ? "Warning" : "Error",
          content:
            err === 500
              ? "Internal Server Error"
              : err === 401
              ? "Session TimeOut , Please Login Again"
              : "Something Went Wrong , Please Login Again",
        });
      });
  };

  const handleInputChangeForSearchTerm = _.debounce((value) => {
    if (value !== "") {
      setReactSelectLoading(true);
      loadOptions(value, "search", "");
      setInputValues(value);
    }
  }, 500);

  const loadOptions = async (inputValue) => {
    const dataSearchterm = [];
    if (inputValue !== "") {
      fetchCustomerEmailSuggestion(inputValue)
        .then((res) => {
          setReactSelectLoading(false);
          if (res?.data) {
            if (res?.data?.data?.length && res?.data?.data?.length > 0) {
              res?.data?.data?.forEach((value) => {
                dataSearchterm.unshift({
                  _id: value,
                });
              });
              setSearchOptionForMain(dataSearchterm);
            }
          }
        })
        .catch(() => {});
    }
  };
  const handleSearchForTable = (value) => {
    if (value === null) {
      setInputValues(null);
    } else {
      setInputValues(value);
    }
  };

  const handleCancelEmailSearch = () => {
    getAccountDetails(0, 10);
    setPage(0);
    setRowsPerPage(10);
  };

  return (
    <Container maxWidth="xxl" id="manage-customers">
      {loading && (
        <Loading title={loadingMsg.title} content={loadingMsg.content} />
      )}
      {spinner && <Spinner />}
      {error && <Error errorMsg={errorMsg} open={error} setOpen={seterror} />}
      <ApproveSuccessModal
        Open={successModal}
        title={successMsg.title}
        footerTitle={successMsg.footerTitle}
      />
      <Grid container className="row-dashbored">
        <Grid item lg={2} md={2} sm={3} xs={3}>
          <h3 className="text">
            Manage
            <span className="badge bg-soft-blue text-blue label-worksapce-name">
              Customers
            </span>
          </h3>
        </Grid>

        <Grid
          item
          lg={10}
          md={10}
          sm={6}
          xs={12}
          style={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
          }}
        >
          <div>
            <label className="switch">
              <input
                type="checkbox"
                id="togBtn"
                onChange={(e) => handleCustomersChange(e)}
              />
              <div className="slider round">
                <span className="on text-center">Websites Customers</span>
                <span className="off text-center">Subscription Customers</span>
              </div>
            </label>

            <AddUserButton
              style={{ marginLeft: "10px" }}
              onClick={() => handleAddCustomerBtnClick()}
            >
              Add Customer
            </AddUserButton>
          </div>
        </Grid>
      </Grid>
      <DataTable
        columns={customerTableHeader}
        rows={customerTableRows}
        rowsPerPage={rowsPerPage}
        page={page}
        isHeaderWrap={true}
        showPagination={true}
        handleAddorPlanBtn={handleAddorPlanBtn}
        handleDeleteCustomerBtn={handleDeleteCustomerBtn}
        handleCustomerInfoBtn={handleCustomerInfoBtn}
        totalAvailableData={totalAvailableRecords}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleChangePage={handleChangePage}
        tableHeigth="550px"
        handleSearchClick={handleSearchEmailBtnClick}
        handleSearchForTable={handleSearchForTable}
        handleCancelEmailSearch={handleCancelEmailSearch}
        searching={true}
        searchIcon={true}
        searchFieldName="Search Email"
        handleInputChangeForSearchTerm={handleInputChangeForSearchTerm}
        getSearchOption={getSearchOption}
        reactSelectLoading={reactSelectLoading}
        searchEmail={searchEmail}
        inputValues={inputValues}
        customer={true}
      />

      {/* Add plan Modal */}
      {IsOpencustomerPanelModal && (
        <ModalComponent
          open={IsOpencustomerPanelModal}
          setOpen={handleOnCloseEditSubscriptionPanelModal}
          width="1000px"
          header="Specify Subscription Details"
          modalBody={
            <SubscriptionPanel
              availableCountry={availableCountry}
              selectedCountry={selectedCountry}
              setSelectedCountry={setSelectedCountry}
              handleSelectedCountry={handleSelectedCountry}
              // nextPrev={true}
              handleSelectAll={handleSelectAll}
              handleDeselectAll={handleDeselectAll}
              handlePreviousAddModal={handlePreviousAddModal}
              subscriptionDetails={customerPlanDetails}
              setCustomerPlanDetails={setCustomerPlanDetails}
              // handleSubscriptionDetails={handleCustomerPlanDetails}
              customerPlanEmptyFields={customerPlanEmptyFields}
              customerPlanFields={customerPlanFields}
              editUserId={editUserId}
              blCountriesISO3={blCountriesISO3}
              totalCustomCountry={totalCustomCountry}
            />
          }
          savedClicked={handleAddorEditPlanBtn}
          saveText="Submit"
          saveButtonShown={true}
          saveButtonColor="#005d91"
        />
      )}

      {/* Add Customer Company Modal */}
      {isOpenAddCustomer && (
        <ModalComponent
          open={isOpenAddCustomer}
          setOpen={setIsOpenAddCustomer}
          width={windowDimensions.width > 1600 ? "80vw" : "100vw"}
          header="Add Customer Details"
          modalBody={
            <AddCustomerModal
              // customerCompanyDetails={customerCompanyDetails}
              // handleCustomerCompanyDetails={handleCustomerCompanyDetails}
              // customerCompanyEmptyFields={customerCompanyEmptyFields}
              // handleCompanyNextBtn={handleCompanyNextBtn}
              setCustomerCompanyDetails={setCustomerCompanyDetails}
              setIsOpenPlanModalAddCustomer={setIsOpenPlanModalAddCustomer}
              setIsOpenAddCustomer={setIsOpenAddCustomer}
              customerCompanyDetails={customerCompanyDetails}
            />
          }
          saveButtonShown={false}
        />
      )}

      {/* Add Customer Plan Modal */}
      {IsOpenPlanModalAddCustomer && (
        <ModalComponent
          open={IsOpenPlanModalAddCustomer}
          setOpen={handleOnCloseAddSubscriptionPanelModal}
          width="800px"
          header="Add Customer Details"
          modalBody={
            <SubscriptionPanel
              availableCountry={availableCountry}
              selectedCountry={selectedCountry}
              setSelectedCountry={setSelectedCountry}
              handleSelectedCountry={handleSelectedCountry}
              handleSelectAll={handleSelectAll}
              handleDeselectAll={handleDeselectAll}
              nextPrev={true}
              handlePreviousAddModal={handlePreviousAddModal}
              subscriptionDetails={customerPlanDetails}
              setCustomerPlanDetails={setCustomerPlanDetails}
              // handleSubscriptionDetails={handleCustomerPlanDetails}
              handleCustomerPlanNextBtn={handleCustomerPlanNextBtn}
              customerPlanEmptyFields={customerPlanEmptyFields}
              editUserId={editUserId}
              blCountriesISO3={blCountriesISO3}
              totalCustomCountry={totalCustomCountry}
            />
          }
          saveButtonShown={false}
        />
      )}

      {/* Delete Customer Confirmation Modal  */}
      {isDeleteCustomerModal && (
        <ModalComponent
          open={isDeleteCustomerModal}
          setOpen={setIsDeleteCustomerModal}
          header="Delete Customer"
          modalBody={
            <span
              style={{
                fontSize: "22px",
                fontWeight: 600,
                color: "rgb(50, 58, 70)",
              }}
            >
              Are you sure to delete this customer ?
            </span>
          }
          width="600px"
          saveText="Yes ,delete it"
          saveButtonShown={true}
          saveButtonColor="#f1556c"
          savedClicked={deleteCustomerAPIHandler}
        />
      )}

      {/* Customer Info Modal */}
      <CustomerInfo
        isCustomerInfoModal={isCustomerInfoModal}
        setIsCustomerInfoModal={setIsCustomerInfoModal}
        currentAccountDetails={currentAccountDetails}
        customerInfoDetails={customerInfoDetails}
      />
    </Container>
  );
}
