import * as React from "react";
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableRow,
  TablePagination,
  TableHead,
  IconButton,
  Pagination,
} from "@mui/material";
import AssessmentIcon from "@mui/icons-material/Assessment";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import "./table.scss";
import Checkbox from "@mui/material/Checkbox";
import { Button, CircularProgress, Box, Typography } from "@mui/material";

import { useEffect, useState } from "react";
import { experimentalStyled as styled } from "@mui/material/styles";
import TablePagionationDropdown from "../TablePaginationDropdown/TablePaginationDropdown";
import Search from "./Componant/search";
import moment from "moment";

const PrimaryBtn = styled(Button)({
  color: "#fff",
  backgroundColor: "#005D91",
  borderColor: " #005D91",
  marginLeft: "10px",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "#07527D !important",
    borderColor: "#07527D !important",
  },
});

const SuccessBtn = styled(Button)({
  color: "#fff",
  backgroundColor: "#1abc9c",
  borderColor: "#1abc9c",
  marginLeft: "10px",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "#159a80 !important",
    borderColor: "#148f77 !important",
  },
});

const SuccessLabel = styled(Button)({
  backgroundColor: "rgba(26, 188, 156, 0.25);",
  borderColor: "rgba(26, 188, 156, 0.25);",
  color: "#1abc9c !important",
  fontWeight: 900,
  fontSize: "12px",
  height: "19px",
  padding: "5px",
  cursor: "default",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "rgba(26, 188, 156, 0.25);",
    borderColor: "rgba(26, 188, 156, 0.25);",
  },
});
const ErrorLabel = styled(Button)({
  backgroundColor: "rgba(241, 85, 108, 0.25);",
  borderColor: "rgba(241, 85, 108, 0.25);",
  color: "#f1556c !important",
  fontWeight: 900,
  fontSize: "12px",
  height: "19px",
  padding: "5px",
  cursor: "default",
  // textTransform:"none",
  "&:hover": {
    backgroundColor: "rgba(241, 85, 108, 0.25);",
    borderColor: "rgba(241, 85, 108, 0.25);",
  },
});

const DeleteBtn = styled(Button)({
  color: "#fff",
  backgroundColor: "#f1556c",
  borderColor: "#f1556c",
  marginLeft: "10px",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "#be3b4e !important",
    borderColor: "#be3b4e !important",
  },
});

const InfoBtn = styled(Button)({
  color: "#fff",
  backgroundColor: "#4fc6e1",
  borderColor: "#4fc6e1",
  marginLeft: "10px",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "#25b7d8 !important",
    borderColor: "#25b7d8 !important",
  },
});
function DataTable(props) {
  const [stickeyRows, setstickeyRows] = useState([
    {
      col: 0,
    },
  ]);

  useEffect(() => {
    let _stickeyRows = [...stickeyRows];
    props.columns.length > 0 &&
      props.columns.forEach((column) => {
        if (props.stickeyColumn && props.stickeyColumn.includes(column.key)) {
          let row = document
            .getElementsByClassName(column.key)
            .getBoundingClientRect().width;
          let obj = {
            col: row,
          };
          _stickeyRows.push(obj);
        }
      });

    setstickeyRows(_stickeyRows);
  }, [props.columns, props.stickeyColumn]);

  const sanitizeDate = (value) => {
    return new Date(value).toDateString();
  };

  const statusColor = {
    failed: "#dc3545",
    ongoing: "#ffc107",
    pending: "#6c757d",
    completed: "#28a745",
    unknown: "#343a40",
  };

  return (
    <Paper id="dataTable" sx={{ width: "100%" }}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        {props?.showPagination === true && (
          <TablePagionationDropdown
            handleChange={props?.handleChangeRowsPerPage}
            value={props?.rowsPerPage}
          />
        )}
        {props?.searching && (
          <Search
            handleSearchForTable={props?.handleSearchForTable}
            searchFieldName={props?.searchFieldName}
            handleSearchClick={props?.handleSearchClick}
            handleCancelEmailSearch={props?.handleCancelEmailSearch}
            searchIcon={props?.searchIcon}
            handleInputChangeForSearchTerm={
              props?.handleInputChangeForSearchTerm
            }
            getSearchOption={props?.getSearchOption}
            reactSelectLoading={props?.reactSelectLoading}
            searchEmail={props?.searchEmail}
            inputValues={props?.inputValues}
            customer={props?.customer}
          />
        )}
      </div>

      <TableContainer
        sx={{ maxHeight: props.tableHeigth ? props.tableHeigth : 440 }}
      >
        <Table
          className={props.scroll == true ? "tableHeight" : ""}
          stickyHeader
          style={{ height: props.height }}
          aria-label="sticky table"
          size="small"
        >
          <TableHead>
            <TableRow style={{ height: 10 }}>
              {props?.columns?.map((column, index) => {
                return (
                  <>
                    <TableCell
                      id={props.isHeaderWrap ? "no-wrap" : ""}
                      className="tableHeader"
                      key={column.key}
                      style={{
                        background: "#c2d8e5",
                        // width: "300px",
                        overflowX: "scroll",
                        // whiteSpace:"nowrap"
                      }}
                    >
                      {column.title}
                    </TableCell>
                  </>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {props?.rows &&
              !!props.rows.length &&
              props.rows.slice(0, props.rowsPerPage).map((row, index) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                    {props.columns.map((column) => {
                      if (
                        column.title === "Company" ||
                        column.title === "Country" ||
                        column.title === "Tax No." ||
                        column.title === "Mobile No"
                      ) {
                        return (
                          <>
                            {props.stickyHeader ? (
                              <TableCell className="tableRow" key={column.key}>
                                {row[column.key]}
                              </TableCell>
                            ) : (
                              <TableCell
                                className="tableRow"
                                key={column.key}
                                style={{
                                  background: props.backgroundColor
                                    ? props.backgroundColor
                                    : "#f1f5f7",
                                  // height: "auto !important",

                                  overflowX: "scroll",
                                  whiteSpace: props.whiteSpaceNoWrap
                                    ? "nowrap"
                                    : "",
                                }}
                              >
                                {row.company[column.key]}
                              </TableCell>
                            )}
                          </>
                        );
                      } else if (column.title === "Added On") {
                        return (
                          <TableCell
                            className="tableRow"
                            key={column.key}
                            style={{
                              background: props.backgroundColor
                                ? props.backgroundColor
                                : "#f1f5f7",
                              // height: "auto !important",

                              overflowX: "scroll",
                              whiteSpace: props.whiteSpaceNoWrap
                                ? "nowrap"
                                : "",
                            }}
                          >
                            {sanitizeDate(row[column.key])}
                          </TableCell>
                        );
                      } else if (column.title === "Email") {
                        return (
                          <TableCell
                            className="tableRow"
                            key={column.key}
                            style={{
                              background: props.backgroundColor
                                ? props.backgroundColor
                                : "#f1f5f7",
                              // height: "auto !important",

                              overflowX: "scroll",
                              whiteSpace: props.whiteSpaceNoWrap
                                ? "nowrap"
                                : "",
                            }}
                          >
                            {row.access[column.key]}
                          </TableCell>
                        );
                      } else if (column.title === "Status") {
                        return row[column.key] === 1 ? (
                          <TableCell
                            style={{
                              background: props.backgroundColor
                                ? props.backgroundColor
                                : "#f1f5f7",
                              borderLeft: "1px solid #dee2e6",
                            }}
                          >
                            <SuccessLabel>Activated</SuccessLabel>
                          </TableCell>
                        ) : (
                          <TableCell
                            style={{
                              background: props.backgroundColor
                                ? props.backgroundColor
                                : "#f1f5f7",
                              borderLeft: "1px solid #dee2e6",
                            }}
                          >
                            <ErrorLabel
                              style={{
                                height: "19px",
                                padding: "5px",
                                fontSize: "10px",
                              }}
                            >
                              Deactivated
                            </ErrorLabel>
                          </TableCell>
                        );
                      } else if (column.title === "Action") {
                        return (
                          <>
                            <TableCell
                              style={{
                                background: "#f1f5f7",
                                borderLeft: "1px solid #dee2e6",
                                minWidth: "400px",
                              }}
                            >
                              <PrimaryBtn
                                onClick={() =>
                                  props.handleAddorPlanBtn("ADD", row)
                                }
                                className="customer-btn"
                              >
                                Add Plan
                              </PrimaryBtn>
                              <SuccessBtn
                                onClick={() =>
                                  props.handleAddorPlanBtn("EDIT", row)
                                }
                                className="customer-btn"
                                id={
                                  row?.plan_constraints?.subscriptionType !==
                                  "SP-CUSTOM"
                                    ? "customer-plan-btn-adjust"
                                    : ""
                                }
                              >
                                {row?.plan_constraints?.subscriptionType}
                              </SuccessBtn>
                              <DeleteBtn
                                onClick={() =>
                                  props.handleDeleteCustomerBtn(row)
                                }
                                className="customer-btn"
                              >
                                Delete
                              </DeleteBtn>
                              <InfoBtn
                                onClick={() => props.handleCustomerInfoBtn(row)}
                                className="customer-btn"
                              >
                                Info
                              </InfoBtn>
                            </TableCell>
                          </>
                        );
                      } else if (column.key === "flag_uri") {
                        const flagUrl = require(`../../Asset/flags/${row.flag_uri}`);
                        return (
                          <TableCell
                            style={{
                              background: "#f1f5f7",
                              borderLeft: "1px solid #dee2e6",
                            }}
                          >
                            <img
                              src={flagUrl}
                              style={{ height: "25px" }}
                              className="set-image"
                              alt="flag"
                            />
                          </TableCell>
                        );
                      } else if (column.title === "Query") {
                        const QueryData = JSON.parse(row[column.key]);
                        let query;
                        if (Array.isArray(QueryData) && QueryData.length > 0) {
                          QueryData.forEach((e) => {
                            if (Array.isArray(e.fieldValue)) {
                              if (e.fieldValue.length > 0) {
                                query = `${e.alias.split("_").join(" ")} : ${
                                  e.fieldValue
                                }`;
                              }
                            } else {
                              query = `${e.alias.split("_").join(" ")} : ${
                                e.fieldValue
                              }`;
                            }
                          });
                        }
                        return (
                          <TableCell
                            style={{
                              background: props.backgroundColor
                                ? props.backgroundColor
                                : "#f1f5f7",
                              maxWidth: "420px",
                              whiteSpace: "nowrap",
                              overflow: "auto",
                            }}
                          >
                            {query}
                          </TableCell>
                        );
                      } else if (column.title === "Update Request") {
                        return (
                          <TableCell
                            style={{
                              background: "#f1f5f7",
                              borderLeft: "1px solid #dee2e6",
                            }}
                          >
                            <PrimaryBtn
                              className="update-req"
                              onClick={() => props.handleUpdateReqBtn(row)}
                            >
                              Update Request
                            </PrimaryBtn>
                            <DeleteBtn
                              className="delete-req"
                              onClick={() => props.handleDeleteReqBtn(row)}
                            >
                              Delete Request
                            </DeleteBtn>
                          </TableCell>
                        );
                      } else if (column.title === "Upload Status") {
                        return (
                          <TableCell
                            style={{
                              background: props.backgroundColor
                                ? props.backgroundColor
                                : "#f1f5f7",
                              borderLeft: "1px solid #dee2e6",
                            }}
                          >
                            <span
                              className="set_status"
                              style={{
                                backgroundColor:
                                  statusColor[row[column.key]?.toLowerCase()],
                              }}
                            >
                              {row[column.key]}
                            </span>
                            {props.pendingLoader && (
                              <span className="pending-loader"></span>
                            )}
                          </TableCell>
                        );
                      } else if (column.title === "Progress") {
                        return (
                          <>
                            {(props?.progressCounter[index]?.count ||
                              row["status"] === "COMPLETED") &&
                            !props.pendingLoader ? (
                              <TableCell
                                style={{
                                  background: props.backgroundColor
                                    ? props.backgroundColor
                                    : "#f1f5f7",
                                  borderLeft: "1px solid #dee2e6",
                                }}
                                className="loader"
                              >
                                <Box
                                  sx={{
                                    position: "relative",
                                    display: "inline-flex",
                                  }}
                                >
                                  <CircularProgress
                                    variant="determinate"
                                    value={
                                      row["status"] === "COMPLETED"
                                        ? "100%"
                                        : props?.progressCounter[index]?.count
                                    }
                                  />
                                  <Box
                                    sx={{
                                      top: 0,
                                      left: 0,
                                      bottom: 0,
                                      right: 0,
                                      position: "absolute",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <Typography
                                      variant="caption"
                                      component="div"
                                      color="text.secondary"
                                    >
                                      {row["status"] === "COMPLETED"
                                        ? "100%"
                                        : `${Math.round(
                                            props?.progressCounter[index]?.count
                                          )}%`}
                                    </Typography>
                                  </Box>
                                </Box>
                                {props.pendingLoader && (
                                  <span className="pending-loader"></span>
                                )}
                              </TableCell>
                            ) : (
                              <TableCell
                                style={{
                                  background: props.backgroundColor
                                    ? props.backgroundColor
                                    : "#f1f5f7",
                                  borderLeft: "1px solid #dee2e6",
                                }}
                              >
                                <span
                                  className="set_status"
                                  style={{
                                    backgroundColor:
                                      statusColor[
                                        row[column.key]?.toLowerCase()
                                      ],
                                  }}
                                >
                                  {row[column.key]}
                                </span>
                                {props.pendingLoader && (
                                  <span className="pending-loader"></span>
                                )}
                              </TableCell>
                            )}
                          </>
                        );
                      } else if (column.title === "Actions") {
                        return row[column.key] === "Discard" ? (
                          <TableCell
                            style={{
                              background: props.backgroundColor
                                ? props.backgroundColor
                                : "#f1f5f7",
                              borderLeft: "1px solid #dee2e6",
                            }}
                          >
                            <DeleteBtn onClick={() => props.discardFile(row)}>
                              Discard
                            </DeleteBtn>
                            {props.pendingLoader && (
                              <span className="pending-loader"></span>
                            )}
                          </TableCell>
                        ) : (
                          <TableCell
                            style={{
                              background: props.backgroundColor
                                ? props.backgroundColor
                                : "#f1f5f7",
                              borderLeft: "1px solid #dee2e6",
                            }}
                          >
                            <span>{row[column.key]}</span>
                            {props.pendingLoader && (
                              <span className="pending-loader"></span>
                            )}
                          </TableCell>
                        );
                      } else if (column.key === "Validation") {
                        return (
                          <TableCell
                            style={{
                              background: props.backgroundColor
                                ? props.backgroundColor
                                : "#f1f5f7",
                              borderLeft: "1px solid #dee2e6",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <span>{row?.validationTitle}</span>
                              <span style={{ color: "#dc3545" }}>
                                {row?.validationContent}
                              </span>
                              {props.pendingLoader && (
                                <span className="pending-loader"></span>
                              )}
                            </div>
                          </TableCell>
                        );
                      } else if (column?.title === "Error Message") {
                        return (
                          <TableCell
                            className="tableRow"
                            key={column?.key}
                            style={{
                              background: props?.backgroundColor
                                ? props?.backgroundColor
                                : "#f1f5f7",
                              // height: "auto !important",
                              maxWidth: "420px",
                              overflowX: "scroll",
                              whiteSpace: props?.whiteSpaceNoWrap
                                ? "nowrap"
                                : "",
                            }}
                          >
                            {row[column.key]}
                          </TableCell>
                        );
                      } else if (
                        column.key === "created_at" &&
                        props?.trainingVideoFeedback
                      ) {
                        return (
                          <TableCell
                            className="tableRow"
                            key={column.key}
                            style={{
                              background: props.backgroundColor
                                ? props.backgroundColor
                                : "#f1f5f7",
                              // height: "auto !important",

                              overflowX: "scroll",
                              whiteSpace: props.whiteSpaceNoWrap
                                ? "nowrap"
                                : "",
                            }}
                          >
                            {moment(row[column.key]).format("LL")}
                          </TableCell>
                        );
                      } else {
                        return (
                          <>
                            {props.stickyHeader ? (
                              <TableCell className="tableRow" key={column.key}>
                                {row[column.key]}
                              </TableCell>
                            ) : (
                              <TableCell
                                className="tableRow"
                                key={column.key}
                                style={{
                                  background: props.backgroundColor
                                    ? props.backgroundColor
                                    : "#f1f5f7",
                                  // height: "auto !important",

                                  overflowX: "scroll",
                                  maxWidth:
                                    props.trainingVideoFeedback ||
                                    props?.isActivityTracker
                                      ? "420px"
                                      : "",
                                  whiteSpace: props.whiteSpaceNoWrap
                                    ? "nowrap"
                                    : "",
                                }}
                              >
                                {row[column.key]}
                              </TableCell>
                            )}
                          </>
                        );
                      }
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      {/* {props.showPagination === true ? (
        <TablePagination
          id="pagination"
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={props.totalAvailableData}
          rowsPerPage={props.rowsPerPage}
          page={props.page}
          onPageChange={props.handleChangePage}
          onRowsPerPageChange={props.handleChangeRowsPerPage}
        />
      ) : (
        ""
      )} */}
      {props.showPagination === true ? (
        <Pagination
          // color="primary"
          count={Math.ceil(props.totalAvailableData / props.rowsPerPage)}
          page={props.page + 1}
          onChange={props.handleChangePage}
        />
      ) : (
        ""
      )}
    </Paper>
  );
}

export default React.memo(DataTable);
