import React, { useState, useEffect } from "react";
import "./navbar.scss";
import EximpediaLogo from "../../Asset/images/logo-dark-og.png";
import EximpediaSmallLogo from "../../Asset/images/logo-sm-og.png";
import UserAvatar from "../../Asset/images/user-5.jpg";
import Cookies from "js-cookie";

import {
  Typography,
  Badge,
  IconButton,
  Toolbar,
  Box,
  AppBar,
  Menu,
  Avatar,
} from "@mui/material";

import NotificationsIcon from "@mui/icons-material/Notifications";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import PaidOutlinedIcon from "@mui/icons-material/PaidOutlined";
import AccessTimeFilledOutlinedIcon from "@mui/icons-material/AccessTimeFilledOutlined";
import ConfirmationNumberOutlinedIcon from "@mui/icons-material/ConfirmationNumberOutlined";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import CalendarViewMonthSharpIcon from "@mui/icons-material/CalendarViewMonthSharp";
import LanguageSharpIcon from "@mui/icons-material/LanguageSharp";
import LayersSharpIcon from "@mui/icons-material/LayersSharp";
import PersonSharpIcon from "@mui/icons-material/PersonSharp";
import StarSharpIcon from "@mui/icons-material/StarSharp";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import CircleNotificationsIcon from "@mui/icons-material/CircleNotifications";
import MenuIcon from "@mui/icons-material/Menu";
import MenuList from "@mui/material/MenuList";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import ChatIcon from "@mui/icons-material/Chat";

const useStyles = makeStyles({
  notiHeader: {
    display: "block",
    fontSize: "15px !important",
    fontWeight: 600,
    color: "#343A40 !important",
    position: "sticky",
    top: "0px",
    zIndex: 10,
    paddingTop: "10px",
    paddingBottom: "10px",
    paddingLeft: "20px",
    background: "white",
    margin: "0px",
  },
  dflex: {
    display: "flex",
  },
  notiData: {
    borderBottom: 1,
    backgroundColor: "#f5f6f8",
    borderColor: "lightgray",
    padding: "10px",
    position: "relative",
    borderTop: "1px solid #C5C5C5",
    margin: "2px",
  },
  notiIcon: {
    verticalAlign: "middle",
    position: "absolute",
    top: "35%",
  },
  heading: {
    fontSize: "0.875rem !important",
    fontWeight: 200,
    color: "#343a40 !important",
    marginLeft: "50px !important",
  },
  description: {
    display: "inline-block",
    fontSize: "12px !important",
    fontWeight: 400,
    marginLeft: "50px !important",
    color: "#98a6ad !important",
  },
  navbox: {
    position: "sticky",
    top: "0px",
  },
  navhead: {
    backgroundColor: "white !important",
    color: "#6c757d !important",
    position: "sticky !important",
    top: 0,
  },
  navAvatar: {
    maxBlockSize: 40,
    marginRight: "5px",
    width: "30px !important",
    height: "30px !important",
  },
  dashIcon: {
    position: "relative",
    top: "4px",
  },
  setNavbar: {
    height: "60px !important",
    background: "#005d91 !important",
    zIndex: "5 !important",
  },
  footerStyles: {
    width: "100%",
    backgroundColor: "#eeeff3",
    padding: "20px 0px",
    color: "#98a6ad",
    fontSize: "14px",
    display: "flex",
    position: "fixed",
  },
});
export default function PrimarySearchAppBar({ children }) {
  const classes = useStyles();

  let CLIENT_FIRSTNAME = Cookies.get("firstName");
  let CLIENT_LASTNAME = Cookies.get("lastName");

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  const setstyleNavbar = () => {
    let navLinks = document.querySelector(".nav-links");

    navLinks.style.left = "0";
  };

  const setArrowNavbar = () => {
    let navLinks = document.querySelector(".nav-links");
    navLinks.classList.toggle("show1");
  };

  const setJsArrowNavbar = () => {
    let navLinks = document.querySelector(".nav-links");
    navLinks.classList.toggle("show3");
  };

  const setUserArrowNavbar = () => {
    let navLinks = document.querySelector(".nav-links");
    navLinks.classList.toggle("show4");
  };

  const setFavouriteArrowNavbar = () => {
    let navLinks = document.querySelector(".nav-links");
    navLinks.classList.toggle("show5");
  };

  const CloseMenuNavbar = (e) => {
    let navLinks = document.querySelector(".nav-links");
    navLinks.style.left = "-600px";
    if (
      e.target.innerText === "All Trade Data" ||
      e.target.innerText === "Import Trade Data" ||
      e.target.innerText === "Export Trade Data"
    ) {
      navLinks.classList.toggle("show1");
    } else if (e.target.innerText === "Manage Workspace") {
      navLinks.classList.toggle("show3");
    } else if (e.target.innerText === "Manage Users") {
      navLinks.classList.toggle("show4");
    } else if (
      e.target.innerText === "Favourite Shipment" ||
      e.target.innerText === "Favourite Country"
    ) {
      navLinks.classList.toggle("show5");
    }
  };

  // function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Box sx={{ flexGrow: 1, zIndex: 5 }} id="navbarComponent">
      <Box className={classes.navbox}>
        <AppBar className={classes.navhead}>
          <Toolbar>
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ display: { xs: "block", sm: "block" } }}
            >
              <span className="header-logo-lg">
                {windowDimensions.width > 750 ? (
                  <img src={EximpediaLogo} alt="logo" />
                ) : (
                  <img src={EximpediaSmallLogo} alt="smlogo" />
                )}
              </span>
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
            <Box
              sx={{
                display: { md: "flex", sm: "inline-flex", xs: "inline-flex" },
              }}
            >
              <ul>
                <li>
                  <Link to="#" className="set-position" id="user-avatar">
                    {windowDimensions.width > 750 ? (
                      <>
                        <Avatar
                          alt="User"
                          src={UserAvatar}
                          className={classes.navAvatar}
                        />
                        <span
                          style={{ color: "black", marginTop: "3px" }}
                          className="userInfo"
                        >
                          {CLIENT_FIRSTNAME} {CLIENT_LASTNAME}
                          <KeyboardArrowDownOutlinedIcon
                            className="bx bxs-chevron-down account-arrow arrow"
                            fontSize="small"
                            style={{ position: "relative", top: "4px" }}
                          />
                        </span>
                      </>
                    ) : (
                      <>
                        <Avatar
                          alt="User"
                          src={UserAvatar}
                          sx={{ maxBlockSize: 40, width: 30, height: 30 }}
                        />
                      </>
                    )}
                  </Link>

                  <ul className="dropdown" id="user-dropdown">
                    <li>
                      <Link
                        to="/providers/accounts/profile"
                        className="dropdownItem "
                        style={{ paddingTop: "30px", display: "flex" }}
                      >
                        <PersonOutlineOutlinedIcon
                          fontSize="small"
                          className="set-user-position"
                        />
                        <Typography className="userInfoDetails">
                          Account
                        </Typography>
                      </Link>
                    </li>

                    <li id="logout">
                      <Link to="/providers/logout" className="dropdownItem">
                        <LogoutOutlinedIcon
                          fontSize="small"
                          className="set-user-position"
                        />
                        <Typography className="userInfoDetails">
                          Logout
                        </Typography>
                      </Link>
                    </li>
                  </ul>
                </li>
              </ul>
            </Box>
          </Toolbar>
        </AppBar>
        <AppBar position="sticky" className={classes.setNavbar}>
          <nav>
            <div className="navbar">
              <MenuIcon className="bx bx-menu" onClick={setstyleNavbar} />
              <div className="nav-links">
                <div className="sidebar-logo">
                  <CloseIcon onClick={CloseMenuNavbar} />
                </div>
                <ul className="links" id="navbar-hover-item">
                  <li>
                    <Link
                      to="/providers/dashboard"
                      className="set-position change-hover-color"
                      onClick={CloseMenuNavbar}
                    >
                      <span className="dash-position">
                        <CalendarViewMonthSharpIcon
                          fontSize="small"
                          className={classes.dashIcon}
                        />
                        <span style={{ padding: "0 4px" }}>Dashboard</span>
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link to="#" className="set-position change-hover-color">
                      <span id="nav-icons" onClick={setArrowNavbar}>
                        <LanguageSharpIcon
                          fontSize="small"
                          className="navbar-icons"
                        />
                        <span className="manage-position">
                          Trade Data Manager
                        </span>
                        <ExpandMoreIcon className="bx bxs-chevron-down country-nav-arrow arrow" />
                      </span>
                    </Link>

                    <ul className="htmlCss-sub-menu sub-menu">
                      <li>
                        <Link
                          to="/providers/trade/builder"
                          className="dropdownItem pt-15"
                          onClick={(e) => CloseMenuNavbar(e)}
                        >
                          Build Trade Data
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/providers/trade/ledger"
                          className="dropdownItem"
                          onClick={(e) => CloseMenuNavbar(e)}
                        >
                          Trade Data Ledger
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="#" className="set-position change-hover-color">
                      <span id="nav-icons" onClick={setUserArrowNavbar}>
                        <LayersSharpIcon
                          fontSize="small"
                          className="navbar-icons"
                        />
                        <span className="manage-position">Customers</span>
                        <ExpandMoreIcon className="bx bxs-chevron-down workspace-nav-arrow arrow " />
                        {/* <PersonSharpIcon fontSize="small" className="navbar-icons" />
                        <span className="manage-position">Customers</span>
                        <ExpandMoreIcon className="bx bxs-chevron-down user-arrow arrow" fontSize="small" /> */}
                      </span>
                    </Link>

                    <ul className="user-sub-menu sub-menu">
                      <li className="px-py">
                        <Link
                          to="/providers/customers"
                          className="dropdownItem pt-15"
                          onClick={(e) => CloseMenuNavbar(e)}
                        >
                          Manage Customers
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link
                      to="/providers/activity-tracker"
                      className="set-position change-hover-color"
                      onClick={CloseMenuNavbar}
                    >
                      <span className="dash-position">
                        <AccessTimeFilledIcon
                          fontSize="small"
                          className={classes.dashIcon}
                        />
                        <span style={{ padding: "0 4px" }}>
                          Activity Tracker
                        </span>
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/providers/shipments-request"
                      className="set-position change-hover-color"
                      onClick={CloseMenuNavbar}
                    >
                      <span className="dash-position">
                        <ShoppingBagIcon
                          fontSize="small"
                          className={classes.dashIcon}
                        />
                        <span style={{ padding: "0 4px" }}>
                          Shipments Request
                        </span>
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/providers/notifications"
                      className="set-position change-hover-color"
                      onClick={CloseMenuNavbar}
                    >
                      <span className="dash-position">
                        <NotificationsIcon
                          fontSize="small"
                          className={classes.dashIcon}
                        />
                        <span style={{ padding: "0 4px" }}>Notifications </span>
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/providers/feedback"
                      className="set-position change-hover-color"
                      onClick={CloseMenuNavbar}
                    >
                      <span className="dash-position">
                        <ChatIcon
                          fontSize="small"
                          className={classes.dashIcon}
                        />
                        <span style={{ padding: "0 4px" }}>
                          Training Feedback
                        </span>
                      </span>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </AppBar>
      </Box>
      <Box
        height="calc(100vh - 203px)"
        overflow="hidden auto"
        style={{ position: "relative", zIndex: -1 }}
        id="middle-content"
      >
        {children}
      </Box>
      <Box className={classes.footerStyles} id="foot">
        <Typography
          style={{ marginLeft: "25px", fontSize: "14px" }}
          id="devlop-parent"
        >
          <Typography
            className="devloped"
            style={{ display: "inline-block", fontSize: "14px" }}
          >
            2020 - 2021 © Developed by
          </Typography>
          <Link
            to="https://www.seair.co.in/"
            target="_blank"
            style={{ marginLeft: "4px" }}
            id="footer-anchor"
          >
            Eximpedia
          </Link>
        </Typography>

        <Box className="about-contact">
          <Typography style={{ marginLeft: "10px", fontSize: "14px" }}>
            <Link
              to="#"
              style={{ color: "#98a6ad" }}
              className="footer-content"
              id="about"
            >
              About Us
            </Link>
          </Typography>
          <Typography id="contact">
            <a
              href="mailto://support@eximpedia.app"
              style={{ color: "#98a6ad" }}
              className="footer-content"
              id="contact-us"
              rel="noopener noreferrer"
            >
              Contact Us
            </a>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
