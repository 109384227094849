// const isNumeric = (number) => {
//   const reg = new RegExp("^[+]?([0-9]+(?:[.][0-9]*)?|.[0-9]+)$", "i");
//   return reg.test(number);
// };

// str.match(/^ *$/) !== null
const isEmpty = (str) => str === undefined || str === null;
export const validateFields = (fields) => {
  const fieldErrors = {};
  Object.entries(fields).forEach((ele) => {
    if (isEmpty(ele[1])) {
      fieldErrors[ele[0]] = true;
    } else if (ele[1].length === 0) {
      fieldErrors[ele[0]] = true;
    }
  });

  return fieldErrors;
};
