import React, { useState, useEffect, useRef } from "react";
import AzureService from "../../../utils/AzureUpload";
import Papa from "papaparse";
import { Container } from "@mui/material";
import SelectComponant from "./SelectComponant/selectComponant";
import { getTaxonomyGlobal, verifyUploadedFiles, addLedgerFiles, updateLedgerFile } from "../../../Services/service";
import Loading from "../../../Component/Loader/loading";
import DetailsInfoModal from "../../../Component/DetailsInfoModal/detailsInfoModal";
import Error from "../../../Component/ErrorLoader/error";
import Upload from "./UploadFile/upload";
import ProcessStatstics from "./ProcessStatistics/processStatstics";
import { getMonthNumber } from "../../../utils/CommanFunctions";

export default function Build() {
  const [loading, setloading] = useState(false);
  const [loadingAlert, setLoadingAlert] = useState(false);
  const [loadingMsg, setLoadingMsg] = useState({});
  const [errorMsg, seterrorMsg] = useState({});
  const [error, seterror] = useState(false);
  const [getTaxonomy, setTaxonomy] = useState([]);
  const [allCountry, setAllCountry] = useState([]);
  const [currentTradeType, setCurrentTradeType] = useState("IMPORT");
  const [currentCountry, setCurrentCountry] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [allSelectedFiles, setAllSelectedFiles] = useState([]);
  const [verifyFileArr, setVerifyFileArr] = useState([]);
  const [detailsInfoModal, setDetailsInfoModal] = useState(false);
  const [processStasticsRows, setProcessStasticsRows] = useState([]);
  const [progressCounter, setProgressCounter] = useState([]);
  const tableRef = useRef(null);
  const [pendingLoader, setPendingLoader] = useState(false);
  const statusBlockConfig = [
    {
      status: "U",
      indicatorVariant: "dark",
      text: "UNKNOWN",
    },
    {
      status: "P",
      indicatorVariant: "secondary",
      text: "PENDING",
    },
    {
      status: "O",
      indicatorVariant: "warning",
      text: "ONGOING",
    },
    {
      status: "F",
      indicatorVariant: "danger",
      text: "FAILED",
    },
    {
      status: "S",
      indicatorVariant: "success",
      text: "COMPLETED",
    },
  ];
  const [monthAndYear, setMonthAndYear] = useState({
    month: "",
    year: "",
    value: "",
  });
  useEffect(() => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = (currentDate.getMonth() + 1)
      .toString()
      .padStart(2, "0");
    var defaultMonthAndYear = `${currentYear}-${currentMonth}`;
    var currentMonthName = getMonthNumber(currentMonth);
    setMonthAndYear({
      month: currentMonthName,
      year: currentYear,
      value: defaultMonthAndYear,
    });
  }, []);
  useEffect(() => {
    // if (processStasticsRows && processStasticsRows.length) {
    //   const _progressCounter = [];
    //   processStasticsRows.forEach((e) => {
    //     _progressCounter.push({ file: e?.name });
    //   });
    //   setProgressCounter(_progressCounter);
    // }
  }, [progressCounter]);

  // const [fileImportQueue, setFileImportQueue] = useState([]);
  let fileImportQueue = [];

  useEffect(() => {
    setloading(true);
    getTaxonomyData();
  }, []);

  // useEffect(() => {
  //   if (fileImportQueue.length > 0) {
  //     sendFileForRegistration();
  //   }
  // }, [fileImportQueue]);

  // start
  //  Add Data to table Row
  const updateUploadSyncProcess = (fileEntry, ProcessStasticsRowsParams) => {
    const currentIndex = processStasticsRows.findIndex((e) => e.name === fileEntry?.file);
    const stageStatusObj = statusBlockConfig.find((stageConfig) => stageConfig.status === fileEntry?.status);
    if (stageStatusObj) {
      // const _processStasticsRows = [...processStasticsRows];
      const _processStasticsRows = ProcessStasticsRowsParams;
      _processStasticsRows[currentIndex] = {
        ..._processStasticsRows[currentIndex],
        name: fileEntry?.file,
        fileId: fileEntry._id,
        startDate: fileEntry?.started_date?.toDateString(),
        tradeType: fileEntry.trade_type,
        country: fileEntry.country,
        validationTitle: fileEntry?.status === "S" ? "Upload Success" : "Upload Failed",
        validationContent: fileEntry?.status === "S" ? "No Issues Detected" : `${fileEntry?.errors?.length} No Issues Detected`,
        status: `${stageStatusObj?.text}`,
        progress: fileEntry?.status === "F" ? `${stageStatusObj?.text}` : "",
        Actions: fileEntry?.status === "P" ? "" : fileEntry?.status === "F" ? "Discard" : fileEntry?.status === "S" ? "file is being ingested" : "",
      };

      setProcessStasticsRows(_processStasticsRows);
      // setPendingLoader(false);
    }
  };

  const updateUploadValidationProcess = (fileEntry, ProcessStasticsRowsParams) => {
    const currentIndex = processStasticsRows.findIndex((e) => e.name === fileEntry?.file);
    const stageStatusObj = statusBlockConfig.find((stageConfig) => stageConfig.status === fileEntry?.status);
    // let _processStasticsRows = [...processStasticsRows];
    let _processStasticsRows = ProcessStasticsRowsParams;

    if (stageStatusObj) {
      _processStasticsRows[currentIndex] = {
        ..._processStasticsRows[currentIndex],
        name: fileEntry?.file,
        fileId: fileEntry._id,
        startDate: fileEntry?.started_date?.toDateString(),
        tradeType: fileEntry.trade_type,
        country: fileEntry.country,
        status: fileEntry?.status === "S" ? "PENDING" : stageStatusObj?.text,
        progress: fileEntry?.status === "F" ? stageStatusObj?.text : "",
        tradeRecords: fileEntry?.trade_records,
        validationTitle: fileEntry?.status === "S" ? "Formats Passed" : "Formats Failed",
        validationContent: fileEntry?.status === "S" ? "No Issues Detected" : `${fileEntry?.errors?.length} Issues Detected`,
        Actions: fileEntry?.status === "P" ? "" : fileEntry?.status === "F" ? "Discard" : fileEntry?.status === "S" ? "file is being ingested" : "",
      };
      setPendingLoader(false);
    }
    setProcessStasticsRows(_processStasticsRows);
  };

  const addOngoingUploadEntry = (fileEntry, ProcessStasticsRowsParams) => {
    // const _processStasticsRows = [...processStasticsRows];
    let _processStasticsRows = [...processStasticsRows];
    if (ProcessStasticsRowsParams) {
      _processStasticsRows = ProcessStasticsRowsParams;
    }
    const currentIndex = processStasticsRows.findIndex((e) => e.name === fileEntry?.file);
    _processStasticsRows[currentIndex] = {
      ..._processStasticsRows[currentIndex],
      name: fileEntry?.file,
      fileId: fileEntry._id,
      startDate: fileEntry?.started_date?.toDateString(),
      tradeType: fileEntry?.trade_type,
      country: fileEntry?.country,
      tradeRecords: fileEntry?.trade_records,
      validationTitle: "Pending",
      validationContent: "Not Initiated",
      status: "",
      progress: "",
      Actions: "",
    };
    setPendingLoader(true);
    tableRef.current.scrollIntoView({
      behavior: "smooth",
    });

    // setProcessStasticsRows(_processStasticsRows);
  };
  const updateUploadProgressCounter = (
    ProcessStasticsRowsParams,
    fileEntry,
    progressCounterParams
  ) => {
    if (progressCounterParams) {
      let _progressCounter = [...progressCounter];

      const countObj = {
        file: fileEntry.file,
        count: progressCounterParams,
      };
      const currentIndex = progressCounter?.findIndex(
        (e) => e.file === fileEntry?.file
      );

      _progressCounter[currentIndex] = countObj;
      setProgressCounter(_progressCounter);
    }
  };

  // end

  const filterTaxonomnyStandard = (taxonomyId) => {
    let taxonomyStandardsArr = getTaxonomy.find((taxonomyStandard) => taxonomyStandard._id === taxonomyId);
    return taxonomyStandardsArr;
  };

  const parseFileCompatibility = (fileId, ProcessStasticsRowsParams) => {
    const fileEntry = fileImportQueue.find((fileEntry) => fileEntry._id === fileId);
    const parseFile = allSelectedFiles.find((file) => file.name === fileEntry?.file);
    Papa.parse(parseFile, {
      worker: false,
      header: true,
      skipEmptyLines: true,
      quoteChar: '"',
      escapeChar: '"',
      delimiter: ",",
      encoding: "utf-8",
      complete: function (results) {
        examineTradeFileCompatibilityChecks(fileEntry, results, null, ProcessStasticsRowsParams);
      },
      error: function (error, file) {
        examineTradeFileCompatibilityChecks(fileEntry, null, error, ProcessStasticsRowsParams);
      },
    });
  };

  const examineTradeFileCompatibilityChecks = (fileEntry, results, issues, ProcessStasticsRowsParams) => {
    let headerFields = results.meta.fields;
    headerFields = headerFields.map(field => field.toLowerCase());

    let taxonomyStandard = filterTaxonomnyStandard(fileEntry.taxonomy_id);
    if (taxonomyStandard.country.toLowerCase() == "india" && taxonomyStandard.trade.toLowerCase()) {
      headerFields.push("DECLARATION_NO");
    }
    let missingFields = [];

    taxonomyStandard.fields.showcase.forEach((field) => {
      field = field.toLowerCase();
      if (!headerFields.includes(field)) {
        if (!missingFields.includes(field)) {
          missingFields.push(field);
        }
      }
    });
    taxonomyStandard.fields.explore.forEach((field) => {
      field = field.toLowerCase();
      if (!headerFields.includes(field)) {
        if (!missingFields.includes(field)) {
          missingFields.push(field);
        }
      }
    });
    taxonomyStandard.fields.search.forEach((field) => {
      field = field.toLowerCase();
      if (!headerFields.includes(field)) {
        if (!missingFields.includes(field)) {
          missingFields.push(field);
        }
      }
    });
    taxonomyStandard.fields.filter.forEach((field) => {
      field = field.toLowerCase();
      if (!headerFields.includes(field)) {
        if (!missingFields.includes(field)) {
          missingFields.push(field);
        }
      }
    });
    taxonomyStandard.fields.analytics.forEach((field) => {
      field = field.toLowerCase();
      if (!headerFields.includes(field)) {
        if (!missingFields.includes(field)) {
          missingFields.push(field);
        }
      }
    });
    taxonomyStandard.fields.all.forEach((field) => {
      field = field.toLowerCase();
      if (!headerFields.includes(field)) {
        if (!missingFields.includes(field)) {
          missingFields.push(field);
        }
      }
    });

    let columnTypedHeaders = [];
    headerFields.forEach((headerField) => {
      let dataTypeArr = taxonomyStandard.fields.dataTypes.filter((type) => type.field.toLowerCase() === headerField);
      if (dataTypeArr != null && dataTypeArr != undefined) {
        if (dataTypeArr.length == 1) {
          columnTypedHeaders.push(dataTypeArr[0].field.concat(".", dataTypeArr[0].type));
        } else {
          columnTypedHeaders.push(headerField.concat(".", "auto()"));
        }
      } else {
        missingFields.push(headerField);
      }
    });

    let fileRecordsReference = "NO_TAG";
    if (headerFields.includes("RECORDS_TAG")) {
      const dataRow = results.data[0];
      fileRecordsReference = dataRow["RECORDS_TAG"];
    }

    let evaluateStatus = "U";
    let errorsArr = [];
    if (missingFields.length === 0 && results.errors.length === 0) {
      evaluateStatus = "S";
    } else {
      evaluateStatus = "F";
      if (missingFields.length > 0) {
        let errorBody = {};
        errorBody.type = "MISSING HEADERS";
        errorBody.code = missingFields.length;
        errorBody.message = missingFields.toString();
        errorBody.extras = {};
        errorsArr.push(errorBody);
      }
      results.errors.forEach((error) => {
        let errorBody = {};
        errorBody.type = error.type;
        errorBody.code = error.code;
        errorBody.message = error.message;
        errorBody.extras = {
          row: error.row,
        };
        errorsArr.push(errorBody);
      });
    }

    let updateDataStageFile = null;
    for (let i in fileImportQueue) {
      if (fileImportQueue[i]._id === fileEntry._id) {
        if (fileImportQueue[i].stage === "EXAMINE" && fileImportQueue[i].status === "P") {
          fileImportQueue[i].status = evaluateStatus;
          fileImportQueue[i].errors = errorsArr;
          fileImportQueue[i].trade_records = results.data.length;
          fileImportQueue[i].file_records_tag = fileRecordsReference;
          fileImportQueue[i].column_typed_headers = columnTypedHeaders;
          updateDataStageFile = fileImportQueue[i];
          break;
        }
      }
    }

    if (updateDataStageFile) {
      updateFileDataStageAPIHandler(updateDataStageFile, ProcessStasticsRowsParams);
      updateUploadValidationProcess(updateDataStageFile, ProcessStasticsRowsParams);
    }
  };
  const updateFileDataStageAPIHandler = (file, ProcessStasticsRowsParams) => {
    setloading(true);
    setLoadingMsg({
      title: "Data Stage Updation",
      content: `Updating File: ${file.file}`,
    });
    let dataStage = {
      level: file.stage,
      status: file.status,
      errors: file.errors,
      meta: {},
    };
    if (file.stage === "EXAMINE") {
      dataStage.meta.trade_records = file.trade_records;
      dataStage.meta.file_records_tag = file.file_records_tag;
    }

    let payloadData = {
      payload: JSON.stringify({
        stage: dataStage,
      }),
    };

    updateLedgerFile(file?._id, payloadData).then((e) => {
      if (e?.status === 500 || e?.status === 401) {
        throw e?.status;
      } else {
        setTimeout(() => {
          setloading(false);
        }, 3000);
        if (file?.stage === "EXAMINE") {
          sendFileForValidation(ProcessStasticsRowsParams);
        } else if (file?.stage === "UPLOAD") {
          sendFileForUpload(ProcessStasticsRowsParams);
        }
      }
    });
  };

  const discardFile = (fileEntry) => {
    fileImportQueue = fileImportQueue.filter((queueFile) => queueFile._id != fileEntry.fileId);
    const currentIndex = processStasticsRows.findIndex((e) => e.fileId === fileEntry.fileId);
    const _processStasticsRows = [...processStasticsRows];
    const _allSelectedFiles = [...allSelectedFiles];
    _processStasticsRows.splice(currentIndex, 1);
    _allSelectedFiles.splice(currentIndex, 1);
    if (_processStasticsRows.length === 0) {
      document.location.reload(true);
    }
    setProcessStasticsRows(_processStasticsRows);
    setAllSelectedFiles(_allSelectedFiles);
  };

  const sendFileForValidation = (ProcessStasticsRowsParams) => {
    let _processStasticsRows = [...processStasticsRows];
    if (ProcessStasticsRowsParams) {
      _processStasticsRows = ProcessStasticsRowsParams;
    }
    let isPendingValidations = false;
    for (let i in fileImportQueue) {
      if (fileImportQueue[i].stage === "REGISTER" && fileImportQueue[i].status === "S") {
        fileImportQueue[i].stage = "EXAMINE";
        fileImportQueue[i].status = "P";
        isPendingValidations = true;
        parseFileCompatibility(fileImportQueue[i]._id, _processStasticsRows);
        break;
      }
    }

    if (!isPendingValidations) {
      setloading(false);
      sendFileForUpload(ProcessStasticsRowsParams);
    }
  };

  const sendFileForUpload = async (ProcessStasticsRowsParams) => {
    let isPendingUploads = false;

    for (let i in fileImportQueue) {
      if (fileImportQueue[i].stage === "EXAMINE" && fileImportQueue[i].status === "S") {
        fileImportQueue[i].stage = "UPLOAD";
        fileImportQueue[i].status = "P";
        isPendingUploads = true;
        AzureService.uploadFile(
          allSelectedFiles,
          fileImportQueue[i],
          fileImportQueue,
          updateFileDataStageAPIHandler,
          updateUploadSyncProcess,
          updateUploadProgressCounter,
          ProcessStasticsRowsParams,
          monthAndYear
        );
        break;
      }
    }

    // setProcessStasticsRows(ProcessStasticsRowsParams);
    if (!isPendingUploads) {
      setloading(false);
      window.location.reload();
    }
  }

  const prepareImportProcess = (ProcessStasticsRowsParams) => {
    fileImportQueue &&
      fileImportQueue.length &&
      fileImportQueue.forEach((uploadFile) => {
        if (uploadFile.stage === "REGISTER" && uploadFile.status === "S") {
          addOngoingUploadEntry(uploadFile, ProcessStasticsRowsParams);
        }
      });
    sendFileForValidation(ProcessStasticsRowsParams);
  };

  const sendFileForRegistration = (ProcessStasticsRowsParams) => {
    let PendingRegistrationFiles = fileImportQueue.filter((file) => file.stage === "REGISTER" && file.status === "P");

    if (PendingRegistrationFiles.length > 0) {
      for (let i in PendingRegistrationFiles) {
        addLedgerFilesAPIHandler(PendingRegistrationFiles[i]);
        break;
      }
    } else {
      prepareImportProcess(ProcessStasticsRowsParams);
    }
  };

  const addLedgerFilesAPIHandler = (file) => {
    setloading(true);
    setLoadingMsg({
      title: "Ledger Registration",
      content: `Registering File: ${file?.file}`,
    });
    let tempProcessStasticsRows = [...processStasticsRows];
    addLedgerFiles(file)
      .then((e) => {
        if (e?.status === 500 || e?.status === 401) {
          throw e?.status;
        } else {
          if (e?.id) {
            populateFileUploadQueue(file, e.id);
            sendFileForRegistration(tempProcessStasticsRows);
          } else {
            throw new Error();
          }
        }
      })
      .catch((err) => {
        setloading(false);
        seterror(true);
        seterrorMsg({
          title: err === 401 ? "Warning" : "Error",
          content: err === 500 ? "Internal Server Error" : err === 401 ? "Session TimeOut , Please Login Again" : "Something Went Wrong , Please Login Again",
        });
      });
  };

  const populateFileUploadQueue = (registerFile, id) => {
    for (let i in fileImportQueue) {
      if (fileImportQueue[i].file == registerFile.file) {
        fileImportQueue[i]._id = id;
        fileImportQueue[i].stage = "REGISTER";
        fileImportQueue[i].status = "S";
        break;
      }
    }

    // setFileImportQueue(_fileImportQueue);
  };

  const getTaxonomyData = () => {
    setLoadingMsg({
      title: "Retrieving Taxonomies",
      content: "Preparing Standards For Import Process",
    });
    getTaxonomyGlobal()
      .then((e) => {
        if (e?.status === 500 || e?.status === 401) {
          throw e?.status;
        } else {
          if (e?.data) {
            setloading(false);
            setTaxonomy(e?.data);
            let _country = [...allCountry];
            const addCountryCode = [];
            e?.data &&
              e?.data?.length &&
              e?.data?.forEach((e) => {
                if (!addCountryCode.includes(e.code_iso_3) && e.bl_flag === false) {
                  addCountryCode.push(e.code_iso_3);
                  _country.push({ title: e.country.toString().toUpperCase().trim().replace(/_/g, " ") });
                }
              });
            _country.push({ title: "BL COUNTRY" });
            setAllCountry(_country);
            if (_country && _country.length) {
              setCurrentCountry(_country[0].title);
            }
          } else {
            throw new Error();
          }
        }
      })
      .catch((err) => {
        setloading(false);
        seterror(true);
        seterrorMsg({
          title: err === 401 ? "Warning" : "Error",
          content: err === 500 ? "Internal Server Error" : err === 401 ? "Session TimeOut , Please Login Again" : "Something Went Wrong , Please Login Again",
        });
      });
  };

  const handleClickUploadFiles = () => {
    if (allSelectedFiles.length === 0) {
      setDetailsInfoModal(true);
      seterrorMsg({
        title: "Files Missing",
        content: "Did you forget to drop files?",
      });
    } else {
      let verifyFileArr = [];
      allSelectedFiles &&
        allSelectedFiles?.length &&
        allSelectedFiles.forEach((file) => {
          verifyFileArr.push(file.name);
        });
      setVerifyFileArr(verifyFileArr);
      verifyFilesExistenceAPIHandler(verifyFileArr.toString());
    }
  };

  const registerLedgerFiles = (_allSelectedFiles) => {
    // const codeISO3 = currentCountry?.slice(0, 3);
    let codeISO3;
    const is_override = 1;
    const started_date = new Date();

    // const _fileImportQueue = [...fileImportQueue];

    if (currentCountry === "BL COUNTRY") {
      const getFirstBLCountry = getTaxonomy.filter((e) => e.bl_flag === true && e.trade === currentTradeType);
      _allSelectedFiles &&
        _allSelectedFiles.length &&
        _allSelectedFiles.forEach((verifiedfile) => {
          let pushFile = {
            file: verifiedfile.name,
            taxonomy_id: getFirstBLCountry[0]._id,
            country: "bl",
            code_iso_3: getFirstBLCountry[0].code_iso_3,
            code_iso_2: getFirstBLCountry[0].code_iso_2,
            trade_type: currentTradeType,
            trade_records: 0,
            bl_flag: true,
            file_records_tag: "",
            is_override: is_override,
            // upload_uuid: verifiedfile.upload.uuid,
            stage: "REGISTER",
            status: "P",
            errors: [],
            started_date: started_date,
          };

          fileImportQueue.push(pushFile);
        });

      // setFileImportQueue(_fileImportQueue);

      sendFileForRegistration();
    } else if (currentCountry) {
      let taxonomyIdArr = getTaxonomy.filter(
        (taxonomy) =>
          taxonomy.country?.split("_")?.join(" ")?.toLowerCase() === currentCountry?.split("_")?.join(" ")?.toLowerCase() &&
          taxonomy.trade === currentTradeType &&
          taxonomy.bl_flag === false
      );

      if (taxonomyIdArr.length > 0) {
        codeISO3 = taxonomyIdArr[0]?.code_iso_3;
        const taxonomyId = taxonomyIdArr[0]._id;
        const codeISO2 = taxonomyIdArr[0].code_iso_2;
        const country = taxonomyIdArr[0].country;
        let pushFile;
        // verifiedFiles
        _allSelectedFiles &&
          _allSelectedFiles.length &&
          _allSelectedFiles.forEach((verifiedfile) => {
            pushFile = {
              taxonomy_id: taxonomyId,
              file: verifiedfile.name,
              country: country === "Brazil" ? "bl_brazil" : country,
              code_iso_3: codeISO3,
              code_iso_2: codeISO2,
              trade_type: currentTradeType,
              trade_records: 0,
              // trade_year: tradeYear,
              file_records_tag: "",
              is_override: is_override,
              // upload_uuid: verifiedfile.upload.uuid,
              stage: "REGISTER",
              status: "P",
              errors: [],
              started_date: started_date,
            };

            fileImportQueue.push(pushFile);
          });
        // setFileImportQueue(_fileImportQueue);

        sendFileForRegistration();
      } else {
        setloading(false);
        seterror(true);
        seterrorMsg({
          title: "Taxonomy Guard Alert",
          content: `Taxonomy Abandoned For : ${currentCountry?.toUpperCase()}`,
        });
      }
    } else {
      setloading(false);
      seterror(true);
      seterrorMsg({
        title: "Import Configuration Insufficient",
        content: `Kindly select all configuration before starting the upload process!`,
      });
    }
  };

  const updateVerifiedFiles = (verifiedFiles) => {
    let _allSelectedFiles = [...allSelectedFiles];
    let _processStasticsRows = [...processStasticsRows];
    let _progressCounter = [...progressCounter];
    allSelectedFiles &&
      allSelectedFiles.length &&
      allSelectedFiles.forEach((attachedFile, index) => {
        verifiedFiles.forEach((verifiedfile) => {
          if (verifiedfile.file === attachedFile.name) {
            if (verifiedfile.exists) {
              setloading(false);
              setLoadingAlert(true);
              setLoadingMsg({
                title: "Duplicate Files Auto-Remover",
                content: "Files Discarded",
              });
              setTimeout(() => {
                setLoadingAlert(false);
              }, 2000);
              _allSelectedFiles.splice(index, 1);
              _processStasticsRows.splice(index, 1);
              _progressCounter.splice(index, 1);
              setAllSelectedFiles(_allSelectedFiles);
              setProcessStasticsRows(_processStasticsRows);
              setProgressCounter(_progressCounter);
            }
          }
        });
      });

    var intervalID = window.setTimeout(function () {
      // setloading(false);
      if (_allSelectedFiles.length > 0) {
        registerLedgerFiles(_allSelectedFiles);
      }
      clearTimeout(intervalID);
    }, 2000);
  };

  const verifyFilesExistenceAPIHandler = (filesParams) => {
    setloading(true);
    setLoadingMsg({
      title: "Files Acceptance",
      content: "Checking if file with same name already exists!",
    });

    verifyUploadedFiles(filesParams)
      .then((e) => {
        if (e?.status === 500 || e?.status === 401) {
          throw e?.status;
        } else {
          if (e?.data) {
            // setloading(false);
            if (e.data && e.data.length > 0) {
              updateVerifiedFiles(e.data);
            }
          } else {
            throw new Error();
          }
        }
      })
      .catch((err) => {
        setloading(false);
        seterror(true);
        seterrorMsg({
          title: err === 401 ? "Warning" : "Error",
          content: err === 500 ? "Internal Server Error" : err === 401 ? "Session TimeOut , Please Login Again" : "Something Went Wrong , Please Login Again",
        });
      });
  };

  return (
    <div>
      <Container maxWidth="xxl">
        {loading && <Loading title={loadingMsg.title} content={loadingMsg.content} />}
        {loadingAlert && <Loading title={loadingMsg.title} content={loadingMsg.content} loader="hide" />}
        {error && <Error errorMsg={errorMsg} open={error} setOpen={seterror} />}
        <DetailsInfoModal errorMsg={errorMsg} open={detailsInfoModal} setOpen={setDetailsInfoModal} />
        <div className="row-dashbored">
          <h4 className="text">
            <span style={{ fontSize: "22px" }}>Import Data</span>
            <span className="badge bg-soft-blue text-blue label-worksapce-name">Set Files</span>
          </h4>
        </div>

        <SelectComponant
          allCountry={allCountry}
          currentTradeType={currentTradeType}
          currentCountry={currentCountry}
          setCurrentCountry={setCurrentCountry}
          setCurrentTradeType={setCurrentTradeType}
          handleClickUploadFiles={handleClickUploadFiles}
          setMonthAndYear={setMonthAndYear}
          monthAndYear={monthAndYear}
        />

        <Upload
          selectedFile={selectedFile}
          setSelectedFile={setSelectedFile}
          setAllSelectedFiles={setAllSelectedFiles}
          allSelectedFiles={allSelectedFiles}
          setProcessStasticsRows={setProcessStasticsRows}
          processStasticsRows={processStasticsRows}
          currentCountry={currentCountry}
          currentTradeType={currentTradeType}
          setProgressCounter={setProgressCounter}
          progressCounter={progressCounter}
        />

        <ProcessStatstics
          processStasticsRows={processStasticsRows}
          progressCounter={progressCounter}
          discardFile={discardFile}
          pendingLoader={pendingLoader}
          tableRef={tableRef}
        />
      </Container>
    </div>
  );
}
