import React, { useEffect, useState } from "react";
import moment from "moment";
import "./customerInfo.scss";
import { Box, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { experimentalStyled as styled } from "@mui/material/styles";
import ModalComponent from "../../../../Component/Modal/modal";
import DataTable from "../../../../Component/DataTable/table";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import WestIcon from "@mui/icons-material/West";
const FilterButton = styled(Button)({
  color: "#fff",
  backgroundColor: "#005D91",
  borderColor: " #005D91",
  // textTransform:"none",
  "&:hover": {
    backgroundColor: "#07527D !important",
    borderColor: "#07527D !important",
  },
});

const DeleteBtn = styled(Button)({
  color: "#fff",
  backgroundColor: "#f1556c",
  borderColor: "#f1556c",
  marginLeft: "10px",
  // textTransform:"none",
  "&:hover": {
    backgroundColor: "#be3b4e !important",
    borderColor: "#be3b4e !important",
  },
});
export default function CustomerInfo(props) {
  const company = props.customerInfoDetails?.company;
  const planDetails = props.customerInfoDetails.plan_constraints;
  const [wokspaceListModal, setWokspaceListModal] = useState(false);
  const [activitytrackerModal, setActivitytrackerModal] = useState(false);
  const workspaceTableColumn = [
    { title: "", key: "flag_uri" },
    { title: "WorkSpace Name", key: "name" },
    { title: "country", key: "country" },
    { title: "Trade Type", key: "trade" },
    { title: "Shipments", key: "records" },
    { title: "Created Date", key: "created_ts" },
  ];
  const [workspaceTableRow, setWorkspaceTableRow] = useState([]);
  const activitytrackerTableColumn = [
    { title: "country", key: "country" },
    { title: "Trade Type", key: "tradeType" },
    { title: "Query Response Time", key: "queryResponseTime" },
    { title: "Created At", key: "created_ts" },
    { title: "Query", key: "query" },
  ];
  const [activitytrackerTableRow, setActivitytrackerTableRow] = useState([]);

  useEffect(() => {
    if (props.customerInfoDetails.workspaceCount !== undefined) {
      if (props.customerInfoDetails.workspaceCount !== 0) {
        setWorkspaceTableRow(props.customerInfoDetails.workspaces[0]);
      }
    }

    if (props.customerInfoDetails.user_activity !== undefined) {
      setActivitytrackerTableRow(props.customerInfoDetails.user_activity);
    }
  }, [props.customerInfoDetails]);

  const handleWorkspaceBtn = () => {
    props.setIsCustomerInfoModal(false);
    setWokspaceListModal(true);
  };

  const handleWorkspaceModalBackBtn = () => {
    setWokspaceListModal(false);
    props.setIsCustomerInfoModal(true);
  };

  const handleActivityBtn = () => {
    props.setIsCustomerInfoModal(false);
    setActivitytrackerModal(true);
  };

  const handleActivtyModalBackBtn = () => {
    setActivitytrackerModal(false);
    props.setIsCustomerInfoModal(true);
  };

  return (
    <Box id="customerInfo">
      <ModalComponent
        open={props.isCustomerInfoModal}
        setOpen={props.setIsCustomerInfoModal}
        header={props.currentAccountDetails.company?.name}
        modalBody={
          <>
            {company !== undefined && (
              <>
                <h4 style={{ margin: "9px 8px", fontSize: "20px", color: "#878787", fontWeight: 700 }}>Personal Details</h4>
                <Box style={{ display: "flex" }}>
                  <Box style={{ width: "50%" }}>
                    {Object.entries(company)
                      .slice(0, 5)
                      .map((e) => {
                        return (
                          <Box sx={{ p: 1 }}>
                            <span style={{ color: "#343a40", fontWeight: 700 }}>{e[0].split("_").join(" ").toUpperCase()} : </span>
                            <span style={{ color: "rgb(146 153 159)", fontWeight: 700 }}>{e[1] !== "" ? e[1] : "N/A"}</span>
                          </Box>
                        );
                      })}
                  </Box>
                  <Box style={{ width: "50%" }}>
                    {Object.entries(company)
                      .slice(5, Object.entries(company).length)
                      .map((e) => {
                        return (
                          <Box sx={{ p: 1 }}>
                            <span style={{ color: "#343a40", fontWeight: 700 }}>{e[0].split("_").join(" ").toUpperCase()} : </span>
                            <span style={{ color: "rgb(146 153 159)", fontWeight: 700 }}>{e[1] !== "" ? e[1] : "N/A"}</span>
                          </Box>
                        );
                      })}
                  </Box>
                </Box>
              </>
            )}

            <Box style={{ borderBottom: "1px solid lightgrey", margin: "20px 0px " }}></Box>
            {planDetails !== undefined && (
              <>
                <h4 style={{ margin: "9px 8px", fontSize: "20px", color: "#878787", fontWeight: 700 }}>Plan Details</h4>
                <Box style={{ display: "flex" }}>
                  <Box style={{ width: "50%" }}>
                    <Box style={{ fontSize: "17px", padding: "10px", fontWeight: 700, color: "#343a40" }}>Access Validity</Box>
                    <Box>
                      {Object.entries(planDetails?.access_validity_interval).map((e) => {
                        return (
                          <Box style={{ margin: "5px 20px" }}>
                            <span style={{ color: "#343a40", fontWeight: 700 }}>{e[0].split("_").join(" ").toUpperCase()} : </span>
                            <span style={{ color: "rgb(146 153 159)", fontWeight: 700 }}>{e[1] !== "" ? moment(e[1]).format("YYYY-MM-DD") : "N/A"}</span>
                          </Box>
                        );
                      })}
                    </Box>

                    <Box style={{ fontSize: "17px", padding: "10px", fontWeight: 700, color: "#343a40" }}>Data Avalidity</Box>
                    {Object.entries(planDetails?.data_availability_interval).map((e) => {
                      return (
                        <Box style={{ margin: "5px 20px" }}>
                          <span style={{ color: "#343a40", fontWeight: 700 }}>{e[0].split("_").join(" ").toUpperCase()} : </span>
                          <span style={{ color: "rgb(146 153 159)", fontWeight: 700 }}>{e[1] !== "" ? moment(e[1]).format("YYYY-MM-DD") : "N/A"}</span>
                        </Box>
                      );
                    })}
                  </Box>
                  <Box style={{ width: "50%" }}>
                    <Box style={{ padding: "5px" }}>
                      <span style={{ color: "#343a40", fontWeight: 700 }}>Subscription Type : </span>
                      <span style={{ color: "rgb(146 153 159)", fontWeight: 700 }}>{planDetails?.subscriptionType}</span>
                    </Box>
                    <Box style={{ padding: "5px" }}>
                      <span style={{ color: "#343a40", fontWeight: 700 }}>Subscription Currency : </span>
                      <span style={{ color: "rgb(146 153 159)", fontWeight: 700 }}>{planDetails?.payment?.currency}</span>
                    </Box>
                    <Box style={{ padding: "5px" }}>
                      <span style={{ color: "#343a40", fontWeight: 700 }}>Subscription Amount : </span>
                      <span style={{ color: "rgb(146 153 159)", fontWeight: 700 }}>{props?.customerInfoDetails?.subscription?.amount}</span>
                    </Box>
                    <Box style={{ padding: "5px" }}>
                      <span style={{ color: "#343a40", fontWeight: 700 }}>Max User : </span>
                      <span style={{ color: "rgb(146 153 159)", fontWeight: 700 }}>{planDetails?.max_users}</span>
                    </Box>
                    <Box style={{ padding: "5px" }}>
                      <span style={{ color: "#343a40", fontWeight: 700 }}>Max Query : </span>
                      <span style={{ color: "rgb(146 153 159)", fontWeight: 700 }}>{planDetails?.max_query_per_day}</span>
                    </Box>
                    <Box style={{ padding: "5px" }}>
                      <span style={{ color: "#343a40", fontWeight: 700 }}>Points Available : </span>
                      <span style={{ color: "rgb(146 153 159)", fontWeight: 700 }}>{planDetails?.purchase_points}</span>
                    </Box>
                    <Box style={{ padding: "5px" }}>
                      <span style={{ color: "#343a40", fontWeight: 700 }}>Points Available : </span>
                      <span style={{ color: "rgb(146 153 159)", fontWeight: 700 }}>{planDetails?.purchase_points}</span>
                    </Box>
                  </Box>
                </Box>
              </>
            )}

            <Box sx={{ mt: 2 }}>
              {props?.customerInfoDetails?.workspaceCount === 0 ? (
                <DeleteBtn>{props?.customerInfoDetails?.workspaceCount + " " + "Workspace Created"}</DeleteBtn>
              ) : (
                <FilterButton onClick={handleWorkspaceBtn} startIcon={<ArrowForwardIcon />}>
                  {props?.customerInfoDetails?.workspaceCount + " " + "Workspace Created"}
                </FilterButton>
              )}

              {props?.customerInfoDetails?.user_activity?.length > 0 && (
                <FilterButton onClick={handleActivityBtn} sx={{ ml: 2 }} startIcon={<ArrowForwardIcon />}>
                  Track User Activity
                </FilterButton>
              )}
            </Box>
          </>
        }
        width="1000px"
      />

      <ModalComponent
        open={wokspaceListModal}
        setOpen={setWokspaceListModal}
        header={props?.currentAccountDetails?.company?.name}
        modalBody={
          <Box>
            <DataTable rows={workspaceTableRow} columns={workspaceTableColumn} showPagination={false} />
            <FilterButton sx={{ mt: 2 }} onClick={handleWorkspaceModalBackBtn} startIcon={<WestIcon />}>
              Back
            </FilterButton>
          </Box>
        }
        width="800px"
      />

      <ModalComponent
        open={activitytrackerModal}
        setOpen={setActivitytrackerModal}
        header={props?.currentAccountDetails?.company?.name}
        modalBody={
          <Box>
            <DataTable rows={activitytrackerTableRow} columns={activitytrackerTableColumn} showPagination={false} />
            <FilterButton sx={{ mt: 2 }} onClick={handleActivtyModalBackBtn} startIcon={<WestIcon />}>
              Back
            </FilterButton>
          </Box>
        }
        width="800px"
      />
    </Box>
  );
}
