import { Grid, Button, Container } from "@mui/material";
import React, { useEffect, useState } from "react";
import moment from "moment";
import DataTable from "../../Component/DataTable/table";
import Spinner from "../../Component/Loader/spinner";
import Error from "../../Component/ErrorLoader/error";
import ApproveSuccessModal from "../../Component/ApprovSuccessModal/approvSuccessModal";
import {
  deleteConsigneeRequest,
  getConsigneePendingRequestList,
  getConsigneeProcessedRequestList,
  sendShipmentData,
} from "../../Services/service";
import UpdateRequest from "./Component/updateRequest";
import "./shipmentsRequest.scss";
import ModalComponent from "../../Component/Modal/modal";
import Loading from "../../Component/Loader/loading";
import Upload from "../Trade/Build/UploadFile/upload";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { experimentalStyled as styled } from "@mui/material/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Papa from "papaparse";

export default function ShipmentsRequest() {
  const [loading, setloading] = useState(false);
  const [loadingMsg, setloadingMsg] = useState({});
  const [spinner, setSpinner] = useState(false);
  const [error, seterror] = useState(false);
  const [errorMsg, seterrorMsg] = useState({});
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [switchValue, setSwitchValue] = useState(false);
  const [consigneeReqList, setConsigneeReqList] = useState([]);
  const [approvSuccessModal, setApprovSuccessModal] = useState(false);
  const [approvSuccessModalData, setApprovSuccessModalData] = useState({});
  const [consigneeListTableHeader, setConsigneeListTableHeader] = useState([]);
  const [totalConsigneeListCount, setTotalConsigneeListCount] = useState(0);
  const [isUpadteReqModal, setIsUpadteReqModal] = useState(false);
  const [isDeleteReqModal, setIsDeleteReqModal] = useState(false);
  const [selectedUpdatedData, setSelectedUpdatedData] = useState({});
  const [selectedDeleteData, setSelectedDeleteData] = useState({});

  const [selectedFile, setSelectedFile] = useState(null);
  let x = true;
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleFileUpload = () => {
    if (x) {
      const fileupload = document.getElementById("uploadFile");
      fileupload.click();
    }
  };

  const pendingConsigneeReqListAPIHandler = (payload, isCreateCsv) => {
    getConsigneePendingRequestList(payload)
      .then((e) => {
        if (e?.status === 500 || e?.status === 401) {
          throw e?.status;
        } else {
          if (e?.data) {
            e?.data &&
              e?.data?.length &&
              e?.data?.forEach((e) => {
                if (e.date) {
                  e.date = moment(e?.date).format("lll");
                }
                if (e.dateOfRequest) {
                  e.dateOfRequest = moment(e?.dateOfRequest).format("lll");
                }
              });
            setConsigneeReqList(e?.data);
            setTotalConsigneeListCount(e?.recordsFiltered);
            if (isCreateCsv) {
              createCsv(e?.data);
            } else {
              setSpinner(false);
            }
          } else {
            throw new Error();
          }
        }
      })
      .catch((err) => {
        setSpinner(false);
        seterror(true);
        seterrorMsg({
          title: err === 401 ? "Warning" : "Error",
          content:
            err === 500
              ? "Internal Server Error"
              : err === 401
              ? "Session TimeOut , Please Login Again"
              : "Something Went Wrong , Please Login Again",
        });
      });
  };

  const processedConsigneeReqListAPIHandler = (payload) => {
    getConsigneeProcessedRequestList(payload)
      .then((e) => {
        if (e?.status === 500 || e?.status === 401) {
          throw e?.status;
        } else {
          if (e?.data) {
            setSpinner(false);
            setConsigneeReqList(e?.data);
            setTotalConsigneeListCount(e?.recordsFiltered);
          } else {
            throw new Error();
          }
        }
      })
      .catch((err) => {
        setSpinner(false);
        seterror(true);
        seterrorMsg({
          title: err === 401 ? "Warning" : "Error",
          content:
            err === 500
              ? "Internal Server Error"
              : err === 401
              ? "Session TimeOut , Please Login Again"
              : "Something Went Wrong , Please Login Again",
        });
      });
  };

  const handleDataTableAPIHandling = (payload) => {
    setSpinner(true);
    if (switchValue) {
      setConsigneeListTableHeader([
        { key: "shipment_number", title: "Shipment No" },
        { key: "buyer_name", title: "Buyer Name" },
        { key: "buyer_email", title: "Buyer Email" },
        { key: "buyer_address", title: "Buyer Address" },
        { key: "buyer_concerned_person", title: "Buyer Concerned Person" },
        { key: "buyer_phone_number", title: "Phone No" },
      ]);
      processedConsigneeReqListAPIHandler(payload);
    } else {
      setConsigneeListTableHeader([
        { key: "requested_account", title: "Requested Account" },
        { key: "date", title: "Date" },
        { key: "shipmentBillNumber", title: "Shipment Bill No" },
        { key: "port", title: "Port" },
        { key: "dateOfRequest", title: "Date of Request" },
        { key: "Update Request", title: "Update Request" },
      ]);
      pendingConsigneeReqListAPIHandler(payload);
    }
  };
  useEffect(() => {
    setPage(0);
    setRowsPerPage(10);
    const payload = {
      limit: 10,
      offset: 0 * 10,
    };
    handleDataTableAPIHandling(payload);
  }, [switchValue]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage - 1);
    const payload = {
      limit: rowsPerPage,
      offset: (newPage - 1) * rowsPerPage,
    };
    handleDataTableAPIHandling(payload);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
    const payload = {
      limit: event.target.value,
      offset: 0 * event.target.value,
    };
    handleDataTableAPIHandling(payload);
  };
  const handleChangeSwitch = (e) => {
    setSwitchValue(e.target.checked);
  };

  const handleUpdateReqBtn = (row) => {
    setIsUpadteReqModal(true);
    setSelectedUpdatedData({
      shipment_number: row.shipmentBillNumber,
      userId: row.user_id,
    });
  };

  const handleDeleteReqBtn = (row) => {
    const payload = {
      shipmentBillNumber: row.shipmentBillNumber,
      userId: row.user_id,
    };
    setSelectedDeleteData(payload);
    setIsDeleteReqModal(true);
  };

  const deleteReqAPIHandler = () => {
    setloading(true);
    setloadingMsg({
      title: "Deleting Request",
      content: "Please Wait",
    });
    deleteConsigneeRequest(selectedDeleteData)
      .then((e) => {
        if (e?.status === 500 || e?.status === 401) {
          throw e?.status;
        } else {
          if (e?.data) {
            setloading(false);
            setIsDeleteReqModal(false);
            setApprovSuccessModal(true);
            setApprovSuccessModalData({
              title: "Successfully Delete Request !",
              footerTitle: "Deleted Successfull",
            });
            setPage(0);
            setRowsPerPage(10);
            const payload = {
              limit: 10,
              offset: 0 * 10,
            };
            handleDataTableAPIHandling(payload);
            setTimeout(() => {
              setApprovSuccessModal(false);
            }, 3000);
          } else {
            throw new Error();
          }
        }
      })
      .catch((err) => {
        setloading(false);
        seterror(true);
        seterrorMsg({
          title: err === 401 ? "Warning" : "Error",
          content:
            err === 500
              ? "Internal Server Error"
              : err === 401
              ? "Session TimeOut , Please Login Again"
              : "Something Went Wrong , Please Login Again",
        });
      });
  };

  const handleSubmitReq = () => {
    setPage(0);
    setRowsPerPage(10);
    const payload = {
      limit: 10,
      offset: 0,
    };
    handleDataTableAPIHandling(payload);
  };
  const UploadBtn = styled(Button)({
    color: "#fff",
    backgroundColor: "#005D91",
    borderColor: " #005D91",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#07527D !important",
      borderColor: "#07527D !important",
    },
  });

  const handleClickUploadFiles = () => {
    if (selectedFile) {
      Papa.parse(selectedFile, {
        complete: (result) => {
          validateFile(result);
        },
        error: (error) => {
          seterror(true);
          seterrorMsg({
            title: error,
            content: "Selected file was not CSV",
          });
        },
        worker: false,
        header: true,
        skipEmptyLines: true,
        quoteChar: '"',
        escapeChar: '"',
        delimiter: ",",
        encoding: "utf-8",
      });
    } else {
      seterror(true);
      seterrorMsg({
        title: "Please Select File",
      });
    }
  };

  const sendFile = (buyerData, result) => {
    const cleanedData = result?.data
      .map((item) => {
        const newObj = {};
        Object.keys(item).forEach((key) => {
          if (key !== "") {
            newObj[key] = item[key];
          }
        });
        return newObj;
      })
      .filter((item) => Object.keys(item).length !== 0);
    sendShipmentData(cleanedData)
      .then((res) => {
        if (res?.status === 200) {
          setApprovSuccessModal(true);
          setApprovSuccessModalData({
            title: "Successfully!",
            footerTitle: "File Uploaded SuccessFully",
          });
        }
      })
      .catch((err) => {
        setSpinner(false);
        seterror(true);
        seterrorMsg({
          title: err === 401 ? "Warning" : "Error",
          content:
            err === 500
              ? "Internal Server Error"
              : err === 401
              ? "Session TimeOut , Please Login Again"
              : "Something Went Wrong , Please Login Again",
        });
      });
    setSelectedFile(null);
  };

  const removeFile = () => {
    setSelectedFile(null);
    x = false;
  };

  const validateFile = (result) => {
    let isBuyerNameAndAddressExist;
    if (result?.meta?.fields) {
      isBuyerNameAndAddressExist = true;
    }
    if (isBuyerNameAndAddressExist) {
      const buyerData = result?.data?.map((item) => {
        return {
          buyerName: item?.buyer_name,
          buyerAddress: item?.buyer_adress,
          buyerEmail: item?.buyer_email,
        };
      });

      if (buyerData.length > 0) {
        const isBuyerNameExist = buyerData?.map((item) => {
          if (item?.buyerName) {
            return true;
          }
        });
        const isBuyerAddressExist = buyerData?.map((item) => {
          if (item?.buyerAddress) {
            return true;
          }
        });
        if (isBuyerNameExist && isBuyerAddressExist) {
          sendFile(buyerData, result);
        }
      } else {
        seterror(true);
        seterrorMsg({
          title: "Missing Data",
          content: "Buyer Name And Buyer Address Data is Missing",
        });
        setSelectedFile(null);
      }
    }
  };

  const createCsv = (data) => {
    const dummyData = data.map((item) => {
      return {
        userId: item?.user_id,
        shipment_number: item?.shipmentBillNumber,
        buyer_name: "",
        buyer_address: "",
        buyer_email: "",
        buyer_concerned_person: "",
        buyer_phone_number: "",
      };
    });
    // Create header row
    const headers = Object.keys(dummyData[0]);
    const headerRow = headers.join(",") + "\n";

    // Create data rows
    const dataRows = dummyData.map((row) => Object.values(row).join(","));

    // Combine header and data rows
    const csvContent =
      "data:text/csv;charset=utf-8," + headerRow + dataRows.join("\n");
    setSpinner(false);
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "pendingConsigneeRequest.csv");
    document.body.appendChild(link);
    link.click();
  };

  const getAllShipmentRecord = () => {
    setSpinner(true);
    const payload = {
      limit: totalConsigneeListCount,
      offset: 0,
    };
    pendingConsigneeReqListAPIHandler(payload, true);
  };

  return (
    <Container maxWidth="xxl">
      {spinner && <Spinner />}
      {loading && (
        <Loading title={loadingMsg.title} content={loadingMsg.content} />
      )}
      <ApproveSuccessModal
        Open={approvSuccessModal}
        title={approvSuccessModalData.title}
        footerTitle={approvSuccessModalData.footerTitle}
      />
      {error && <Error errorMsg={errorMsg} open={error} setOpen={seterror} />}
      <Grid container className="row-dashbored">
        <Grid item lg={2} md={2} sm={3} xs={3}>
          <h3 className="text">
            Shipments
            <span className="badge bg-soft-blue text-blue label-worksapce-name">
              Request
            </span>
          </h3>
        </Grid>

        <Grid item lg={10} md={10} sm={6} xs={12}>
          <div style={{ float: "right" }}>
            <label className="switch">
              <input
                type="checkbox"
                id="togBtn"
                onChange={(e) => handleChangeSwitch(e)}
              />
              <div className="slider round">
                <span className="on text-center">Processed Request</span>
                <span className="off text-center">Pending Request</span>
              </div>
            </label>
          </div>
        </Grid>
      </Grid>
      <Grid
        style={{
          display: "flex",
          flexDirection: "column-reverse",
        }}
      >
        {/* <input
          type="file"
          className="upload_shipment_file"
          onChange={handleFileChange}
        /> */}
        <div className="card">
          <div className="card-body">
            <div className="card-widgets">
              <a
                id="expcolFileDropZone"
                data-toggle="collapse"
                href="#dropFileCard"
                role="button"
                aria-expanded="false"
                aria-controls="dropFileCard"
                rel="noopener noreferrer"
              >
                <i className="mdi mdi-minus"></i>
              </a>
            </div>
            <h4 className="upload-title">Select Files To Upload</h4>

            <div id="dropFileCard">
              <div
                className="dropzone"
                id="fileDropZone"
                onClick={handleFileUpload}
                onChange={handleFileChange}
              >
                <input
                  type="file"
                  id="uploadFile"
                  style={{ display: "none" }}
                  accept=".csv"
                />
                {selectedFile ? (
                  <div className="dz-preview">
                    <div className="dz-content">
                      <div className="dz-details">
                        <div className="dz-size">{selectedFile?.size}</div>
                        <div
                          className="dz-filename"
                          style={{
                            justifyContent:
                              selectedFile?.name.length < 10 && "center",
                          }}
                        >
                          {selectedFile?.name.length > 10
                            ? selectedFile?.name.slice(0, 10) + ".."
                            : selectedFile?.name}
                        </div>
                      </div>
                    </div>
                    <div className="dz-remove" onClick={() => removeFile()}>
                      Remove File
                    </div>
                  </div>
                ) : (
                  <div
                    className="dz-message needsclick"
                    style={{ textAlign: "center", padding: "40px" }}
                  >
                    <CloudUploadIcon
                      fontSize="large"
                      style={{ fontSize: "60px" }}
                    />
                    <h1
                      className="upload-title"
                      style={{ margin: "0px", marginBottom: "15px" }}
                    >
                      Drop files here or click to select files.
                    </h1>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <UploadBtn
          style={{
            maxWidth: "200px",
            position: "absolute",
            top: "100px",
            right: "50px",
          }}
          startIcon={<UploadFileIcon />}
          onClick={handleClickUploadFiles}
        >
          Start Upload
        </UploadBtn>
      </Grid>
      <UploadBtn
        style={{
          position: "absolute",
          right: "55px",
        }}
        onClick={getAllShipmentRecord}
      >
        Create CSV
      </UploadBtn>
      <DataTable
        columns={consigneeListTableHeader}
        rows={consigneeReqList}
        showPagination={true}
        totalAvailableData={totalConsigneeListCount}
        rowsPerPage={rowsPerPage}
        page={page}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleUpdateReqBtn={handleUpdateReqBtn}
        handleDeleteReqBtn={handleDeleteReqBtn}
        tableHeigth="550px"
      />

      <ModalComponent
        open={isDeleteReqModal}
        setOpen={setIsDeleteReqModal}
        header="Delete Request"
        modalBody={
          <span
            style={{
              fontSize: "22px",
              fontWeight: 600,
              color: "rgb(50, 58, 70)",
            }}
          >
            Are you sure to delete this Request ?
          </span>
        }
        width="600px"
        saveText="Yes , delete it"
        saveButtonShown={true}
        saveButtonColor="#f1556c"
        savedClicked={deleteReqAPIHandler}
      />

      <UpdateRequest
        isUpadteReqModal={isUpadteReqModal}
        setIsUpadteReqModal={setIsUpadteReqModal}
        selectedUpdatedData={selectedUpdatedData}
        handleSubmitReq={handleSubmitReq}
      />
    </Container>
  );
}
