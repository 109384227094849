import RecoveryImage from "../../Asset/images/successPassword.png";
import ErrorImage from "../../Asset/images/errorImage.png";
import "./authPage.scss";
import { Link } from "react-router-dom";
import AuthFooter from "../AuthFooter/authFooter";
import EximpediaLogo from "../../Asset/images/logo-dark-og.png";
const AuthPageComponant = (props) => {
  return (
    <div className="background-content" id="password-recovery">
      <div className="mb-5">
        <div className="container">
          <div className="recovery-col col-md-8 col-lg-6 col-xl-5">
            <div className="card-recovery">
              <div className="card-body p-4">
                <div className="text-center w-75 m-auto">
                  <span>
                    {props.name === "logout" ? <img style={{ marginTop: "12px" }} className="img-center" src={EximpediaLogo} alt="" height="44" /> : ""}
                  </span>
                </div>
                {props.name === "passwordRecovery" ? (
                  <div className="success-recovery">
                    <div className="mt-3 pt-24 text-center success-pass-submit">
                      <img src={RecoveryImage} alt="successPassword" />
                      <h3 className="font-success">Success !</h3>
                      <p className="text-width text-muted font-14 mt-2 success-text">
                        An email has been send to your email id. Please check for an email from Eximpedia and click on the included link to reset your password.
                      </p>
                    </div>
                  </div>
                ) : props.name === "emailVerification" ? (
                  <div className="success-recovery" style={{ paddingBottom: "30px" }}>
                    <div className="mt-3 pt-24 text-center success-pass-submit">
                      <img src={RecoveryImage} alt="emailVerification" />
                      <h3 className="font-success">Success !</h3>
                      <p className="text-width text-muted font-14 mt-2 success-text">
                        Your email <b> {props.emailId}</b> has been verified!. Click on the login button below to proceed to access your Eximpedia panel
                      </p>

                      <Link to="/providers/login">
                        <button className="go-login-btn">Go to Login</button>
                      </Link>
                    </div>
                  </div>
                ) : props.name === "logout" ? (
                  <div className="mt-3  text-center">
                    <div className="logout-checkmark">
                      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                        <circle className="path circle" fill="none" stroke="#4bd396" strokeWidth="6" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1" />
                        <polyline
                          className="path check"
                          fill="none"
                          stroke="#4bd396"
                          strokeWidth="6"
                          strokeLinecap="round"
                          strokeMiterlimit="10"
                          points="100.2,40.2 51.5,88.8 29.8,67.5 "
                        />
                      </svg>
                    </div>
                    <h3 style={{ marginBottom: "7px" }} className="font-success">
                      See you again ! {props.data.CLIENT_FIRSTNAME}
                    </h3>
                    <p style={{ marginTop: "0px" }} className="text-width text-muted font-14 mt-2">
                      You are now successfully signed out.
                    </p>
                  </div>
                ) : props.name === "emailActivation" ? (
                  <div className="success-recovery" style={{ paddingBottom: "30px" }}>
                    <div className="mt-3 pt-24 text-center success-pass-submit">
                      <img src={RecoveryImage} alt="emailActivation" />
                      <h3 className="font-success">Success !</h3>
                      <p className="text-width text-muted font-14 mt-2 success-text">
                        A email has been send to <b> {props.emailId}</b> . Please check for an email from Eximpedia and click on the included link to activate
                        your account.
                      </p>

                      <Link to="/providers/login">
                        <button className="go-login-btn">Go to Login</button>
                      </Link>
                    </div>
                  </div>
                ) : (
                  <div className="mt-3 pt-24 text-center error-page">
                    <img src={ErrorImage} alt="Error" />
                    <h3 className="font-success">Page not found !</h3>
                    <p className="text-width text-muted font-14 mt-2 error-text">
                      It's looking like you may have taken a wrong turn. Don't worry... it happens to the best of us. You might want to check your internet
                      connection. Here's a little tip that might help you get back on track.
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>

          {props.name === "ErrorPage" ? (
            <div className="error-return text-white-50">
              Return to
              <Link className="text-white" to={props.token !== undefined ? "/providers/dashboard" : "/providers/login"}>
                Home
              </Link>
            </div>
          ) : props.name === "logout" ? (
            <div className="error-return text-white-50">
              Back to
              <Link className="text-white" to="/providers/login">
                Sign<span style={{ marginLeft: "3px" }}>In</span>
              </Link>
            </div>
          ) : (
            ""
          )}
        </div>

        <AuthFooter />
      </div>
    </div>
  );
};
export default AuthPageComponant;
