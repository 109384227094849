import React from "react";
import DataTable from "../../../../Component/DataTable/table";
export default function ProcessStatstics({ processStasticsRows, discardFile, pendingLoader, tableRef , progressCounter }) {
  
  const processStatsticsTabelHeader = [
    { key: "name", title: "Name" },
    { key: "startDate", title: "Start Date" },
    { key: "tradeType", title: "Trade" },
    { key: "country", title: "country" },
    { key: "tradeRecords", title: "Records" },
    { key: "Validation", title: "Validation" },
    { key: "status", title: "Upload Status" },
    { key: "progress", title: "Progress" },
    { key: "Actions", title: "Actions" },
  ];

  return (
    <div style={{ padding: "20px" }} ref={tableRef}>
      <h2 style={{ color: "#323a46" }}>Import Process Statistics</h2>

      <DataTable
        columns={processStatsticsTabelHeader}
        rows={processStasticsRows}
        discardFile={discardFile}
        pendingLoader={pendingLoader}
        progressCounter={progressCounter}
      />
    </div>
  );
}
