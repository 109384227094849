import { useEffect } from "react";
import AuthPageComponant from "../../Component/AuthPageComponant/authPageComponant";
import { logoutDetails } from "../../Services/service";
import "./logout.scss";
const LogOut = (props) => {
  const deleteCookie = (name) => {
    document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;secure";
  };
  useEffect(() => {
    let data = {
      user_id: props.CLIENT_USER_ID,
    };

    deleteCookie("accountId");
    deleteCookie("user_id");
    deleteCookie("firstName");
    deleteCookie("tokenUI");
    deleteCookie("lastName");
    deleteCookie("email_id");

    logoutDetails(data)
      .then((e) => {})
      .catch((e) => {});
  }, []);

  return (
    <div id="logout-page">
      <AuthPageComponant name="logout" data={props} />
    </div>
  );
};
export default LogOut;
